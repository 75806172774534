import React, { useState, useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import axios from "axios";
import Spinner from "../../../Spinner/Spinner";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
// import { submitStatus } from "../../../../utils/Utils";

const EnableDisableWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const GoBackContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UserStatusWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserStatus = styled.div`
  display: flex;
`;

const StatusMessage = styled.p`
  font-size: 1.3rem;
`;
const Status = styled.strong`
  font-size: 1.3rem;
  margin-left: 15px;
`;

interface Props {
  setUpdateUser: any;
  getAPI: string;
  postAPI: string;
  roleId: any;
  selectedUserId: any;
  setRows: any;
  rows: any;
}

const EnableDisable: React.FC<Props> = ({
  setUpdateUser,
  getAPI,
  postAPI,
  roleId,
  setRows,
  rows,
  selectedUserId,
}) => {
  const [status, setStatus] = useState<any>(null);
  const { t } = useTranslation();

  const [fetchDataError, setFetchDataError] = useState(false);
  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState(false);
  const [fetchingData, setFetchingData] = useState(true);

  const [displaySubmitMessage, setDisplaySubmitMessage] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  const [submitError, setSubmitError] = useState(false);

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchData = useCallback(() => {
    axios
      .get(getAPI, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          setStatus(res.data.status);
          // setFetchingData(false);
          setFetchingData(false);
          setFetchDataError(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          //TODO it shouldn't be just clg

          setFetchDataError(true);
          setFetchingData(false);
          setFetchDataErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          // setFetchingData(false);}
        }
      });
  }, [getAPI]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const statusOutputHandler = (status: any) => {
    if (status === "DISABLED") {
      return <Status>{t("EnableDisableUser.8")}</Status>;
    } else if (status === "ENABLED") {
      return <Status>{t("EnableDisableUser.7")}</Status>;
    }

    return null;
  };

  const submitStatus = (status: any) => {
    let UserData = new FormData();

    UserData.append("status", status === "ENABLED" ? "DISABLED" : "ENABLED");
    UserData.append(
      "languageSelection",
      localStorage.getItem("i18nextLng") as any
    );

    // setStatus(null);

    setDisplaySubmitMessage(false);
    setFetchingData(true);
    axios
      .post(postAPI, UserData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          const data = res.data;

          // console.log(status);
          // console.log(data.status);
          // console.log(data.status === "SUCCESS" && status === "DISABLED");
          // setStatus(data.status);
          // if (data.status === "SUCCESS" && status === "DISABLED") {
          //   setStatus("ENABLED");
          // } else if (data.status === "SUCCESS" && status === "ENABLED") {
          //   setStatus("DISABLED");
          // }
          // setDisplaySubmitMessage(true);
          // setSubmitMessage(data.statusMessage);
          // setSubmitError(false);
          // setLoading(false);
          setDisplaySubmitMessage(true);

          if (data.status === "SUCCESS") {
            setSubmitError(false);

            console.log(rows);
            let updatedAccounts = rows.map((acc: any) => {
              if (acc.userId === selectedUserId) {
                if (acc.enabledOrDisabled === "Enabled") {
                  acc.enabledOrDisabled = "Disabled";
                } else if (acc.enabledOrDisabled === "Disabled") {
                  acc.enabledOrDisabled = "Enabled";
                }

                return acc;
              } else {
                return acc;
              }
            });
            setRows(updatedAccounts);
          } else {
            setSubmitError(true);
          }

          setSubmitMessage(data.statusMessage);

          axios
            .get(getAPI, {
              headers: {
                Authorization: "Bearer " + sessionStorage.getItem("token"),
              },
            })
            .then((res) => {
              if (isMounted.current) {
                setStatus(res.data.status);
                // setFetchingData(false);
                setFetchingData(false);
                setFetchDataError(false);
              }
            })
            .catch((err) => {
              if (isMounted.current) {
                //TODO it shouldn't be just clg

                setFetchDataError(true);
                setFetchingData(false);
                setFetchDataErrorMsg(
                  err.response &&
                    err.response.data &&
                    err.response.data.statusMessage
                    ? err.response.data.statusMessage
                    : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
                );
                // setFetchingData(false);}
              }
            });
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setDisplaySubmitMessage(true);
          setSubmitMessage(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setSubmitError(true);
          setFetchingData(false);
        }
      });
  };

  if (fetchingData) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    if (fetchDataError) {
      return (
        <div>
          <Alert severity="error">{fetchDataErrorMsg}</Alert>
          <br />
          <GoBackContainer>
            <Button
              color="secondary"
              variant="outlined"
              title={t("EnableDisableUser.6")}
              onClick={setUpdateUser}
            >
              {t("EnableDisableUser.5")}
            </Button>
          </GoBackContainer>
        </div>
      );
    } else {
      return (
        <>
          <EnableDisableWrapper>
            <br />
            <br />
            {displaySubmitMessage && (
              <Alert severity={submitError ? "error" : "success"}>
                {submitMessage}
              </Alert>
            )}
            <br />
            <UserStatusWrapper>
              <UserStatus>
                <StatusMessage>{t("EnableDisableUser.1")}</StatusMessage>
                {statusOutputHandler(status)}
              </UserStatus>

              <Button
                //   onClick={handlePasswordChange}
                disabled={status === "DISABLED" && roleId !== 1}
                color="primary"
                variant="contained"
                title={t("EnableDisableUser.4")}
                onClick={() => submitStatus(status)}
              >
                {status === "DISABLED"
                  ? t("EnableDisableUser.2")
                  : t("EnableDisableUser.3")}
              </Button>
            </UserStatusWrapper>
            <br />
            <br />
            <br />
            <GoBackContainer>
              <Button
                color="secondary"
                variant="outlined"
                title={t("EnableDisableUser.6")}
                onClick={setUpdateUser}
              >
                {t("EnableDisableUser.5")}
              </Button>
            </GoBackContainer>
          </EnableDisableWrapper>
        </>
      );
    }
  }
};

export default EnableDisable;
