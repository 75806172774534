import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const WelcomeText = styled.p`
  font-size: 1.2rem;
`;

const Welcome: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <WelcomeWrapper>
        <div style={{ textAlign: "center" }}>
          <h2 style={{ fontSize: "1.5rem" }}>{t("FPHome.1")}</h2>
        </div>
        <br />
        <br />
        <WelcomeText>{t("FPHome.2")}</WelcomeText>
        <br /> <br />
        <WelcomeText>{t("FPHome.3")}</WelcomeText>
        <br /> <br />
        <WelcomeText>
          {t("FPHome.4")}
          <br />
          <a
            href="https://www.fda.gov/media/102736/download"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#1138c7" }}
          >
            {t("FPHome.5")}
          </a>
          <br />
          <a
            href="https://www.fda.gov/industry/create-esg-account/webtrader-system-requirements"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#1138c7" }}
          >
            {t("FPHome.6")}
          </a>
          <br />
          <a
            href="https://www.fda.gov/industry/policiesguidance/links-center-specific-submission-preparation-guidelines"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#1138c7" }}
          >
            {t("FPHome.7")}
          </a>
        </WelcomeText>
      </WelcomeWrapper>
    </>
  );
};

export default Welcome;
