import React from "react";

const Spinner: React.FC<any> = () => {
  return (
    <div>
      <img
        src="img/Rolling-1s-31px.gif"
        alt="Loading..."
        style={{ display: "block" }}
      />
    </div>
  );
};

export default Spinner;
