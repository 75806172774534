import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const WelcomeText = styled.p`
  font-size: 1.2rem;
`;

const Welcome = () => {
  const { t } = useTranslation();
  return (
    <>
      <WelcomeWrapper>
        <div style={{ textAlign: "center" }}>
          <Typography variant="h4" component="h2">
            {t("WTMenuHome.1")}
          </Typography>
          {/* <h4>{t("WTMenuHome.1")}</h4> */}
        </div>
        <br />
        <br />
        <WelcomeText>{t("WTMenuHome.2")}</WelcomeText>
        <br /> <br />
        <WelcomeText>{t("WTMenuHome.3")}</WelcomeText>
        <br /> <br />
        <WelcomeText>
          {t("WTMenuHome.4")} <br />
          <a
            href="https://www.fda.gov/media/102736/download"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#1138c7" }}
          >
            {t("WTMenuHome.5")}
          </a>
          <br />
          <a
            href="https://www.fda.gov/industry/create-esg-account/webtrader-system-requirements"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#1138c7" }}
          >
            {t("WTMenuHome.6")}
          </a>
          <br />
          <a
            href="https://www.fda.gov/industry/policiesguidance/links-center-specific-submission-preparation-guidelines"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#1138c7" }}
          >
            {t("WTMenuHome.7")}
          </a>
        </WelcomeText>
        <br />
        <br />
        <br />
      </WelcomeWrapper>
    </>
  );
};

export default Welcome;
