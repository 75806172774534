import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";

interface Props {
  setUserAuthenticated: (param: any) => void;
  setUserClickedLogOut: (param: any) => void;
  setLoggedOutMessage: (param: any) => void;
  setAuthorizedStatus: (param: any) => void;
  authorizedStatus: string;
  // userName: string;
  // userRole: string;
  userAuthenticated: boolean;
}

const Header: React.FC<Props> = ({
  userAuthenticated,
  setUserAuthenticated,
  setUserClickedLogOut,
  setLoggedOutMessage,
  setAuthorizedStatus,
  authorizedStatus,
  // userName,
  // userRole,
}) => {
  let history = useHistory();
  const { t } = useTranslation();
  const logoutHandler = () => {
    let LogOutData = new FormData();

    LogOutData.append("logout", "true");

    //TODO make sure it is closed on close

    setUserAuthenticated(false);

    axios
      .post(
        `/api/logout?userid=${sessionStorage.getItem("userID")}`,
        LogOutData
      )
      .then((res) => {
        setLoggedOutMessage(res.data.statusMessage);
        setUserClickedLogOut(Boolean(res.data.status));

        localStorage.clear();

        setAuthorizedStatus(false);

        history.push("/");

        // history.push("/");

        // return <Redirect to="/" />;
        //TODO it can't just be clg err!!!!!
      })
      .catch((err) => {
        localStorage.clear();
        setAuthorizedStatus(false);
        history.push("/");
      });
  };

  // const [termsAccepted, setTermsAccepted] = React.useState(
  //   getSessionStorageOrDefault("userName", "")
  // );

  // React.useEffect(() => {
  //   sessionStorage.setItem("userName", JSON.stringify(termsAccepted));
  // }, [termsAccepted]);

  // console.log(termsAccepted);
  //TODO don't delete it, make sure the header is rerendered based on location
  const location = useLocation();

  // console.log(location);
  React.useEffect(() => {
    let url = location.pathname;

    // console.log(sessionStorage);
  }, []);

  return (
    <>
      <div className="header-main" role="banner">
        <div className="top-row">
          <a href="https://www.fda.gov/">
            <img
              src="./img/gov-fda-new-white.png"
              alt="food and drug administration website link"
            />
          </a>

          <h1>
            {t("Header.1")} <br /> {t("Header.2")}
          </h1>
          {userAuthenticated || authorizedStatus ? (
            <Dropdown
              onClick={(e: any) => e.stopPropagation()}
              alignRight={true}
            >
              <Dropdown.Toggle id="dropdown-basic" variant="info">
                {/* {userName}  */}
                {JSON.parse(sessionStorage.getItem("userName") || '""')}
                <PersonIcon />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Header>
                  {/* {userRole} */}{" "}
                  {JSON.parse(sessionStorage.getItem("userRole") || '""')}
                </Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={logoutHandler}>
                  {t("Translation.18")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <div style={{ visibility: "hidden" }}>
              <img
                src="./img/gov-fda-new-white.png"
                alt="food and drug administration logo"
              />
            </div>
          )}
        </div>

        <div className="bottom-row">
          <h2 style={{ fontSize: "1.5rem" }}>
            {t("Header.1")} - {t("Header.2")}
          </h2>
        </div>
      </div>
    </>
  );
};

export default Header;
