import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Alert from "@material-ui/lab/Alert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "../components/Spinner/Spinner";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "react-bootstrap/Popover";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// interface Props {

//   letterId:any;
//   name:any;
//   letterType:any;
//   baseDownloadURL:any;
// }

//TODO make sure there will be no null pointer exceptions!!!!!!!!!!!!!!
export const downloadSelectedDoc = (
  letterId: number,
  name: string,
  letterType: string,
  baseDownloadURL: string
) => {
  fetch(
    `${baseDownloadURL}?letterId=${letterId}&lang=${localStorage.getItem(
      "i18nextLng"
    )}&letterType=${letterType}`,
    {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }
  ).then((response: any) => {
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1];
    response.blob().then((blob: any) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
    });
  });
};

interface ExpiredPWDWindowProps {
  openPWDExpire: any;
  handleClosePWDExpire: any;
  pwdExpireMsg: any;
}

export const ExpiredPWDWindow: React.FC<ExpiredPWDWindowProps> = ({
  openPWDExpire,
  handleClosePWDExpire,
  pwdExpireMsg,
}) => {
  return (
    <Dialog
      open={openPWDExpire}
      onClose={handleClosePWDExpire}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description"> */}
        <Alert severity="warning">{pwdExpireMsg}</Alert>
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosePWDExpire} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const submitStatus = (status: any, dataUpdateStatus: any) => {
  if (status === 1) {
    return <Spinner />;
  } else if (status === 2) {
    return <Alert severity="success">{dataUpdateStatus}</Alert>;
  } else if (status === 3) {
    return <Alert severity="error">{dataUpdateStatus}</Alert>;
  } else if (status === 4) {
    return <Alert severity="warning">{dataUpdateStatus}</Alert>;
  }

  return null;
};

interface TooltipContainerProps {
  txt: string;
}

export const TooltipContainer: React.FC<TooltipContainerProps> = ({ txt }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const renderTooltip = (props: any) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      onMouseLeave={() => setShowTooltip(false)}
      onMouseEnter={() => setShowTooltip(true)}
    >
      {txt}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
      show={showTooltip}
    >
      <FontAwesomeIcon
        icon={faInfoCircle}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        // data-tip={tooltipTxt}
      />
    </OverlayTrigger>
  );
};

export const TooltipWithContent: React.FC = (props) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const popover = (
    // <TooltipWindow
    //   onMouseLeave={() => setShowTooltip(false)}
    //   onMouseEnter={() => setShowTooltip(true)}
    // >
    <Popover
      id="popover-basic"
      onMouseLeave={() => setShowTooltip(false)}
      onMouseEnter={() => setShowTooltip(true)}
    >
      <Popover.Content>{props.children}</Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      // trigger="click"
      delay={{ show: 250, hide: 400 }}
      placement="right"
      overlay={popover}
      show={showTooltip}
    >
      <FontAwesomeIcon
        icon={faInfoCircle}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        // data-tip={tooltipTxt}
      />
    </OverlayTrigger>
  );
};
