import React, { useState, useEffect, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import Table from "react-bootstrap/Table";
import axios from "axios";
import moment from "moment";
import Spinner from "../../../Spinner/Spinner";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { downloadSelectedDoc } from "../../../../utils/Utils";
import Alert from "@material-ui/lab/Alert";
import { submitStatus } from "../../../../utils/Utils";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { detectIE } from "../../../../utils/checkIE";

import {
  AttachContainer,
  LetterSection,
  UploadErrorMessage,
  ExtraMessageContainer,
  ExtraMessage,
  GoBackToSearchWrapper,
  letterSectionTitleStyle,
} from "../../Layout/Layout";

interface Props {
  checkboxText: string;
  displayMessage?: boolean;
  extraMessage?: string;
  setUpdateUser?: any;
  isCompanyAccount?: boolean;
  getAPI: string;
  postAPI: string;

  baseDownloadURL: string;
  checkboxTooltip: string;
}

const Authorization: React.FC<Props> = ({
  checkboxText,
  displayMessage,
  extraMessage,
  setUpdateUser,
  isCompanyAccount,
  getAPI,
  postAPI,

  baseDownloadURL,
  checkboxTooltip,
}) => {
  const { t } = useTranslation();

  const [authFile, setAuthFile] = useState<null | File>(null);
  const [authName, setAuthName] = useState(
    localStorage.getItem("i18nextLng") === "fr"
      ? "Joindre un fichier…"
      : "Attach a file..."
  );
  const [existingLetters, setExistingLetters] = useState<any>([]);
  const [fetchingData, setFetchingData] = useState(true);
  const [authUploadError, setAuthUploadError] = useState(false);
  const [submitFileSpinner, setSubmitFileSpinner] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState(false);

  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState(
    "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
  );

  const [fetchDataError, setFetchDataError] = useState(false);
  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState(false);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const [apiStatus, setApiStatus] = useState(0);
  const [dataUpdateStatus, setDataUpdateStatus] = useState("");

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const authLetterHandler = (e: any) => {
    const target = e.target as HTMLInputElement;

    const file: File = (target.files as FileList)[0];

    const fileName = file ? file.name : "";

    // setAuthFile(e.target.files[0]);
    // setAuthName(e.target.files[0].name);

    setAuthFile(file);
    setAuthName(fileName);

    setAuthUploadError(false);

    if (!detectIE()) {
      target.value = "";
    }
  };

  const fetchData = useCallback(() => {
    axios
      .get(getAPI, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          setFetchDataError(false);
          setFetchingData(false);
          setExistingLetters(res.data.existingLetters);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setFetchingData(false);
          setFetchDataErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );

          setFetchDataError(true);
        }
      });
  }, [getAPI]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const submitData = () => {
    if (authFile !== null) {
      if (checked) {
        setCheckedError(false);
        setSubmitFileSpinner(true);
        setUploadError(false);
        setUploadErrorMsg(
          "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
        );
        let UserData = new FormData();

        UserData.append("authLetterFile", authFile as any);
        UserData.append(
          "languageSelection",
          localStorage.getItem("i18nextLng") as any
        );

        setDisableSubmit(true);
        setApiStatus(0);
        axios
          .post(postAPI, UserData, {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          })
          .then((res) => {
            if (isMounted.current) {
              if (res.data.status === "SUCCESS") {
                setApiStatus(2);
              } else if (res.data.status === "WARNING") {
                setApiStatus(4);
              } else {
                setApiStatus(3);
              }
              setDataUpdateStatus(res.data.statusMessage);
              //get starts
              setFetchingData(true);
              axios
                .get(getAPI, {
                  headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  if (isMounted.current) {
                    setFetchDataError(false);
                    setFetchingData(false);
                    setExistingLetters(res.data.existingLetters);
                  }
                })
                .catch((err) => {
                  if (isMounted.current) {
                    setFetchingData(false);
                    setFetchDataErrorMsg(
                      err.response &&
                        err.response.data &&
                        err.response.data.statusMessage
                        ? err.response.data.statusMessage
                        : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
                    );

                    setFetchDataError(true);
                  }
                });

              //get ends

              // if (data.statusMessage === "Success") {
              //   setExistingLetters(updatedExistingLettersList);
              // }
              setSubmitFileSpinner(false);

              setDisableSubmit(false);

              setAuthFile(null);
              setAuthName(
                localStorage.getItem("i18nextLng") === "fr"
                  ? "Joindre un fichier…"
                  : "Attach a file..."
              );
              setChecked(false);
            }
          })
          .catch((err) => {
            if (isMounted.current) {
              setAuthFile(null);
              setAuthName(
                localStorage.getItem("i18nextLng") === "fr"
                  ? "Joindre un fichier…"
                  : "Attach a file..."
              );
              setChecked(false);
              setDisableSubmit(false);
              setSubmitFileSpinner(false);
              setUploadError(true);
              setUploadErrorMsg(
                err.response &&
                  err.response.data &&
                  err.response.data.statusMessage
                  ? err.response.data.statusMessage
                  : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
              );
            }
          });
      } else {
        setCheckedError(true);
      }
    } else {
      setAuthUploadError(true);
    }
  };

  return (
    <>
      <div>
        <LetterSection>
          <Typography
            variant="h4"
            component="h2"
            style={letterSectionTitleStyle}
          >
            {t("UploadNewAuth.2")}
          </Typography>
          {/* <LetterSectionTitle>{t("UploadNewAuth.2")}</LetterSectionTitle> */}
        </LetterSection>
        {uploadError && <Alert severity="error">{uploadErrorMsg}</Alert>}

        <div> {submitStatus(apiStatus, dataUpdateStatus)}</div>
        <AttachContainer>
          <Form style={{ width: "50%" }}>
            <Form.File
              // id="nrl"
              title={t("Translation.71")}
              id="authorization-file-upload"
              label={authName}
              custom
              onChange={authLetterHandler}
              isInvalid={authUploadError}
              data-browse={
                localStorage.getItem("i18nextLng") === "fr"
                  ? "Explorer"
                  : "Browse"
              }
            />
            {authUploadError ? (
              <UploadErrorMessage>{t("Translation.59")}</UploadErrorMessage>
            ) : null}
          </Form>
          <div>
            {submitFileSpinner ? <Spinner width="38px" height="38px" /> : null}
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{ justifySelf: "flex-end" }}
            onClick={submitData}
            disabled={disableSubmit || !checked || !authFile}
            title={t("UploadNewAuth.8")}
          >
            {t("UploadNewAuth.7")}
          </Button>
        </AttachContainer>
        <div style={{ paddingLeft: "30px" }}>
          {checkedError && (
            <UploadErrorMessage>{t("Translation.59")}</UploadErrorMessage>
          )}
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            }
            label={checkboxText}
            title={checkboxTooltip}
          />
        </div>

        {displayMessage ? (
          <ExtraMessageContainer>
            <ExtraMessage>
              {extraMessage}
              {/* Please Select "Update Company Accounts" from left navigation,
              search for an account to add letter into individual account. */}
            </ExtraMessage>
          </ExtraMessageContainer>
        ) : null}

        <LetterSection>
          <Typography
            variant="h4"
            component="h2"
            style={letterSectionTitleStyle}
          >
            {t("UploadNewAuth.9")}
          </Typography>
          {/* <LetterSectionTitle>{t("UploadNewAuth.9")}</LetterSectionTitle> */}
        </LetterSection>
        <div style={{ padding: "15px 30px" }}>
          {fetchDataError && (
            <Alert severity="error">{fetchDataErrorMsg}</Alert>
          )}

          {/* TODO make sure empty lists are also displayed */}
          {!fetchingData && existingLetters.length === 0 && !fetchDataError && (
            <p>{t("Errors.25")}</p>
          )}

          {fetchingData && <Spinner />}

          {!fetchingData && existingLetters.length !== 0 && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>{t("UploadNewAuth.10")}</th>
                  <th>{t("UploadNewAuth.11")}</th>
                </tr>
              </thead>
              <tbody>
                {baseDownloadURL && existingLetters
                  ? existingLetters.map((row: any) => (
                      <tr key={row.letterId}>
                        <td>
                          {moment(row.addedDate).calendar("dddd MMMM D Y")}
                        </td>
                        <td>
                          <Button
                            color="primary"
                            onClick={() =>
                              downloadSelectedDoc(
                                row.letterId,
                                row.fileName,
                                "auth",
                                baseDownloadURL
                              )
                            }
                          >
                            {row.fileName}
                          </Button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          )}
        </div>
        {isCompanyAccount && setUpdateUser ? (
          <GoBackToSearchWrapper>
            <Button
              variant="outlined"
              color="secondary"
              onClick={setUpdateUser}
              title={t("UploadNewAuth.18")}
            >
              {t("UploadNewAuth.17")}
            </Button>
          </GoBackToSearchWrapper>
        ) : null}
      </div>
    </>
  );
};

export default Authorization;
