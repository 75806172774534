import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import CancelRegistration from "../CancelRegistration";
import { useTranslation } from "react-i18next";

interface Props {
  continueHandler: any;
  previousHandler: any;
  previousOption: any;
  disbale?: any;
}

const FormFooter: React.FC<Props> = ({
  continueHandler,
  previousHandler,
  previousOption,
  disbale,
}) => {
  const [cancelRegistration, setCancelRegistration] = useState(false);

  const { t } = useTranslation();

  let history = useHistory();

  const stopRegistrationCancel = () => {
    setCancelRegistration(false);
  };

  const goBackToLogin = () => {
    history.push("/");
  };
  return (
    <div className="form-footer-wrapper">
      <div>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setCancelRegistration(!cancelRegistration)}
          title={t("Tooltips.11")}
          className="form-footer-cancel-button"
        >
          {t("Registration.39")}
        </Button>
        <CancelRegistration
          cancelRegistration={cancelRegistration}
          cancelRegistrationHandler={stopRegistrationCancel}
          goBackToLogin={goBackToLogin}
        />
        {previousOption ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={previousHandler}
            title={t("Tooltips.43")}
          >
            {t("Registration.41")}
          </Button>
        ) : null}
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={continueHandler}
        title={t("Tooltips.12")}
        disabled={disbale}
      >
        {t("Registration.40")}
      </Button>
    </div>
  );
};

export default FormFooter;
