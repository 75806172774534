import React from "react";
import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="footer-links">
        
        <Link
          href="https://www.fda.gov/industry/electronic-submissions-gateway"
          target="_blank"
          title={t("Tooltips.3")}
        >
          {t("Footer.3")}
        </Link>
        <Link
          href="https://www.fda.gov/industry/create-esg-account/account-faqs"
          target="_blank"
          title={t("Tooltips.2")}
        >
          {t("Footer.2")}
        </Link>
        <Link
          href="http://www.fda.gov/ForIndustry/ElectronicSubmissionsGateway/AboutESG/ucm367545.htm"
          target="_blank"
          title={t("Tooltips.1")}
        >
          {t("Footer.1")}
        </Link>  
        <Link
          href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html"
          target="_blank"
          title={t("Tooltips.72")}
        >
          {t("Footer.4")}
        </Link> 
        <Link
          href="https://www.fda.gov/industry/about-esg/esg-accounts-management-portal-user-guide"
          target="_blank"
          title={t("Tooltips.73")}
        >
          {t("Footer.5")}
        </Link>      
      </div>
    </footer>
  );
};

export default Footer;
