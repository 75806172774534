import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import DatePicker, { registerLocale } from "react-datepicker";

import Spinner from "../../../Spinner/Spinner";
import axios from "axios";

import { Alert } from "@material-ui/lab";
import { Formik, Form, ErrorMessage } from "formik";
import { InputWithInfoIcon } from "../../../../utils/Input";
import { useTranslation } from "react-i18next";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

const TableDiv = styled.div`
  border: 1px solid grey;

  border-radius: 5px;
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  row: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#001871",
      color: theme.palette.common.white,
      fontSize: 20,
    },
  })
)(TableCell);

const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
  margin: 0 auto;

  @media (min-width: 1060px) {
    width: 60%;
  }
`;

export const ErrorMsg = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #c60000;
`;

const TableWrapper = styled.div`
  padding: 30px;
`;

interface Props {
  inputAccountName?: any;
}

const StatusOfSubmissions: React.FC<Props> = ({ inputAccountName }) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>(null);

  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { t } = useTranslation();

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const searchSubmission = (values: any, actions: any) => {
    // setStartSearch(true);
    actions.setSubmitting(true);

    setError(false);
    setErrorMsg("");
    setLoading(true);
    setRows(null);

    let SubmissionsData = new FormData();

    SubmissionsData.append("coreID", values.coreID);
    SubmissionsData.append("messageID", values.messageID);
    SubmissionsData.append("fileName", values.fileName);
    SubmissionsData.append("from", values.fromDate as any);
    SubmissionsData.append("to", values.toDate as any);

    if (values.accountName) {
      SubmissionsData.append("accountName", values.accountName);
    }

    axios
      .post(
        `/api/searchSubStatus?userid=${sessionStorage.getItem("userID")}`,
        SubmissionsData,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (isMounted.current) {
          setRows(res.data);

          actions.setSubmitting(false);

          setLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          actions.setSubmitting(false);

          setError(true);
          setErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );

          setLoading(false);
        }
      });
  };

  return (
    <>
      <div>
        <UserInputContainer>
          <p>{t("Translation.62")}</p>
          <br />

          {inputAccountName ? (
            <p>{t("SearchStatus.30")}</p>
          ) : (
            <p>{t("SearchStatus.2")}</p>
          )}

          <br />
          <br />

          <Formik
            initialValues={{
              accountName: "",
              coreID: "",
              messageID: "",
              fileName: "",
              fromDate: null,
              toDate: null,
            }}
            // onSubmit={(values) => console.log(values)}
            onSubmit={(values: any, actions: any) => {
              setPage(0);
              searchSubmission(values, actions);
              // dispatch(startSearch(values, actions, cancelSource));
            }}
            validate={(values) => {
              interface Errors {
                coreID?: string;
                fromDate?: string;
                toDate?: string;
              }

              let errors: Errors = {};

              if (
                !values.accountName &&
                !values.coreID &&
                !values.messageID &&
                !values.fileName &&
                (rows === null || rows === undefined)
              ) {
                if (inputAccountName) {
                  errors.coreID = t("Translation.61");
                } else {
                  errors.coreID = t("Translation.69");
                }
              }

              if (!values.fromDate) {
                errors.fromDate = t("Translation.59");
              }

              if (!values.toDate) {
                errors.toDate = t("Translation.59");
              }

              return errors;
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              errors,
              touched,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <ErrorMessage name="coreID">
                    {(msg) => <Alert severity="error">{msg}</Alert>}
                  </ErrorMessage>
                  <br />
                  {inputAccountName ? (
                    <>
                      <InputWithInfoIcon
                        label={t("SearchStatus.20")}
                        name="accountName"
                        id="accountName"
                        value={values.accountName}
                        onChange={handleChange}
                        onBlur={() => {}}
                        tooltipTxt={t("SearchStatus.29")}
                        titleTxt={t("SearchStatus.28")}
                      />

                      <br />
                    </>
                  ) : null}

                  <InputWithInfoIcon
                    label={t("SearchStatus.3")}
                    name="coreID"
                    id="coreID"
                    value={values.coreID}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    onBlur={() => {}}
                    tooltipTxt={t("SearchStatus.5")}
                    titleTxt={t("SearchStatus.4")}
                  />

                  <br />

                  <InputWithInfoIcon
                    label={t("SearchStatus.6")}
                    name="messageID"
                    id="messageID"
                    value={values.messageID}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    onBlur={() => {}}
                    tooltipTxt={t("SearchStatus.8")}
                    titleTxt={t("SearchStatus.7")}
                  />

                  <br />

                  <InputWithInfoIcon
                    label={t("SearchStatus.9")}
                    name="fileName"
                    id="fileName"
                    value={values.fileName}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    onBlur={() => {}}
                    tooltipTxt={t("SearchStatus.11")}
                    titleTxt={t("SearchStatus.10")}
                  />

                  <br />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div title={t("SearchStatus.13")}>
                      {/* <p>{t("SearchStatus.12")}</p> */}
                      <label htmlFor="fromDate" style={{ display: "block" }}>
                        {t("SearchStatus.12")}
                      </label>
                      <DatePicker
                        id="fromDate"
                        onChange={(date) => setFieldValue("fromDate", date)}
                        selected={values.fromDate}
                        locale={
                          localStorage.i18nextLng
                            ? localStorage.i18nextLng
                            : "en"
                        }
                      />
                      <ErrorMessage name="fromDate">
                        {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                      </ErrorMessage>
                    </div>
                    <div title={t("SearchStatus.15")}>
                      <label htmlFor="toDate" style={{ display: "block" }}>
                        {t("SearchStatus.14")}
                      </label>

                      <DatePicker
                        id="toDate"
                        onChange={(date) => setFieldValue("toDate", date)}
                        selected={values.toDate}
                        locale={
                          localStorage.i18nextLng
                            ? localStorage.i18nextLng
                            : "en"
                        }
                      />
                      <ErrorMessage name="toDate">
                        {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                      </ErrorMessage>
                    </div>
                  </div>

                  <br />
                  <br />

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: "flex-end" }}
                    type="submit"
                    disabled={
                      isSubmitting ||
                      (!values.accountName &&
                        !values.coreID &&
                        !values.messageID &&
                        !values.fileName &&
                        (rows !== null || rows !== undefined))
                    }
                    title={t("SearchStatus.17")}
                  >
                    {t("SearchStatus.16")}
                  </Button>
                </Form>
              );
            }}
          </Formik>

          <br />

          {rows && rows.length === 0 && (
            <div>
              {inputAccountName ? (
                <p>{t("Translation.63")}</p>
              ) : (
                <p>{t("Translation.75")}</p>
              )}
            </div>
          )}
        </UserInputContainer>

        {loading && <Spinner />}

        {error && <Alert severity="error">{errorMsg}</Alert>}

        {rows && rows.length !== 0 && (
          <TableWrapper>
            <TableContainer component={TableDiv}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t("SearchStatus.19")}</StyledTableCell>
                    <StyledTableCell align="right">
                      {t("SearchStatus.20")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t("SearchStatus.23")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t("SearchStatus.21")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t("SearchStatus.22")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t("SearchStatus.24")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t("SearchStatus.25")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t("SearchStatus.26")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {t("SearchStatus.27")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => (
                      <TableRow key={row.id} hover>
                        <TableCell component="th" scope="row">
                          {row.fileName}
                        </TableCell>
                        <TableCell align="right">{row.accountName}</TableCell>
                        <TableCell align="right">{row.center}</TableCell>
                        <TableCell align="right">
                          {row.submissionType}
                        </TableCell>
                        <TableCell align="right">{row.messageID}</TableCell>
                        <TableCell align="right">{row.receiptTime}</TableCell>
                        <TableCell align="right">
                          {row.acknowledgement2}
                        </TableCell>
                        <TableCell align="right">
                          {row.acknowledgement3}
                        </TableCell>
                        <TableCell align="right">
                          {row.acknowledgement4}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
            />
          </TableWrapper>
        )}
      </div>
    </>
  );
};

export default StatusOfSubmissions;
