import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { InputWithInfoIcon } from "../../../../utils/Input";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../../../Spinner/Spinner";
import Alert from "@material-ui/lab/Alert";
import { Formik, Form, ErrorMessage } from "formik";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";

const TableDiv = styled.div`
  border: 1px solid grey;

  border-radius: 5px;
`;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  row: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#001871",
      color: theme.palette.common.white,
      fontSize: 20,
    },
  })
)(TableCell);

const SearchStatusInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px;
  margin: 0 auto;
  @media (min-width: 1060px) {
    width: 60%;
  }
`;

const TableWrapper = styled.div`
  padding: 30px;
`;

interface Props {
  setUpdateUser: any;
  setIsWT: any;
  setSelectedUserId: any;
  setRoleId: any;
  postAPICompanySearch: any;
  setRows: any;
  rows: any;
  setSelectedNodeText: (param: any) => void;
}

const SearchForUser: React.FC<Props> = ({
  setUpdateUser,
  setIsWT,
  setSelectedUserId,
  setRoleId,
  postAPICompanySearch,
  rows,
  setRows,
  setSelectedNodeText,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitHandler = (values: any, actions: any) => {
    setPage(0);
    setRows(null);

    setLoading(true);
    setError(false);

    actions.setSubmitting(true);

    let SubmissionsData = new FormData();

    SubmissionsData.append("accountName", values.accountName);
    SubmissionsData.append("emailID", values.emailId);
    SubmissionsData.append("firstName", values.firstName);
    SubmissionsData.append("lastName", values.lastName);

    axios
      .post(postAPICompanySearch, SubmissionsData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          actions.setSubmitting(false);
          setLoading(false);

          let accounts = res.data.accounts.map((row: any) => {
            let updatedRow = { ...row };
            updatedRow.id = uuidv4();

            updatedRow.role = Number(updatedRow.role);

            updatedRow.enabledOrDisabled =
              updatedRow.enabledOrDisabled === 1 ? "Disabled" : "Enabled";

            return updatedRow;
          });

          setRows(accounts);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          actions.setSubmitting(false);
          setLoading(false);
          setError(true);
          setErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
        }
      });
  };

  const handleRowClick = (row: any) => {
    setSelectedNodeText("Update Company Accounts: " + row.accountName);
    setRoleId(row.role);

    setUpdateUser(true);

    setSelectedUserId(row.userId);

    let isWtRole = Number(row.role) === 1 || Number(row.role) === 2;

    setIsWT(isWtRole);
  };

  return (
    <>
      <SearchStatusInputContainer>
        <p>{t("UpdateCompanyAccounts.2")}</p>
        <br />

        <Formik
          initialValues={{
            accountName: "",
            emailId: "",
            firstName: "",
            lastName: "",
          }}
          // enableReinitialize={true}
          onSubmit={submitHandler}
          validate={(values) => {
            interface Errors {
              accountName?: string;
              emailId?: string;
              firstName?: string;
              lastName?: string;
            }

            let errors: Errors = {};

            if (
              !values.accountName &&
              !values.emailId &&
              !values.firstName &&
              !values.lastName &&
              (rows === null || rows === undefined)
            ) {
              errors.accountName = t("Errors.26");
            }

            return errors;
          }}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => {
            return (
              <Form>
                <ErrorMessage name="accountName">
                  {(msg) => <Alert severity="error">{msg}</Alert>}
                </ErrorMessage>
                <br />

                <InputWithInfoIcon
                  label={t("UpdateCompanyAccounts.3")}
                  tooltipTxt={t("UpdateCompanyAccounts.5")}
                  titleTxt={t("UpdateCompanyAccounts.4")}
                  name="accountName"
                  id="accountName"
                  value={values.accountName}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onBlur={() => {}}
                />
                <br />
                <InputWithInfoIcon
                  label={t("UpdateCompanyAccounts.12")}
                  tooltipTxt={t("UpdateCompanyAccounts.14")}
                  titleTxt={t("UpdateCompanyAccounts.13")}
                  name="emailId"
                  id="emailId"
                  value={values.emailId}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onBlur={() => {}}
                />
                <br />
                <InputWithInfoIcon
                  label={t("UpdateCompanyAccounts.6")}
                  tooltipTxt={t("UpdateCompanyAccounts.8")}
                  titleTxt={t("UpdateCompanyAccounts.7")}
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onBlur={() => {}}
                />
                <br />
                <InputWithInfoIcon
                  label={t("UpdateCompanyAccounts.9")}
                  tooltipTxt={t("UpdateCompanyAccounts.11")}
                  titleTxt={t("UpdateCompanyAccounts.10")}
                  name="lastName"
                  id="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onBlur={() => {}}
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  style={{ alignSelf: "flex-end" }}
                  title={t("UpdateCompanyAccounts.16")}
                  type="submit"
                  disabled={
                    isSubmitting ||
                    (!values.accountName &&
                      !values.emailId &&
                      !values.firstName &&
                      !values.lastName &&
                      (rows !== null || rows !== undefined))
                  }
                  // onClick={handleSearchStatus}
                >
                  {t("UpdateCompanyAccounts.15")}
                </Button>
              </Form>
            );
          }}
        </Formik>
        <br />
        {rows && rows.length === 0 && (
          <div>
            There are no accounts found for the company, for the selection
            criteria.
          </div>
        )}
      </SearchStatusInputContainer>

      {loading && <Spinner />}

      {error && <Alert severity="error">{errorMsg}</Alert>}

      {rows && rows.length !== 0 && (
        <TableWrapper>
          <p>{t("Translation.1")}</p>
          <TableContainer component={TableDiv}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{t("Translation.64")}</StyledTableCell>
                  <StyledTableCell align="right">
                    {t("Translation.65")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {t("Translation.66")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {t("Translation.67")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {t("Translation.68")}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any) => (
                    <TableRow
                      hover
                      key={row.userId}
                      onClick={() => handleRowClick(row)}
                      className={classes.row}
                    >
                      <TableCell component="th" scope="row">
                        {row.accountName}
                      </TableCell>
                      <TableCell align="right">{row.emailID}</TableCell>
                      <TableCell align="right">{row.roleDesc}</TableCell>
                      <TableCell align="right">
                        {row.enabledOrDisabled}
                      </TableCell>
                      <TableCell align="right">{row.routingId}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
          />

        </TableWrapper>
      )}
    </>
  );
};

export default SearchForUser;
