import React, { useState, useEffect, useCallback } from "react";
import {
  InputWithInfoIcon,
  NotEditableInputWithInfoIcon,
} from "../../../../utils/Input";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import Spinner from "../../../Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import { submitStatus } from "../../../../utils/Utils";
import {
  InputRow,
  UpdateProfileWrapper,
  SubmitContainerWrapper,
  ErrorMsg,
  SubmitStatusContainer,
} from "../../Layout/Layout";
import { ProfileSchema } from "../../../../utils/Verification";
import FormControl from "@material-ui/core/FormControl";

interface Props {
  getAPI: string;
  postAPI: string;
  appendRole?: boolean;
  roleId?: string;
  showAccountNameInput?: boolean;
  isCompanyAccount?: boolean;
  setUpdateUser?: any;
  showRoutingId?: boolean;
}

interface Values {
  fName: string;
  lName: string;
  mInitial: string;
  pPhone: string;
  suffix: string;
  company: string;
}

const UpdateProfile: React.FC<Props> = ({
  getAPI,
  postAPI,
  appendRole,
  roleId,
  showAccountNameInput,
  isCompanyAccount,
  setUpdateUser,
  showRoutingId,
}) => {
  const { t } = useTranslation();
  const [userID, setUserID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [mInitial, setMInitial] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [emailID, setEmailID] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [accountName, setAccountName] = useState("");
  const [fetchingData, setFetchingData] = useState(true);
  const [apiStatus, setApiStatus] = useState(0);
  const [dataUpdateStatus, setDataUpdateStatus] = useState("");
  const [fetchDataError, setFetchDataError] = useState(false);
  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState(false);

  const [routingId, setRoutingId] = useState("");
  const [company, setCompany] = useState("");

  let isMounted = React.useRef(false);

  const fetchData = useCallback(() => {
    axios
      .get(getAPI, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          setFetchDataError(false);

          const {
            emailID,
            firstName,
            lastName,
            middleInitial,
            phone,
            accountName,
            suffix,
            loginUserId,
            routingId,
            company,
          } = res.data;

          setRoutingId(routingId);
          setSuffix(suffix);
          setLastName(lastName);
          setMInitial(middleInitial);
          setUserID(loginUserId);
          setEmailID(emailID);
          setPrimaryPhone(phone);
          setFirstName(firstName);
          setAccountName(accountName);
          setFetchingData(false);
          setCompany(company);
        }
      })

      .catch((err) => {
        if (isMounted.current) {
          setFetchingData(false);
          setFetchDataErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setFetchDataError(true);
        }
      });
  }, [getAPI]);

  useEffect(() => {
    isMounted.current = true;
    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [fetchData]);

  const handleFormSubmit = (values: Values, actions: FormikHelpers<Values>) => {
    setApiStatus(1);
    actions.setSubmitting(true);
    let UserData = new FormData();

    UserData.append("firstName", values.fName);
    UserData.append("lastName", values.lName);
    UserData.append("suffix", values.suffix ? values.suffix : "");
    UserData.append("middleInitial", values.mInitial ? values.mInitial : "");
    UserData.append("phone", values.pPhone ? values.pPhone : "");

    if (appendRole) {
      UserData.append("role", roleId ? roleId : "");
    }

    UserData.append(
      "languageSelection",
      localStorage.getItem("i18nextLng") as any
    );

    axios
      .post(postAPI, UserData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          //check PENDING SUCCESS ERROR
          if (res.data.status === "SUCCESS") {
            //Add status statusMessage
            //TO THE MESSAGE DISPLAYED AFTER SUBMIT.

            setApiStatus(2);
          } else if (res.data.status === "WARNING") {
            setApiStatus(4);
          } else {
            setApiStatus(3);
          }
          setDataUpdateStatus(res.data.statusMessage);
          actions.setSubmitting(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setDataUpdateStatus(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setApiStatus(3);
          actions.setSubmitting(false);
        }
      });
  };

  if (fetchingData) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    if (fetchDataError) {
      return <Alert severity="error">{fetchDataErrorMsg}</Alert>;
    } else {
      return (
        <>
          <Formik
            initialValues={{
              fName: firstName ? firstName : "",
              mInitial: mInitial ? mInitial : "",
              lName: lastName ? lastName : "",
              suffix: suffix ? suffix : "",
              pPhone: primaryPhone ? primaryPhone : "",
              company: company ? company : "",
            }}
            enableReinitialize={true}
            onSubmit={handleFormSubmit}
            validationSchema={() =>
              ProfileSchema(t("Translation.59"), t("Errors.22"), t("Errors.23"))
            }
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => {
              return (
                <Form>
                  <UpdateProfileWrapper>
                    {showRoutingId && (
                      <>
                        <FormControl
                          style={{ width: "100%", marginBottom: "1.3rem" }}
                        >
                          <InputRow className="input-row-styles">
                            <label htmlFor="routingId">
                              {t("UpdateProfilePU.24")}
                            </label>
                            {/* <p>{t("UpdateProfilePU.24")}</p> */}
                            <NotEditableInputWithInfoIcon
                              id="routingId"
                              value={routingId}
                            />
                          </InputRow>
                        </FormControl>
                        <br />
                      </>
                    )}

                    {showAccountNameInput ? (
                      <>
                        <FormControl
                          style={{ width: "100%", marginBottom: "1.3rem" }}
                        >
                          <InputRow className="input-row-styles">
                            {/* <p>{t("UpdateProfilePU.23")}</p> */}
                            <label htmlFor="accountName">
                              {t("UpdateProfilePU.23")}
                            </label>
                            <NotEditableInputWithInfoIcon
                              id="accountName"
                              value={accountName}
                            />
                          </InputRow>
                        </FormControl>
                        <br />
                      </>
                    ) : null}
                    <FormControl
                      style={{ width: "100%", marginBottom: "1.3rem" }}
                    >
                      <InputRow className="input-row-styles">
                        {/* <p>{t("UpdateProfilePU.2")}</p> */}
                        <label htmlFor="userId">{t("UpdateProfilePU.2")}</label>
                        <NotEditableInputWithInfoIcon
                          value={userID}
                          id="userId"
                        />{" "}
                      </InputRow>
                    </FormControl>
                    <br />
                    
                    <FormControl
                      style={{ width: "100%", marginBottom: "1.3rem" }}
                    >
                      <InputRow className="input-row-styles">
                        {/* <p>{t("UpdateProfilePU.26")}</p> */}
                        <label htmlFor="company">{t("UpdateProfilePU.26")}</label>
                        <NotEditableInputWithInfoIcon
                          value={company}
                          id="company"
                        />{" "}
                      </InputRow>
                    </FormControl>
                    <br />

                    <FormControl
                      style={{ width: "100%", marginBottom: "1.3rem" }}
                    >
                      <InputRow className="input-row-styles">
                        {/* <p>{t("UpdateProfilePU.3")}</p> */}
                        <label htmlFor="fName">{t("UpdateProfilePU.3")}</label>
                        <div>
                          <InputWithInfoIcon
                            name="fName"
                            id="fName"
                            value={values.fName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            tooltipTxt={t("UpdateProfilePU.4")}
                            titleTxt={t("UpdateProfilePU.5")}
                            error={errors.fName && touched.fName}
                            maxLength={40}
                          />
                          <ErrorMessage name="fName">
                            {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                          </ErrorMessage>
                        </div>
                      </InputRow>
                    </FormControl>
                    <br />
                    <FormControl
                      style={{ width: "100%", marginBottom: "1.3rem" }}
                    >
                      <InputRow className="input-row-styles">
                        <label htmlFor="mInitial">
                          {t("UpdateProfilePU.6")}
                        </label>
                        {/* <p>{t("UpdateProfilePU.6")}</p> */}
                        <div>
                          <InputWithInfoIcon
                            name="mInitial"
                            id="mInitial"
                            value={values.mInitial}
                            tooltipTxt={t("UpdateProfilePU.8")}
                            titleTxt={t("UpdateProfilePU.7")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={1}
                            error={errors.mInitial && touched.mInitial}
                          />
                          <ErrorMessage name="mInitial">
                            {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                          </ErrorMessage>{" "}
                        </div>
                      </InputRow>
                    </FormControl>
                    <br />
                    <FormControl
                      style={{ width: "100%", marginBottom: "1.3rem" }}
                    >
                      <InputRow className="input-row-styles">
                        <label htmlFor="lName">{t("UpdateProfilePU.9")}</label>
                        {/* <p>{t("UpdateProfilePU.9")}</p> */}
                        <div>
                          <InputWithInfoIcon
                            name="lName"
                            id="lName"
                            value={values.lName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            tooltipTxt={t("UpdateProfilePU.11")}
                            titleTxt={t("UpdateProfilePU.10")}
                            maxLength={45}
                            error={errors.lName && touched.lName}
                          />
                          <ErrorMessage name="lName">
                            {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                          </ErrorMessage>{" "}
                        </div>
                      </InputRow>
                    </FormControl>
                    <br />
                    <FormControl
                      style={{ width: "100%", marginBottom: "1.3rem" }}
                    >
                      <InputRow className="input-row-styles">
                        {/* <p>{t("UpdateProfilePU.12")}</p> */}
                        <label htmlFor="suffix">
                          {t("UpdateProfilePU.12")}
                        </label>
                        <div>
                          <InputWithInfoIcon
                            name="suffix"
                            id="suffix"
                            tooltipTxt={t("UpdateProfilePU.14")}
                            titleTxt={t("UpdateProfilePU.13")}
                            value={values.suffix}
                            maxLength={5}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.suffix && touched.suffix}
                          />
                          <ErrorMessage name="suffix">
                            {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                          </ErrorMessage>{" "}
                        </div>
                      </InputRow>
                    </FormControl>
                    <br />
                    <FormControl
                      style={{ width: "100%", marginBottom: "1.3rem" }}
                    >
                      <InputRow className="input-row-styles">
                        {/* <p>{t("UpdateProfilePU.15")}</p> */}
                        <label htmlFor="emailId">
                          {t("UpdateProfilePU.15")}
                        </label>
                        <NotEditableInputWithInfoIcon
                          value={emailID}
                          id="emailId"
                        />{" "}
                      </InputRow>
                    </FormControl>
                    <br />
                    <FormControl
                      style={{ width: "100%", marginBottom: "1.3rem" }}
                    >
                      <InputRow className="input-row-styles">
                        {/* <p>{t("UpdateProfilePU.18")}</p> */}

                        <label htmlFor="pPhone">
                          {t("UpdateProfilePU.18")}
                        </label>
                        <div>
                          <InputWithInfoIcon
                            name="pPhone"
                            id="pPhone"
                            value={values.pPhone}
                            tooltipTxt={t("UpdateProfilePU.20")}
                            titleTxt={t("UpdateProfilePU.25")}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={25}
                            error={errors.pPhone && touched.pPhone}
                          />
                          {errors.pPhone && touched.pPhone ? 
                            (<div className="error-container">
                                  <p>{t("Errors.34")}</p>
                                  <p>{t("Errors.35")}</p>
                                  <ul style={{ listStylePosition: "inside" }}>
                                    <li>{t("Errors.36")}</li>
                                    <li>{t("Errors.37")}</li>
                                    <li>{t("Errors.38")}</li>
                                    <li>{t("Errors.39")}</li>
                                  </ul>
                                </div>
                            ):(<p className="error-mssg-input-fields error-mssg-input-fields-regular">
                            {t("Registration.20")}
                          </p>)}
                        </div>
                      </InputRow>
                    </FormControl>
                    <br />

                    <br />

                    <SubmitStatusContainer>
                      {submitStatus(apiStatus, dataUpdateStatus)}
                    </SubmitStatusContainer>

                    <SubmitContainerWrapper>
                      {isCompanyAccount && setUpdateUser ? (
                        <Button
                          variant="outlined"
                          style={{ marginRight: "2rem" }}
                          color="secondary"
                          onClick={setUpdateUser}
                        >
                          Go Back to Account Search
                        </Button>
                      ) : null}

                      <Button
                        variant="contained"
                        color="primary"
                        title={t("UpdateProfilePU.22")}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {t("UpdateProfilePU.21")}
                      </Button>
                    </SubmitContainerWrapper>
                  </UpdateProfileWrapper>
                </Form>
              );
            }}
          </Formik>
        </>
      );
    }
  }
};

export default UpdateProfile;
