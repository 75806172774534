import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { InputWithInfoIcon } from "../../../../utils/Input";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import { submitStatus } from "../../../../utils/Utils";
import { PasswordResetSchema } from "../../../../utils/Verification";
import {
  ErrorMsg,
  MainContainerPWD,
  InputSectionPWD,
  InputRowPWD,
  SubmitSectionPWD,
} from "../../Layout/Layout";

interface Props {
  postAPI: string;
}

interface Values {
  currentPwd: string;
  newPwd: string;
  reenterNewPwd: string;
}

const PasswordUpdate: React.FC<Props> = ({ postAPI }) => {
  const { t } = useTranslation();

  const [apiStatus, setApiStatus] = useState(0);
  const [pwdUpdateStatus, setPwdUpdateStatus] = useState("");

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFormSubmit = (values: Values, actions: FormikHelpers<Values>) => {
    setApiStatus(1);
    actions.setSubmitting(true);

    let UserPassword = new FormData();

    UserPassword.append("currentPassword", values.currentPwd);
    UserPassword.append("newPassword", values.newPwd);
    UserPassword.append(
      "languageSelection",
      localStorage.getItem("i18nextLng") as any
    );

    axios
      .post(postAPI, UserPassword, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          if (res.data.status === "SUCCESS") {
            setApiStatus(2);
          } else if (res.data.status === "WARNING") {
            setApiStatus(4);
          } else {
            setApiStatus(3);
          }
          setPwdUpdateStatus(res.data.statusMessage);

          actions.setSubmitting(false);
          actions.resetForm();
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setPwdUpdateStatus(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setApiStatus(3);

          actions.setSubmitting(false);
          actions.resetForm();
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={{ currentPwd: "", newPwd: "", reenterNewPwd: "" }}
        onSubmit={handleFormSubmit}
        validationSchema={() =>
          PasswordResetSchema(t("Translation.59"), t("Errors.24"))
        }
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form>
              <MainContainerPWD>
                <div style={{ marginBottom: "2.5rem", fontWeight: 600 }}>
                  <p>{t("UpdatePassword.2")}</p>
                </div>
                <InputSectionPWD>
                  <InputRowPWD first className="input-row-styles">
                    {/* <p>{t("UpdatePassword.3")}</p> */}
                    <label htmlFor="currentPwd">{t("UpdatePassword.3")}</label>
                    <div>
                      <InputWithInfoIcon
                        autoComplete="current-password"
                        name="currentPwd"
                        id="currentPwd"
                        value={values.currentPwd}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        tooltipTxt={t("UpdatePassword.5")}
                        titleTxt={t("UpdatePassword.4")}
                        type="password"
                        error={errors.currentPwd && touched.currentPwd}
                      />
                      <ErrorMessage name="currentPwd">
                        {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                      </ErrorMessage>
                    </div>
                  </InputRowPWD>

                  <InputRowPWD className="input-row-styles">
                    {/* <p>{t("UpdatePassword.6")}</p> */}

                    <label htmlFor="newPwd">{t("UpdatePassword.6")}</label>
                    <div>
                      <InputWithInfoIcon
                        autoComplete="new-password"
                        name="newPwd"
                        id="newPwd"
                        value={values.newPwd}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        tooltipTxt={t("UpdatePassword.8")}
                        titleTxt={t("UpdatePassword.7")}
                        type="password"
                        error={errors.newPwd && touched.newPwd}
                      />
                      {errors.newPwd && touched.newPwd && (
                        <div className="error-container">
                          <p>{t("Errors.14")}</p>
                          <ul style={{ listStylePosition: "inside" }}>
                            <li>{t("Errors.15")}</li>
                            <li>{t("Errors.16")}</li>
                            <li>{t("Errors.17")}</li>
                            <li>{t("Errors.18")}</li>
                            <li>{t("Errors.19")}</li>
                            <li> {t("Errors.20")}</li>
                            <li>{t("Errors.21")}</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </InputRowPWD>

                  <InputRowPWD className="input-row-styles">
                    {/* <p>{t("UpdatePassword.9")}</p> */}
                    <label htmlFor="reenterNewPwd">
                      {t("UpdatePassword.9")}
                    </label>
                    <div>
                      <InputWithInfoIcon
                        autoComplete="new-password"
                        name="reenterNewPwd"
                        id="reenterNewPwd"
                        value={values.reenterNewPwd}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        tooltipTxt={t("UpdatePassword.11")}
                        titleTxt={t("UpdatePassword.10")}
                        type="password"
                        error={errors.reenterNewPwd && touched.reenterNewPwd}
                      />
                      <ErrorMessage name="reenterNewPwd">
                        {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                      </ErrorMessage>{" "}
                    </div>
                  </InputRowPWD>
                </InputSectionPWD>
                <SubmitSectionPWD>
                  <div>{submitStatus(apiStatus, pwdUpdateStatus)}</div>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    title={t("UpdatePassword.13")}
                    disabled={
                      isSubmitting ||
                      !values.currentPwd ||
                      !values.newPwd ||
                      !values.reenterNewPwd ||
                      errors.reenterNewPwd !== undefined ||
                      errors.newPwd !== undefined
                    }
                  >
                    {t("UpdatePassword.12")}
                  </Button>
                </SubmitSectionPWD>
              </MainContainerPWD>
            </Form>
          );
        }}
      </Formik>
      <br />
    </>
  );
};

export default PasswordUpdate;
