import React, { useState, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { submitStatus } from "../../utils/Utils";
import Spinner from "../Spinner/Spinner";
import { Formik, Form, ErrorMessage } from "formik";
import { InputWithInfoIcon } from "../../utils/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { PasswordSchema } from "../../utils/Verification";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const GetNewPasswordInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid lightgrey;
  padding-top: 10px;
  margin-bottom: 20px;
`;
const LogInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CAPTCHAContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ErrorMsg = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #c60000;
  text-align: left;
`;

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HintContainer = styled.div`
  text-align: left;
`;
const HintText = styled.p`
  color: rgba(0, 0, 0, 0.6);

  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 3px 0px 0px;
`;

const PasswordReset = () => {
  const [showMsg, setShowMsg] = useState(false);

  const [dataUpdateStatus, setDataUpdateStatus] = useState("");
  const [status, setStatus] = useState(0);

  const [imgCode, setImgCode] = useState("");
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  const [submittingEmailLoader, setSubmittingEmailLoader] = useState(false);

  const [captchaErr, setCaptchaErr] = useState(false);

  const [captchaValue, setCaptchaValue] = useState("");

  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState("");
  const [fetchDataError, setFetchDataError] = useState(false);

  const { t } = useTranslation();

  let history = useHistory();

  const fetchCAPTCHA = useCallback(() => {
    Axios.get(`/api/captcha`)
      .then((res) => {
        setLoadingCaptcha(false);
        setImgCode(res.data.captchaImage);
        setCaptchaValue(res.data.value);

        setFetchDataError(false);
      })
      .catch((err) => {
        setLoadingCaptcha(false);

        setFetchDataErrorMsg(
          err.response && err.response.data && err.response.data.statusMessage
            ? err.response.data.statusMessage
            : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
        );

        setFetchDataError(true);
      });
  }, []);

  React.useEffect(() => {
    fetchCAPTCHA();
  }, [fetchCAPTCHA]);

  const handleReset = (values: any, actions: any) => {
    if (values.userCaptchaInput === captchaValue) {
      let UserData = new FormData();

      UserData.append("resetUserId", values.userId);
      UserData.append("tmpPassword", values.tempPwd);
      UserData.append("password", values.newPwd);
      UserData.append("captcha", values.userCaptchaInput);
      UserData.append(
        "languageSelection",
        localStorage.getItem("i18nextLng") as any
      );

      // UserData.append("userId", values.userId);
      // UserData.append("tempPwd", values.tempPwd);
      // UserData.append("password", values.newPwd);
      // UserData.append("captcha", values.userCaptchaInput);
      // UserData.append(
      //   "languageSelection",
      //   localStorage.getItem("i18nextLng") as any
      // );

      setSubmittingEmailLoader(true);

      Axios.post(
        "/api/resetPassword",
        UserData
        // {
        //   headers: {
        //     Authorization: "Bearer " + sessionStorage.getItem("token"),
        //   },
        // }
      )
        .then((res) => {
          setSubmittingEmailLoader(false);
          //check PENDING SUCCESS ERROR
          if (res.data.status === "SUCCESS") {
            //Add status statusMessage
            //TO THE MESSAGE DISPLAYED AFTER SUBMIT.
            setStatus(2);
          } else if (res.data.status === "WARNING") {
            setStatus(4);
          } else {
            setStatus(3);
          }

          setShowMsg(true);
          setDataUpdateStatus(res.data.statusMessage);
        })
        .catch((err) => {
          setSubmittingEmailLoader(false);

          setDataUpdateStatus(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setStatus(3);
          setShowMsg(true);
        });
    } else {
      setLoadingCaptcha(true);
      fetchCAPTCHA();

      actions.resetForm();
      setCaptchaErr(true);
    }
  };

  const validate = (values: any) => {
    interface ErrorsType {}

    let errors: ErrorsType = {};

    if (values.userCaptchaInput) {
      setCaptchaErr(false);
    }

    return errors;
  };

  if (showMsg) {
    return (
      <div>
        <Paper elevation={3} className="forgot-password-paper">
          {submitStatus(status, dataUpdateStatus)}

          <HorizontalLine></HorizontalLine>

          <LogInButtonWrapper>
            {" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push("/");
              }}
            >
              {t("Signin.1")}
            </Button>
          </LogInButtonWrapper>
        </Paper>
      </div>
    );
  } else {
    return (
      <div style={{ marginBottom: "100px" }}>
        <Paper elevation={3} className="forgot-password-paper">
          <h1>{t("Translation.34")}</h1>
          {/* <h4>Please enter your email address for a reset</h4> */}
          <div style={{ height: "30px" }}></div>
          <GetNewPasswordInput>
            <Formik
              initialValues={{
                userId: "",
                tempPwd: "",
                newPwd: "",
                reenterNewPwd: "",
                userCaptchaInput: "",
              }}
              enableReinitialize={true}
              onSubmit={handleReset}
              validationSchema={() =>
                PasswordSchema(t("Translation.59"), t("Errors.24"))
              }
              validate={validate}
            >
              {({
                values,
                isSubmitting,
                handleChange,
                handleBlur,
                errors,
                touched,
              }) => {
                return (
                  <Form style={{ width: "100%" }}>
                    {/* {captchaErr && values.userCaptchaInput !== captchaValue && (
                      <CaptchaErrorContainer>
                        <ErrorMsg>{t("Translation.24")}</ErrorMsg>
                      </CaptchaErrorContainer>
                    )} */}

                    <InputWithInfoIcon
                      id="userid"
                      name="userId"
                      value={values.userId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t("Translation.35")}
                      titleTxt={t("Translation.36")}
                      tooltipTxt={t("Translation.37")}
                      autoComplete="new-password"
                    />

                    <div style={{ height: "40px" }}></div>

                    <InputWithInfoIcon
                      id="reset-temporary-password"
                      name="tempPwd"
                      value={values.tempPwd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t("Translation.38")}
                      titleTxt={t("Translation.39")}
                      tooltipTxt={t("Translation.40")}
                      type="password"
                      autoComplete="new-password"
                    />
                    <HintContainer>
                      <HintText>{t("Translation.40")}</HintText>
                    </HintContainer>

                    <div style={{ height: "40px" }}></div>

                    <InputWithInfoIcon
                      id="reset-enter-new-password"
                      name="newPwd"
                      value={values.newPwd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t("Translation.41")}
                      titleTxt={t("Translation.42")}
                      tooltipTxt={t("Translation.43")}
                      type="password"
                      error={errors.newPwd && touched.newPwd}
                      autoComplete="new-password"
                    />

                    {errors.newPwd && touched.newPwd && (
                      <div className="error-container">
                        <p>{t("Errors.14")}</p>
                        <ul style={{ listStylePosition: "inside" }}>
                          <li>{t("Errors.15")}</li>
                          <li>{t("Errors.16")}</li>
                          <li>{t("Errors.17")}</li>
                          <li>{t("Errors.18")}</li>
                          <li> {t("Errors.19")}</li>
                          <li> {t("Errors.20")}</li>
                          <li>{t("Errors.21")}</li>
                        </ul>
                      </div>
                    )}

                    {/* <ErrorMessage name="newPwd">
                      {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                    </ErrorMessage> */}

                    <div style={{ height: "20px" }}></div>

                    <InputWithInfoIcon
                      id="reset-reenter-new-password"
                      name="reenterNewPwd"
                      value={values.reenterNewPwd}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t("Translation.44")}
                      titleTxt={t("Translation.45")}
                      tooltipTxt={t("Translation.46")}
                      type="password"
                      error={errors.reenterNewPwd && touched.reenterNewPwd}
                      autoComplete="new-password"
                    />

                    <ErrorMessage name="reenterNewPwd">
                      {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                    </ErrorMessage>

                    <div style={{ height: "40px" }}></div>

                    <div style={{ width: "100%" }}>
                      {fetchDataError && !loadingCaptcha && (
                        <Alert severity="error">{fetchDataErrorMsg}</Alert>
                      )}
                    </div>

                    {loadingCaptcha ? (
                      <Spinner />
                    ) : (
                      <CAPTCHAContainer>
                        <img
                          src={`data:image/jpeg;base64,${imgCode}`}
                          alt="CAPTCHA-code"
                        />
                        <TextField
                          id="CAPTCHA-input"
                          name="userCaptchaInput"
                          label={t("Translation.30")}
                          value={values.userCaptchaInput}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // titleTxt={"todo"}
                          // tooltipTxt={"todo"}
                          variant="outlined"
                          size="small"
                          error={
                            captchaErr &&
                            values.userCaptchaInput !== captchaValue
                          }
                          helperText={
                            captchaErr &&
                            values.userCaptchaInput !== captchaValue
                              ? t("Errors.28")
                              : ""
                          }
                          style={{ marginTop: "10px", width: "100%" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.userCaptchaInput === captchaValue &&
                                !fetchDataError ? (
                                  <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                  <></>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </CAPTCHAContainer>
                    )}

                    <div style={{ marginBottom: "70px" }}></div>

                    <ButtonsContainer>
                      <Button
                        variant="outlined"
                        color="primary"
                        href="/"
                        title={t("Translation.29")}
                      >
                        {t("Signin.1")}
                      </Button>

                      <SubmitContainer>
                        {submittingEmailLoader && <Spinner />}
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={
                            !values.userId ||
                            !values.tempPwd ||
                            !values.newPwd ||
                            !values.reenterNewPwd ||
                            !values.userCaptchaInput
                          }
                          //   onClick={handleGetNewPwd}
                          title={t("Translation.47")}
                        >
                          {t("Translation.20")}
                        </Button>
                      </SubmitContainer>
                    </ButtonsContainer>
                  </Form>
                );
              }}
            </Formik>
          </GetNewPasswordInput>
        </Paper>
      </div>
    );
  }
};

export default PasswordReset;
