import React from "react";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

interface Props {
  label?: any;
  titleTxt: any;
  value: any;
  onChange: any;
  error?: any;
  tooltipTxt: any;
  inputType: any;
  onBlur: any;
  name: any;
  autoComplete?: string;
  disabled?: boolean;
  maxLength?: any;
  helperText?: any;
  onSelect?: any;
  id: string;
}

const Field: React.FC<Props> = ({
  label,
  titleTxt,
  value,
  onChange,
  error,
  tooltipTxt,
  inputType = "text",
  onBlur,
  name,
  autoComplete = "off",
  disabled = false,
  maxLength,
  helperText,
  onSelect = null,
  id,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const renderTooltip = (props: any) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      onMouseLeave={() => setShowTooltip(false)}
      onMouseEnter={() => setShowTooltip(true)}
    >
      {tooltipTxt}
    </Tooltip>
  );

  return (
    <div className="input-infoicon-wrapper">
      <TextField
        id={id}
        autoComplete={autoComplete}
        label={label}
        inputProps={{ maxLength: maxLength }}
        variant="outlined"
        size="small"
        helperText={helperText}
        name={name}
        onSelect={onSelect}
        className="input-field"
        value={value}
        title={titleTxt}
        onChange={onChange}
        error={error}
        type={inputType}
        disabled={disabled}
        // helperText={lastNameErrorMessage}
        onBlur={onBlur}
        // onMouseOut={blurAction}
      />
      <div
        className="tooltip-icon-info"
        // style={{ marginBottom: "0" }}
      >
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
          show={showTooltip}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            // data-tip={tooltipTxt}
          />
        </OverlayTrigger>
        {/* <FontAwesomeIcon
          icon={faInfoCircle}
          //   style={{ marginLeft: "10px" }}
          data-tip={tooltipTxt}
        />

        <ReactTooltip
          className="extraClass"
          delayHide={500}
          effect="solid"
          multiline
          place={"top"}
          html={true}
        /> */}
      </div>
    </div>
  );
};

export default Field;
