import React, { useEffect } from "react";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { countries } from "../../../utils/Countries";
import { TooltipContainer } from "../../../utils/Utils";
import Field from "./UserInputField/Field";
import FormFooter from "../../Registration/FormFooter/FormFooter";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";

interface Props {
  setPage: any;
  handleNext: any;
  role: any;
  handleBack: any;
  values: any;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  autoFillDisable: any;
  setAutoFillDisable: any;
}

const UserInput2: React.FC<Props> = ({
  setPage,
  handleNext,
  role,
  handleBack,
  autoFillDisable,
  setAutoFillDisable,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue
}) => {

  const { t } = useTranslation();

  const filterOptions = createFilterOptions({
    limit: 100,
  });

  const continueButtonHandler = () => {
    if (role === "poweruser" && !(role === "webtrader")) {
      setPage(5);
    } else {
      handleNext();
      setPage(4);
    }
  };

  const goBackHandler = () => {
    handleBack();
    setPage(2);
  };

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [loadingCompaniesError, setLoadingCompaniesError] =
    React.useState(false);
  const [loadingCompaniesErrorMsg, setLoadingCompaniesErrorMsg] =
    React.useState("");

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setLoadingCompaniesError(false);
    setLoading(true);
    axios
      .get(`/api/getcompanies`)
      .then((res) => {
        
        if (isMounted.current) {
          setOptions(res.data);

          setLoading(false);
        }
        setLoadingCompaniesError(false);
      })
      .catch((err) => {
        if (isMounted.current) {
          setLoading(false);

          setLoadingCompaniesError(true);
          setLoadingCompaniesErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
        }
      });

    if (values.company.companyId) {
      setAutoFillDisable(true);    
    }
  }, []);
  return (
    <div className="paper-body">
      {loadingCompaniesError && (
        <Alert severity="error">{loadingCompaniesErrorMsg}</Alert>
      )}
      <Autocomplete
        filterOptions={filterOptions}
        freeSolo
        open={open}
        value={values.company}
        onOpen={() => {
          setOpen(true);
        }}

        onInputChange={(event, newValue) => { 

          const found = options.find(
            (option: any) => option.companyName === newValue
          );
          
          if (found) {
            setAutoFillDisable(true);
            setFieldValue("ifExist", true);           
          } else {
            setAutoFillDisable(false);
            setFieldValue("ifExist", true);   
          }

        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setFieldValue("city", newValue.city);
            setFieldValue("country", { name: newValue.country });
            setFieldValue("state", newValue.state);
            setFieldValue("company", {
              companyName: newValue.companyName,
              companyId: newValue.companyId,
            });
            setAutoFillDisable(true);
          }         
        }}
        onClose={(e) => {
          setOpen(false);

          let target = e.target as any;

          if (target.defaultValue) {
            setFieldValue("company", { companyName: target.defaultValue });
            setFieldValue("ifExist", false);
          }

          options.forEach((option: any) => {
            if (option.companyName === target.defaultValue) {
              setFieldValue("company", {
                companyName: option.companyName,
                companyId: option.companyId,
              });
              
              setFieldValue("city", option.city);
              setFieldValue("country", { name: option.country });
              setFieldValue("state", option.state);
              setAutoFillDisable(true);          
            }
          });
          
        }}
        getOptionSelected={(option, value) => {
          return option.companyName === value.companyName;
        }}
        getOptionLabel={(option) => {
          return option.companyName;
        }}
        options={options
          .filter((el: any) => {
            if (el.companyName) {
              return true;
            }
            return false;
          })
          .sort((a: any, b: any) =>
            a.companyName
              .toLowerCase()
              .localeCompare(b.companyName.toLowerCase())
          )}
        loading={loading}
        renderInput={(params) => (
          <div className="input-infoicon-wrapper">
            <TextField
              {...params}
              label={t("Registration.29")}
              title={t("Tooltips.44")}
              size="small"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
                maxLength: 90,
              }}
              InputProps={{
                ...params.InputProps,

                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
            <div className="tooltip-icon-info">
              <TooltipContainer txt={t("Tooltips.45")} />
            </div>
          </div>
        )}
      />

      <div style={{ height: "25px" }}></div>

      <Autocomplete
        disablePortal={true}
        autoComplete={true}
        options={countries}
        getOptionLabel={(option) => {
          return option.name;
        }}
        getOptionSelected={(option, value) => {
          return option.name === value.name;
        }}
        // freeSolo
        value={values.country}
        disabled={autoFillDisable}
        // onInputChange={(event, newValue) => {
        //   setFieldValue("country", { name: newValue });
        // }}
        onChange={(event, newValue) => {
          setFieldValue("country", newValue);
        }}
        renderInput={(params) => (
          <div className="input-infoicon-wrapper">
            <TextField
              {...params}
              label={t("Registration.28")}
              title={t("Tooltips.50")}
              variant="outlined"
              size="small"
              disabled={autoFillDisable}
              className="input-field"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
            <div className="tooltip-icon-info">
              <TooltipContainer txt={t("Tooltips.51")} />
            </div>
          </div>
        )}
      />

      <div style={{ height: "25px" }}></div>

      <div className="city-state-province-input">
        <div className="city-input">
          <Field
            value={values.city}
            name="city"
            id="city-reg"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.city && touched.city}
            label={t("Registration.27")}
            titleTxt={t("Tooltips.46")}
            tooltipTxt={t("Tooltips.47")}
            inputType="text"
            disabled={autoFillDisable}
            maxLength={50}
          />
        </div>

        <div className="state-province-input">
          <Field
            value={values.state}
            name="state"
            id="state-reg"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.state && touched.state}
            label={t("Registration.30")}
            titleTxt={t("Tooltips.48")}
            tooltipTxt={t("Tooltips.49")}
            inputType="text"
            disabled={autoFillDisable}
            maxLength={50}
          />
        </div>
      </div>

      <div style={{ height: "45px" }}></div>

      <FormFooter
        continueHandler={continueButtonHandler}
        previousOption={true}
        previousHandler={goBackHandler}
        disbale={
          values.country === null ||
          values.country === undefined ||
          (values.country && !values.country.name) ||
          !values.city ||
          !values.state ||
          !values.company ||
          !values.company.companyName
        }
      />
    </div>
  );
};

export default UserInput2;
