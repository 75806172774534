import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Base64 } from "js-base64";
import Form from "react-bootstrap/Form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Spinner from "../../../Spinner/Spinner";
import { InputWithInfoIcon } from "../../../../utils/Input";
import { useTranslation } from "react-i18next";
import { Formik, Form as FormikForm, ErrorMessage } from "formik";
import { PasswordSchema } from "../../../../utils/Verification";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { detectIE } from "../../../../utils/checkIE";

const CertificateButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 50px 100px;
`;

const UploadErrorMessage = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #c60000;
`;

const ErrorMsg = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #c60000;
`;

const Certificate = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [newCertificateContent, setNewCertificateContent] = useState(0);
  const [fetchingDataLoader, setFetchingDataLoader] = useState(true);
  const [fetchingDataErr, setFetchingDataErr] = useState(false);
  const [fetchingDataErrTxt, setFetchingDataErrTxt] = useState("");

  const [certificateFile, setCertificateFile] = useState(null);
  const [certificateFileName, setCertificateFileName] = useState(
    localStorage.getItem("i18nextLng") === "fr"
      ? "Joindre un fichier…"
      : "Attach a file..."
  );
  const [applicationStatus, setApplicationStatus] = useState({
    certified: false,
  });
  const [certifyError, setCertifyError] = useState(false);

  const [uploadCertificateLoader, setUploadCertificateLoader] = useState(false);

  const certifyHandler = (e: any) => {
    setApplicationStatus({
      ...applicationStatus,
      [e.target.name]: e.target.checked,
    });
  };

  const { certified } = applicationStatus;

  const [certificateFileError, setCertificateFileError] = useState(false);
  const [certificateUploadExtError, setCertificateUploadExtError] =
    useState(false);

  const [submittingUploadOptionErr, setSubmittingUploadOptionErr] =
    useState(false);
  const [submittingUploadOptionErrMsg, setSubmittingUploadOptionErrMsg] =
    useState("");

  const [submittingPwdOptionErr, setSubmittingPwdOptionErr] = useState(false);
  const [submittingPwdOptionErrMsg, setSubmittingPwdOptionErrMsg] =
    useState("");

  // const fetchData = useCallback(() => {
  //   axios
  //     .get(
  //       `api/getUserCert?userid=${sessionStorage.getItem(
  //         "userID"
  //       )}&lang=${localStorage.getItem("i18nextLng")}`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + sessionStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       const data = res.data;

  //       if (data.submitted) {
  //         setNewCertificateContent(1);
  //       }
  //       setFetchingDataLoader(false);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //       setFetchingDataLoader(false);
  //     });
  // }, []);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [middleInitial, setMiddleInitial] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [issuer, setIssuer] = useState("");
  const [emailID, setEmailID] = useState("");
  // const [certificatePassword, setCertificatePassword] = useState("");
  const [name, setName] = useState("");

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  //TODO
  useEffect(() => {
    setFetchingDataLoader(true);
    axios
      .get(
        `/api/getAccountCertData?userid=${sessionStorage.getItem(
          "userID"
        )}&lang=${localStorage.getItem("i18nextLng")}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (isMounted.current) {
          setFetchingDataLoader(false);

          const {
            emailID,
            firstName,
            lastName,
            middleInitial,
            phone,
            country,
            suffix,
            city,
            state,
            company,
            issuer,
            name,
          } = res.data;

          setCountry(country);
          setCity(city);
          setState(state);
          setFirstName(firstName);
          setLastName(lastName);
          setSuffix(suffix);
          setMiddleInitial(middleInitial);
          setCompany(company);
          setPhone(phone);
          setIssuer(issuer);
          setEmailID(emailID);
          // setCertificatePassword()
          setName(name);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setFetchingDataLoader(false);

          setFetchingDataErr(true);
          setFetchingDataErrTxt(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
        }
      });
  }, []);

  const handleUploadClose = () => {
    setApplicationStatus({
      certified: false,
    });
    setCertificateUploadExtError(false);
    setCertificateFile(null);
    setCertificateFileName(
      localStorage.getItem("i18nextLng") === "fr"
        ? "Joindre un fichier…"
        : "Attach a file..."
    );
    setOpenUpload(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const certificateUploadHandler = (e: any) => {
    // setCertificateFile(e.target.files[0]);
    // setCertificateFileName(e.target.files[0].name);

    // setCertificateFileError(false);

    let name = e.target.files[0].name;

    let ext = name?.slice(name.length - 4, name.length);

    if (ext !== ".cer" && ext !== ".p7b" && ext !== ".p7c") {
      // setCertificateFile(e.target.files[0]);
      // setCertificateFileName(e.target.files[0].name);

      setCertificateUploadExtError(true);
      setCertificateFileError(false);
      setCertificateFile(null);
      setCertificateFileName(
        localStorage.getItem("i18nextLng") === "fr"
          ? "Joindre un fichier…"
          : "Attach a file..."
      );
    } else {
      setCertificateFile(e.target.files[0]);
      setCertificateFileName(e.target.files[0].name);

      setCertificateUploadExtError(false);
      setCertificateFileError(false);
    }

    if (!detectIE()) {
      e.target.value = "";
    }
  };

  const [uploadCertificateSubmitDisable, setUploadCertificateSubmitDisable] =
    useState(false);

  const submitDataAfterUpload = () => {
    if (certificateFile === null) {
      setCertificateFileError(true);
    } else if (certified === false) {
      setCertifyError(true);
    } else {
      setUploadCertificateSubmitDisable(true);

      let UserData = new FormData();

      UserData.append("certificateFile", certificateFile as any);
      UserData.append(
        "languageSelection",
        localStorage.getItem("i18nextLng") as any
      );
      setUploadCertificateLoader(true);
      axios
        .post(
          `/api/uploadNewCert?userid=${sessionStorage.getItem("userID")}`,
          UserData,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (isMounted.current) {
            setUploadCertificateSubmitDisable(false);

            setUploadCertificateLoader(false);

            // const data = res.data;

            //TODO check if it is successfull
            // if (data.message === "Success") {
            //   setExistingLetters(updatedExistingLettersList);
            // }

            // setSubmitFileSpinner(false);

            // setNrlFile(null);
            // setNrlName("Attach a file...");
            setNewCertificateContent(1);
            setOpenUpload(false);
          }
        })
        .catch((err) => {
          if (isMounted.current) {
            setUploadCertificateSubmitDisable(false);
            //TODO it should not be just clg err

            setUploadCertificateLoader(false);

            setSubmittingUploadOptionErr(true);
            setSubmittingUploadOptionErrMsg(
              err.response &&
                err.response.data &&
                err.response.data.statusMessage
                ? err.response.data.statusMessage
                : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
            );
          }
        });
    }
  };

  const submitDataAfterPassword = (values: any, actions: any) => {
    let CertificateData = new FormData();

    function urltoFile(url: any, filename: any, mimeType: any) {
      return fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType });
        });
    }

    CertificateData.append("certificatePassword", values.newPwd);

    CertificateData.append(
      "languageSelection",
      localStorage.getItem("i18nextLng") as any
    );

    let GenerateCertificateData = new FormData();

    GenerateCertificateData.append("country", country);
    GenerateCertificateData.append("city", city);
    GenerateCertificateData.append("state", state);
    GenerateCertificateData.append("firstName", firstName ? firstName : "");
    GenerateCertificateData.append("lastName", lastName ? lastName : "");
    GenerateCertificateData.append("suffix", suffix ? suffix : "");
    GenerateCertificateData.append(
      "middleInitial",
      middleInitial ? middleInitial : ""
    );
    GenerateCertificateData.append("company", company);
    GenerateCertificateData.append("phone", phone ? phone : "");
    GenerateCertificateData.append("issuer", issuer ? issuer : "");
    GenerateCertificateData.append("emailID", emailID);
    GenerateCertificateData.append("certificatePassword", values.newPwd);
    GenerateCertificateData.append("name", name ? name : "");

    axios
      .post(
        `/api/getcertificate?userid=${sessionStorage.getItem("userID")}`,
        GenerateCertificateData
      )
      .then((res) => {
        if (isMounted.current) {
          const data = res.data;

          CertificateData.append("publicCertFileName", data.publicCertFileName);
          CertificateData.append("publicCertificate", data.publicCertificate);

          if (detectIE()) {
            let wn: any = window.navigator;
            wn.msSaveOrOpenBlob(
              new Blob([Base64.toUint8Array(data.privateKeyStore)], {
                type: "text/plain",
              }),
              data.privateKeyFilename
            );
            // setCertLoader(false);
            // if (!certLoader) {
            //   setOpen(false);
            // }
          } else {
            urltoFile(
              `data:text/plain;base64,${data.privateKeyStore}`,
              `${data.privateKeyFilename}`,
              "text/plain"
            ).then(function (file) {
              var link = document.createElement("a");
              // var file = element.artifact;

              link.download = file.name;
              link.href = URL.createObjectURL(file);
              link.click();
            });
          }

          axios
            .post(
              `/api/uploadNewCert?userid=${sessionStorage.getItem("userID")}`,
              CertificateData,
              {
                headers: {
                  Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
              }
            )
            .then((res) => {
              // const data = res.data;
              if (isMounted.current) {
                setNewCertificateContent(1);
                setOpen(false);
              }
            })
            .catch((err) => {
              if (isMounted.current) {
                actions.setSubmitting(false);
                actions.resetForm();

                setSubmittingPwdOptionErr(true);
                setSubmittingPwdOptionErrMsg(
                  err.response &&
                    err.response.data &&
                    err.response.data.statusMessage
                    ? err.response.data.statusMessage
                    : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
                );
              }
            });
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          //TODO

          actions.setSubmitting(false);
          actions.resetForm();

          setSubmittingPwdOptionErr(true);
          setSubmittingPwdOptionErrMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
        }
      });
  };

  if (fetchingDataLoader) {
    return (
      <>
        <Spinner />
      </>
    );
  } else if (fetchingDataErr) {
    return <Alert severity="error">{fetchingDataErrTxt}</Alert>;
  } else {
    return (
      <>
        {newCertificateContent === 0 ? (
          <>
            <div style={{ padding: "50px 50px 0px 50px", textAlign: "center" }}>
              {/* <h5>{t("AddNewCertificate.1")}</h5> */}
              <Typography variant="h5" component="h2">
                {t("AddNewCertificate.1")}
              </Typography>
            </div>
            <br />
            <br />
            <br />
            <CertificateButtonsWrapper>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenUpload(!openUpload)}
                title={t("AddNewCertificate.3")}
              >
                {t("AddNewCertificate.2")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(!open)}
                title={t("AddNewCertificate.5")}
              >
                {t("AddNewCertificate.4")}
              </Button>
            </CertificateButtonsWrapper>{" "}
          </>
        ) : null}

        {newCertificateContent === 1 ? (
          <>
            <br />

            <div style={{ textAlign: "center", padding: "100px" }}>
              {/* <h5>{t("AddNewCertificate.6")}</h5> */}
              <Typography variant="h5" component="h2">
                {t("AddNewCertificate.6")}
              </Typography>
            </div>
          </>
        ) : null}

        <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
          <DialogTitle id="create-certificate-title">
            {t("AddNewCertificate.17")}
          </DialogTitle>

          <Formik
            initialValues={{
              newPwd: "",
              reenterNewPwd: "",
            }}
            onSubmit={submitDataAfterPassword}
            validationSchema={() =>
              PasswordSchema(t("Translation.59"), t("Errors.24"))
            }
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => {
              return (
                <FormikForm>
                  <DialogContent>
                    <DialogContentText className="create-certificate-desc">
                      {t("AddNewCertificate.18")}
                    </DialogContentText>
                    <br />
                    {submittingPwdOptionErr && (
                      <Alert severity="error">
                        {submittingPwdOptionErrMsg}
                      </Alert>
                    )}
                    <br />
                    <div className="certificate-password-field">
                      <label htmlFor="newPwd-certificate">
                        {t("AddNewCertificate.19")}
                      </label>
                      <div className="certificate-password-input-wrapper">
                        <InputWithInfoIcon
                          type="password"
                          name="newPwd"
                          id="newPwd-certificate"
                          value={values.newPwd}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          tooltipTxt={t("AddNewCertificate.21")}
                          titleTxt={t("AddNewCertificate.20")}
                          error={errors.newPwd && touched.newPwd}
                          autoComplete="new-password"
                        />
                        {errors.newPwd && touched.newPwd && (
                          <div className="error-container">
                            <p>{t("Errors.14")}</p>
                            <ul style={{ listStylePosition: "inside" }}>
                              <li>{t("Errors.15")}</li>
                              <li>{t("Errors.16")}</li>
                              <li>{t("Errors.17")}</li>
                              <li>{t("Errors.18")}</li>
                              <li> {t("Errors.19")}</li>
                              <li> {t("Errors.20")}</li>
                              <li>{t("Errors.21")}</li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="certificate-password-field">
                      <label htmlFor="reenterNewPwd-certificate">
                        {t("AddNewCertificate.22")}
                      </label>
                      <div className="certificate-password-input-wrapper">
                        <InputWithInfoIcon
                          type="password"
                          name="reenterNewPwd"
                          id="reenterNewPwd-certificate"
                          value={values.reenterNewPwd}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          tooltipTxt={t("AddNewCertificate.28")}
                          titleTxt={t("AddNewCertificate.23")}
                          error={errors.reenterNewPwd && touched.reenterNewPwd}
                          autoComplete="new-password"
                        />
                        <ErrorMessage name="reenterNewPwd">
                          {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </DialogContent>
                  <br />
                  <div
                    className="certificate-bottom-buttons"
                    style={{ alignItems: "center" }}
                  >
                    <Button
                      onClick={() => setOpen(false)}
                      color="secondary"
                      variant="outlined"
                      title={t("AddNewCertificate.14")}
                    >
                      {t("AddNewCertificate.13")}
                    </Button>

                    {isSubmitting && <Spinner />}

                    <Button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        values.newPwd === "" ||
                        values.reenterNewPwd === "" ||
                        errors.newPwd !== undefined ||
                        errors.reenterNewPwd !== undefined
                      }
                      // onClick={submitDataAfterPassword}
                      color="primary"
                      variant="contained"
                      title={t("AddNewCertificate.16")}
                    >
                      {t("AddNewCertificate.15")}
                    </Button>
                  </div>
                </FormikForm>
              );
            }}
          </Formik>
        </Dialog>

        <Dialog open={openUpload} onClose={handleUploadClose}>
          <DialogTitle id="create-certificate-title">
            {t("AddNewCertificate.7")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="create-certificate-desc">
              {t("AddNewCertificate.8")}
            </DialogContentText>

            {submittingUploadOptionErr && (
              <Alert severity="error">{submittingUploadOptionErrMsg}</Alert>
            )}

            <br />
            <Form style={{ width: "100%" }}>
              <Form.File
                // id="nrl"
                title={t("Translation.71")}
                id="certificate-file-upload"
                label={certificateFileName}
                custom
                accept=".cer, .p7b, .p7c"
                onChange={certificateUploadHandler}
                isInvalid={certificateFileError || certificateUploadExtError}
                data-browse={
                  localStorage.getItem("i18nextLng") === "fr"
                    ? "Explorer"
                    : "Browse"
                }
              />
              {certificateFileError ? (
                <UploadErrorMessage>{t("Translation.59")}</UploadErrorMessage>
              ) : null}

              {certificateUploadExtError && (
                <UploadErrorMessage>
                  {t("AddNewCertificate.29")}
                </UploadErrorMessage>
              )}
            </Form>
            <br />

            {certifyError ? (
              <UploadErrorMessage>{t("Translation.59")}</UploadErrorMessage>
            ) : null}

            <FormControlLabel
              control={
                <Checkbox
                  checked={certified}
                  onChange={certifyHandler}
                  // checked={webtrader}
                  // onChange={handleCheckboxRole}
                  // name="webtrader"
                  name="certified"
                  color="primary"
                  title={t("AddNewCertificate.12")}
                />
              }
              label={t("AddNewCertificate.11")}
            />
          </DialogContent>
          <br />
          <div className="certificate-bottom-buttons">
            <Button
              onClick={() => {
                setOpenUpload(false);
                setApplicationStatus({
                  certified: false,
                });
                setCertificateUploadExtError(false);
                setCertificateFile(null);
                setCertificateFileName(
                  localStorage.getItem("i18nextLng") === "fr"
                    ? "Joindre un fichier…"
                    : "Attach a file..."
                );
              }}
              color="secondary"
              variant="outlined"
              title={t("AddNewCertificate.25")}
            >
              {t("AddNewCertificate.24")}
            </Button>

            {uploadCertificateLoader && <Spinner />}

            <Button
              onClick={submitDataAfterUpload}
              color="primary"
              variant="contained"
              title={t("AddNewCertificate.27")}
              disabled={
                !certified ||
                certificateFile === null ||
                uploadCertificateSubmitDisable
              }
            >
              {t("AddNewCertificate.26")}
            </Button>
          </div>
        </Dialog>
      </>
    );
  }
};

export default Certificate;
