import React, { useState, useEffect, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Dialog from "@material-ui/core/Dialog";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import Axios from "axios";
import Spinner from "../Spinner/Spinner";
import styled from "styled-components";

import InputAdornment from "@material-ui/core/InputAdornment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { DialogTitle as RegularDialogTitle } from "@material-ui/core";

const CAPTCHAContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  align-items: center;
  padding-left: 75px;
  padding-right: 75px;
`;

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface Props {
  setAuthorizedStatus: any;
  t: any;
  french: any;
  // handleLanguage={handleLanguage}
  i18n: any;
  setFrenchLang: any;
  setEnglish: any;
  setUserAuthenticated: any;
  setUserClickedLogOut: any;
  // setUserName: any;
  // setUserRole: any;
  handleClickOpenPWDExpire: any;
  setPwdExpireMsg: any;
}

const Login: React.FC<Props> = ({
  t,
  french,
  setEnglish,
  setFrenchLang,
  setUserAuthenticated,
  setAuthorizedStatus,
  setUserClickedLogOut,
  // setUserName,
  // setUserRole,
  handleClickOpenPWDExpire,
  setPwdExpireMsg,
}) => {
  const [userID, setUserID] = useState("");
  const [userIDError, setUserIDError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [rulesCheckbox, setRulesCheckbox] = useState(false);
  const [rulesCheckboxError, setRulesCheckboxError] = useState(false);

  const [openRules, setOpenRules] = useState(false);

  const [openLanguageSelection, setOpenLanguageSelection] = useState(false);

  const [openPendingStatus, setOpenPendingStatus] = useState(false);

  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState<boolean | string>(
    false
  );

  const [statusMessage, setStatusMessage] = useState("");

  const [imgCode, setImgCode] = useState("");
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [userCaptchaInputErr, setUserCaptchaInputErr] = useState(false);

  const [loginSubmitLoader, setLoginSubmitLoader] = useState(false);

  const [captchaValue, setCaptchaValue] = useState("");

  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState("");
  const [fetchDataError, setFetchDataError] = useState(false);

  const handleOpenPendingStatus = () => {
    setOpenPendingStatus(true);
  };

  const handleClosePendingStatus = () => {
    setOpenPendingStatus(false);
  };
  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    setUserAuthenticated(false);
    setAuthorizedStatus(false);

    // localStorage.setItem()
    setUserClickedLogOut(false);
  }, [setAuthorizedStatus, setUserAuthenticated, setUserClickedLogOut]);

  let history = useHistory();

  const handleOpenLanguageSelection = () => {
    setOpenLanguageSelection(true);
  };

  useEffect(() => {
    handleOpenLanguageSelection();
  }, []);

  const handleCloseLanguageSelection = () => {
    setOpenLanguageSelection(false);
  };

  const viewRulesHandler = (e: any) => {
    e.preventDefault();
    setOpenRules(true);
  };

  const handleClose = () => {
    setOpenRules(false);
  };

  const handleIAgreeButton = () => {
    setOpenRules(false);
    setRulesCheckbox(true);
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (password.trim() === "") {
      setPasswordError(true);
      setPassword("");
    } else {
      setPasswordError(false);
    }

    if (userID.trim() === "") {
      setUserIDError(true);
      setUserID("");
    } else {
      setUserIDError(false);
    }

    if (rulesCheckbox) {
      setRulesCheckboxError(false);
    } else {
      setRulesCheckboxError(true);
    }

    if (userCaptchaInput === "") {
      setUserCaptchaInputErr(true);
    } else {
      setUserCaptchaInputErr(false);
    }

    if (userCaptchaInput !== captchaValue) {
      setUserCaptchaInputErr(true);
      setLoadingCaptcha(true);
      setUserCaptchaInput("");
      fetchCAPTCHA();
    }

    if (
      password.trim() !== "" &&
      userID.trim() !== "" &&
      rulesCheckbox &&
      userCaptchaInput !== "" &&
      userCaptchaInput === captchaValue
    ) {
      let LoginData = new FormData();

      LoginData.append("login", userID);
      LoginData.append("captcha", userCaptchaInput);
      LoginData.append("password", password);
      LoginData.append(
        "languageSelection",
        localStorage.getItem("i18nextLng") as any
      );

      setLoginSubmitLoader(true);

      axios
        .post("/api/login", LoginData)
        .then((res) => {
          setLoginSubmitLoader(false);
          sessionStorage.setItem("token", res.headers.authorization);

          if (res !== null) {
            if (res.data.status === "WARNING") {
              setStatusMessage(res.data.statusMessage);
              handleOpenPendingStatus();
            } else if (res.data.status === "tempPwd") {
              history.push(`/reset-pwd`);
            } else if (res.data.status === null) {
              setLoginError(true);
              setLoginErrorMessage(res.data.statusMessage);
            } else {
              if (res.data.statusMessage) {
                handleClickOpenPWDExpire();
                setPwdExpireMsg(res.data.statusMessage);
              }
              setAuthorizedStatus(true);
              sessionStorage.setItem("user", res.data.id);

              sessionStorage.setItem("userID", res.data.userId);
              sessionStorage.setItem("status", res.data.status);
              sessionStorage.setItem("statusMessage", res.data.statusMessage);
              setUserAuthenticated(true);
              // setUserName(res.data.name);
              sessionStorage.setItem("userName", JSON.stringify(res.data.name));
              // history.push(`/menu?role=${res.data.id}`);

              if (res.data.id === "1") {
                // setUserRole("WebTrader");
                sessionStorage.setItem("userRole", JSON.stringify("WebTrader"));
                history.push(`/wt`);
              } else if (res.data.id === "2") {
                // setUserRole("Full Power");
                sessionStorage.setItem(
                  "userRole",
                  JSON.stringify("Full Power")
                );
                history.push(`/fp`);
              } else if (res.data.id === "3") {
                // setUserRole(t("Registration.17"));
                sessionStorage.setItem(
                  "userRole",
                  JSON.stringify(t("Registration.17"))
                );
                history.push(`/po`);
              }
            }
          } else {
            //TODO add handling if response is null
            setLoginError(true);
            setLoginErrorMessage(
              "Error occurred while logging into system. Please try again.If you continue to get this problem please contact ESG Help Desk."
            );
          }
        })
        .catch((err) => {
          setLoginSubmitLoader(false);

          setLoginError(true);
          setLoginErrorMessage(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Error occurred while logging into system. Please try again.If you continue to get this problem please contact ESG Help Desk."
          );
          //TODO Handle errors and show message to the USER
        });
    }
  };

  const fetchCAPTCHA = useCallback(() => {
    Axios.get(`/api/captcha`)
      .then((res) => {
        setLoadingCaptcha(false);
        setImgCode(res.data.captchaImage);

        setCaptchaValue(res.data.value);

        setFetchDataError(false);
      })
      .catch((err) => {
        setLoadingCaptcha(false);

        setFetchDataErrorMsg(
          err.response && err.response.data && err.response.data.statusMessage
            ? err.response.data.statusMessage
            : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
        );

        setFetchDataError(true);
      });
  }, []);

  React.useEffect(() => {
    fetchCAPTCHA();
  }, [fetchCAPTCHA]);

  return (
    <div className="welcome-wrapper ">
      {!openLanguageSelection ? (
        <Paper elevation={3} className="login-screen">
          <div className="login-avatar-wrapper">
            <AccountCircleIcon
              style={{ height: "7rem", color: "grey" }}
              className="login-avatar"
            />
          </div>
          <Typography
            variant="h4"
            component="h1"
            style={{
              textAlign: "center",
              marginBottom: "2rem",
              marginTop: "1rem",
            }}
          >
            {t("Signin.1")}
          </Typography>

          <form
            // autoComplete="off"
            onSubmit={submitHandler}
            className="form-of-inputs"
          >
            {loginError ? (
              <Alert severity="error">{loginErrorMessage}</Alert>
            ) : null}
            <br />
            <div className="user-id-input-wrapper">
              <FormControl style={{ width: "100%" }}>
                <TextField
                  id="username-input"
                  autoComplete="username"
                  label={t("Signin.9")}
                  variant="outlined"
                  value={userID}
                  onChange={(e) => setUserID(e.target.value)}
                  size="small"
                  // className="user-id-input"
                  error={userIDError}
                  title={t("Tooltips.10")}
                  helperText={userIDError ? t("Translation.59") : ""}
                />
              </FormControl>
            </div>
            <Link
              href="/forgot-userid"
              className="forgot-user-id"
              title={t("Tooltips.4")}
            >
              {t("Signin.2")}
            </Link>

            <div className="password-input-wrapper">
              <FormControl style={{ width: "100%" }}>
                <TextField
                  id="password-input"
                  autoComplete="current-password"
                  label={t("Signin.8")}
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="small"
                  // className="password-input"
                  error={passwordError}
                  title={t("Tooltips.9")}
                  helperText={passwordError ? t("Translation.59") : ""}
                />
              </FormControl>
            </div>
            <Link
              href="/forgot-password"
              className="forgot-password-link"
              title={t("Tooltips.5")}
            >
              {t("Signin.3")}
            </Link>

            <div style={{ width: "100%" }}>
              {fetchDataError && !loadingCaptcha && (
                <Alert severity="error">{fetchDataErrorMsg}</Alert>
              )}
            </div>

            {loadingCaptcha ? (
              <Spinner />
            ) : (
              <CAPTCHAContainer>
                <img
                  src={`data:image/jpeg;base64,${imgCode}`}
                  alt={`CAPTCHA code is ${captchaValue}`}
                />
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    id="CAPTCHA-input"
                    label={t("Translation.30")}
                    value={userCaptchaInput}
                    onChange={(e) => setUserCaptchaInput(e.target.value)}
                    variant="outlined"
                    size="small"
                    style={{ marginTop: "10px", width: "100%" }}
                    error={
                      userCaptchaInputErr && userCaptchaInput !== captchaValue
                    }
                    helperText={
                      userCaptchaInputErr && userCaptchaInput !== captchaValue
                        ? t("Errors.28")
                        : ""
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {userCaptchaInput === captchaValue &&
                          !fetchDataError ? (
                            <CheckCircleIcon style={{ color: "green" }} />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </CAPTCHAContainer>
            )}
            <div style={{ height: "30px" }}></div>
            <FormControl error={rulesCheckboxError} className="agree-terms">
              {rulesCheckboxError ? (
                <FormLabel component="legend">{t("Translation.60")}</FormLabel>
              ) : (
                ""
              )}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rulesCheckbox}
                      onChange={() => setRulesCheckbox(!rulesCheckbox)}
                      name="checkedRules"
                      color="primary"
                    />
                  }
                  label={
                    <span className="checkbox-message">
                      {t("Signin.4")}

                      <Link
                        href="#"
                        onClick={viewRulesHandler}
                        title={t("Tooltips.8")}
                      >
                        {t("Signin.5")}
                      </Link>
                    </span>
                  }
                />
              </FormGroup>
            </FormControl>

            <Dialog onClose={handleClose} open={openRules}>
              <DialogTitle onClose={handleClose} id="customized-dialog-title">
                {t("Translation.57")}
              </DialogTitle>
              <DialogContent dividers>
                <ul style={{ paddingLeft: "16px" }} className="rules-list">
                  <li>{t("Translation.48")}</li>
                  <li>{t("Translation.49")}</li>
                  <li>{t("Translation.50")}</li>
                  <li>{t("Translation.51")}</li>
                  <li>{t("Translation.52")}
					<ul style={{ paddingLeft: "16px" }}>
	                  	<li>{t("Translation.53")}</li>
	                  	<li>{t("Translation.54")}</li>
					</ul>
				  </li>
                  
                </ul>
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={handleIAgreeButton}
                >
                  {t("Translation.58")}
                </Button>
              </DialogActions>
            </Dialog>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="submit-button"
              title={t("Tooltips.7")}
              disabled={loginSubmitLoader}
            >
              {t("Signin.6")}
            </Button>
            <div className="account-registration-restoration">
              <Link
                href={`/register`}
                // href={`/register${french ? "" : "?lang=fr"}`}
                title={t("Tooltips.6")}
                style={{ fontSize: "1.2rem" }}
              >
                {t("Signin.7")}
              </Link>
            </div>
          </form>
        </Paper>
      ) : null}

      <Dialog
        open={openLanguageSelection}
        onClose={handleCloseLanguageSelection}
      >
        <RegularDialogTitle>
          {"Please select a language / Sélectionnez une langue"}
        </RegularDialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              color="primary"
              title="Click on this button to select the English language"
              onClick={() => {
                setEnglish();
                handleCloseLanguageSelection();
              }}
              style={{
                width: "13rem",
                display: "block",
                marginTop: "1rem",
              }}
            >
              English
            </Button>
            <Button
              variant="contained"
              color="primary"
              title="Cliquez sur ce bouton pour sélectionner le français"
              onClick={() => {
                setFrenchLang();
                handleCloseLanguageSelection();
              }}
              style={{
                width: "13rem",
                display: "block",
                marginTop: "1rem",
              }}
            >
              Français
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openPendingStatus} onClose={handleClosePendingStatus}>
        <Alert severity="warning">{statusMessage}</Alert>
        <DialogContent>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClosePendingStatus}
          >
            Back
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Login;
