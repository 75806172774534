import React from "react";

const useSessionStorageParse = (defaultValue: any, key: any) => {
  const [value, setValue] = React.useState(() => {
    const lsValue = window.sessionStorage.getItem(key);

    return lsValue !== null ? JSON.parse(lsValue) : defaultValue;
  });

  React.useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorageParse;
