import React, { useState, useRef, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { useHistory } from "react-router-dom";

interface Props {
  setUserClickedLogOut: (params: any) => void;
  setLoggedOutMessage: (params: any) => void;
  setAuthorizedStatus: (params: any) => void;
  setUserAuthenticated: (params: any) => void;
}

const IdleTimerContainer: React.FC<Props> = ({
  setUserClickedLogOut,
  setLoggedOutMessage,
  setAuthorizedStatus,
  setUserAuthenticated,
}) => {
  const [open, setOpen] = React.useState(false);

  const [logOutCounter, setLogOutCounter] = useState(11);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
    setLogOutCounter(10);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (logOutCounter < 11) {
        setLogOutCounter(logOutCounter - 1);
      }

      if (logOutCounter === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef<any>(null);
  let history = useHistory();
  const handleClose = () => {
    clearTimeout(sessionTimeoutRef.current);
    setOpen(false);

    let LogOutData = new FormData();

    LogOutData.append("logout", "true");

    axios
      .post(
        `/api/logout?userid=${sessionStorage.getItem("userID")}`,
        LogOutData
      )
      .then((res) => {
        setLoggedOutMessage(res.data.statusMessage);
        setUserClickedLogOut(res.data.status);

        localStorage.clear();

        setAuthorizedStatus(false);
      })
      .catch((err) => {
        localStorage.clear();

        setAuthorizedStatus(false);

        setUserClickedLogOut(true);
        history.push(`/`);
       /*setLoggedOutMessage(
          "Your session has expired."
        );*/
       
      });

    setUserAuthenticated(false);
  };

  const handleCloseWithoutLogout = () => {
    clearTimeout(sessionTimeoutRef.current);
    setOpen(false);
  };

  const onIdle = (e: any) => {
    handleClickOpen();
    sessionTimeoutRef.current = setTimeout(handleClose, 10000);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleCloseWithoutLogout}>
        <DialogTitle> {t("Translation.17")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("Translation.23")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            title={t("Translation.3")}
          >
            {t("Translation.18")}
          </Button>
          <Button
            onClick={handleCloseWithoutLogout}
            color="primary"
            variant="contained"
            title={t("Translation.4")}
          >
            {t("Translation.19")} ({logOutCounter})
          </Button>
        </DialogActions>
      </Dialog>
      <IdleTimer
        ref={idleTimerRef as any}
        timeout={6 * 10000 * 5 * 10}
         //timeout={10000}
        onIdle={(e) => onIdle(e)}
        // onAction={(e) => {
        //   let rwegd: any = idleTimerRef.current;
        //   console.log(rwegd.getRemainingTime());
        // }}
      ></IdleTimer>
    </div>
  );
};

export default IdleTimerContainer;
