import React, { useState } from "react";
import Row from "./Row";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CancelRegistration from "../CancelRegistration";
import FormHeader from "../FormHeader/FormHeader";
import { TooltipContainer } from "../../../utils/Utils";
import { Alert, AlertTitle } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Spinner from "../../Spinner/Spinner";
import Axios from "axios";
import SpinnerW from "../../Spinner/SpinnerWhite";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Typography } from "@material-ui/core";

const StatusWrapper = styled.div`
  width: 700px;
  margin: 100px auto;
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid lightgrey;
  padding-top: 10px;
  margin-bottom: 20px;
`;
const LogInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CAPTCHAContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  align-items: center;
`;

const titleStyles = {
  fontWeight: 800,
  fontSize: "1.2rem",
  borderBottom: "1px solid lightgray",
  paddingBottom: "5px",
};

interface Props {
  role: any;

  nrlFile: any;
  certificateFile: any;
  authLetterFile: any;
  existingNRL: any;
  createCertificate: any;
  setPage: any;
  handleSubmitPagePrevious: any;

  nrlName: any;
  authorizationName: any;
  certificateName: any;
  publicCertificate: any;
  publicCertFileName: any;

  values: any;
}

type Color = "warning" | "info" | "success" | "error";

const SubmitPage: React.FC<Props> = ({
  role,

  nrlFile,
  certificateFile,
  authLetterFile,
  existingNRL,
  createCertificate,
  setPage,
  handleSubmitPagePrevious,

  nrlName,
  authorizationName,
  certificateName,
  publicCertificate,
  publicCertFileName,

  values,
}) => {
  const { t } = useTranslation();

  let history = useHistory();

  const [cancelRegistration, setCancelRegistration] = useState(false);

  const [showSubmitStatus, setShowSubmitStatus] = useState(false);

  //Something is wrong, contact the administrators!
  //You have successfully registered, please sign in.
  const [statusMessage, setStatusMessage] = useState(
    "You have successfully registered, please sign in."
  );
  // success
  //error
  const [severity, setSeverity] = useState<Color | undefined>("success");
  //Success
  //Error
  const [statusTitle, setStatusTitle] = useState("Success");

  const [submittingDataSpinner, setSubmittingDataSpinner] = useState(false);

  const [imgCode, setImgCode] = useState("");
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [userCaptchaInput, setUserCaptchaInput] = useState("");

  const [regSubmitLoader, setRegSubmitLoader] = useState(false);

  const [captchaErr, setCaptchaErr] = useState(false);

  const [captchaValue, setCaptchaValue] = useState("");

  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState("");
  const [fetchDataError, setFetchDataError] = useState(false);

  const stopRegistrationCancel = () => {
    setCancelRegistration(false);
  };

  const goBackToLogin = () => {
    history.push("/");
  };

  const signInHandler = () => {
    history.push("/");
  };

  /*
     async function submitRegistration(User){

           const response= await fetch("/api/register",{
      method:"POST",
      mode:"cors",
      cache:"no-cache",
      credentials :"same-origin",
      headers:{
      "Content-type":"application/json"
      },
      redirect:"follow",
      referrerPolicy:"no-referrer",
      body: JSON.stringify(User)
    });

    let body=await response.json();
    console.log(body.emailID);


  }  */

  // console.log(country);
  const fetchCAPTCHA = React.useCallback(() => {
    Axios.get(`/api/captcha`)
      .then((res) => {
        setLoadingCaptcha(false);
        setImgCode(res.data.captchaImage);
        setCaptchaValue(res.data.value);

        setFetchDataError(false);
      })
      .catch((err) => {
        setLoadingCaptcha(false);

        setFetchDataErrorMsg(
          err.response && err.response.data && err.response.data.statusMessage
            ? err.response.data.statusMessage
            : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
        );

        setFetchDataError(true);
      });
  }, []);

  React.useEffect(() => {
    fetchCAPTCHA();
  }, [fetchCAPTCHA]);

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSubmit = (setSubmittingDataSpinner: any) => {
    if (captchaValue === userCaptchaInput) {
      setSubmittingDataSpinner(true);
      let UserData = new FormData();

      if (role === "webtrader") {
        UserData.append("role", role);
        UserData.append("firstName", values.firstName);
        UserData.append("lastName", values.lastName);
        UserData.append("suffix", values.suffix ? values.suffix : "");
        UserData.append(
          "middleInitial",
          values.middleInitial ? values.middleInitial : ""
        );
        UserData.append("emailID", values.emailId);
        UserData.append("city", values.city);

        UserData.append("company", values.company.companyName);

        if (values.company.companyId) {
          UserData.append("companyId", values.company.companyId);
        }

        UserData.append("state", values.state);
        UserData.append("country", values.country.name);
        UserData.append("phone", values.phone ? values.phone : "");
        UserData.append("password", values.password);
        // UserData.append("certificatePassword", certificatePassword);
        UserData.append(
          "secondaryPhone",
          values.secondaryPhone ? values.secondaryPhone : ""
        );
        UserData.append(
          "secondaryFname",
          values.secondaryFirstName ? values.secondaryFirstName : ""
        );
        UserData.append(
          "secondaryEmailID",
          values.secondaryEmailID ? values.secondaryEmailID : ""
        );
        UserData.append(
          "secondaryMinitial",
          values.secondaryMInitial ? values.secondaryMInitial : ""
        );
        UserData.append(
          "secondaryLname",
          values.secondaryLastName ? values.secondaryLastName : ""
        );
        UserData.append(
          "secondarySuffix",
          values.secondarySuffix ? values.secondarySuffix : ""
        );

        if (nrlFile) {
          UserData.append("nrlFile", nrlFile);
        } else {
          UserData.append("existingNRL", existingNRL);
        }

        if (authLetterFile) {
          UserData.append("authLetterFile", authLetterFile);
        }
        UserData.append("shouldSystemCreateCertificate", createCertificate);
        UserData.append("captcha", userCaptchaInput);

        UserData.append(
          "languageSelection",
          localStorage.getItem("i18nextLng") as any
        );

        //if user selects upload, append an uploaded file
        //if user clicks create certificate for me then append public certificate

        if (certificateFile) {
          UserData.append("certificateFile", certificateFile);
          // UserData.append("certificateFile", certificate);
        } else {
          UserData.append("publicCertificate", publicCertificate);
          UserData.append("publicCertFileName", publicCertFileName);
        }
      } else {
        UserData.append("role", role);
        UserData.append("firstName", values.firstName);
        UserData.append("lastName", values.lastName);
        UserData.append("suffix", values.suffix ? values.suffix : "");
        UserData.append(
          "middleInitial",
          values.middleInitial ? values.middleInitial : ""
        );
        UserData.append("emailID", values.emailId);
        UserData.append("city", values.city);
        UserData.append("company", values.company.companyName);
        if (values.company.companyId) {
          UserData.append("companyId", values.company.companyId);
        }
        UserData.append("state", values.state);
        UserData.append("country", values.country.name);
        UserData.append("phone", values.phone ? values.phone : "");
        UserData.append("password", values.password);
        UserData.append(
          "languageSelection",
          localStorage.getItem("i18nextLng") as any
        );
        UserData.append("captcha", userCaptchaInput);
      }

      setRegSubmitLoader(true);
      //Uncomment on the server
      axios
        .post("/api/register", UserData)
        .then(function (res) {
          if (isMounted.current) {
            setRegSubmitLoader(false);

            if (res !== null) {
	             setStatusMessage(res.data.statusMessage);
	             setSeverity("success");
	             setShowSubmitStatus(true);
	             setStatusTitle("Success");
	             setSubmittingDataSpinner(false);
            }
          }
        })
        .catch(function (error) {
          if (isMounted.current) {
            setRegSubmitLoader(false);

            setStatusTitle("Error");
            // if (error.response) {
            //   setStatusMessage(error.response.data.statusMessage);
            // } else {
            //   setStatusMessage(error.message);
            // }

            setStatusMessage(
              error.response &&
                error.response.data &&
                error.response.data.statusMessage
                ? error.response.data.statusMessage
                : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
            );

            setSeverity("error");
            setShowSubmitStatus(true);

            setSubmittingDataSpinner(false);
          }
        });
    } else {
      fetchCAPTCHA();
      setLoadingCaptcha(true);
      setCaptchaErr(true);
    }
  };

  const goBackHandler = () => {
    if (role === "webtrader") {
      setPage(4);
      handleSubmitPagePrevious(2);
    } else {
      setPage(3);
      handleSubmitPagePrevious(1);
    }
  };

  const [certified, setCertified] = useState(false);
  const [certified2, setCertified2] = useState(false);
  const [certified3, setCertified3] = useState(false);

  if (!showSubmitStatus) {
    return (
      <div className="submit-page-wrapper">
        <div className={`user-input-1-summary`}>
          <FormHeader
            firstLine={`Registration - ${role === "poweruser" ? "Power" : ""} ${
              role === "poweruser" && role === "webtrader" ? "&" : ""
            } ${role === "webtrader" ? "WebTrader" : ""} User Account`}
            secondLine={t("Registration.53")}
          />
          <div>
            <div>
              <Typography variant="h5" component="h3" style={titleStyles}>
                {t("Registration.24")}
              </Typography>
              {/* <p className="input-heading">{t("Registration.24")}</p> */}
              <Row
                name={t("Registration.1")}
                userInput={values.firstName}
                firstInput={true}
              />
              <Row
                name={t("Registration.7")}
                userInput={values.middleInitial}
              />
              <Row name={t("Registration.2")} userInput={values.lastName} />
              <Row name={t("Registration.46")} userInput={values.suffix} />
              <Row name={t("Registration.3")} userInput={values.emailId} />
              <Row name={t("Registration.6")} userInput={values.phone} />
            </div>
            {role === "webtrader" ? (
              <div style={{ marginTop: "1rem" }}>
                {/* <p className="input-heading">{t("Registration.25")}</p> */}

                <Typography variant="h5" component="h3" style={titleStyles}>
                  {t("Registration.25")}
                </Typography>

                <Row
                  name={t("Registration.29")}
                  userInput={values.company.companyName}
                  firstInput={true}
                />
                <Row
                  name={t("Registration.28")}
                  userInput={values.country.name}
                />
                <Row name={t("Registration.27")} userInput={values.city} />
                <Row name={t("Registration.30")} userInput={values.state} />
              </div>
            ) : null}
          </div>
        </div>

        {role === "webtrader" ? (
          <div className="user-input-2-summary">
            {/* <p className="input-heading">{t("Registration.22")}</p> */}
            <Typography variant="h5" component="h3" style={titleStyles}>
              {t("Registration.22")}
            </Typography>

            <Row
              name={t("Registration.8")}
              userInput={values.secondaryFirstName}
              firstInput={true}
            />
            <Row
              name={t("Registration.10")}
              userInput={values.secondaryMInitial}
            />
            <Row
              name={t("Registration.11")}
              userInput={values.secondaryLastName}
            />
            <Row
              name={t("Registration.58")}
              userInput={values.secondarySuffix}
            />
            <Row
              name={t("Registration.12")}
              userInput={values.secondaryEmailID}
            />
            <Row
              name={t("Registration.13")}
              userInput={values.secondaryPhone}
            />

            <Typography variant="h5" component="h3" style={titleStyles}>
              {t("Registration.21")}
            </Typography>
            {/* <p className="input-heading">{t("Registration.21")}</p> */}
            <div className="document-certificate-wrapper">
              <div className="document-certificate">
                <p>{t("Registration.31")}</p>{" "}
                <p>
                  {!existingNRL
                    ? `${t("Registration.54")} : ${nrlName}`
                    : t("Registration.57")}
                </p>
              </div>
              <div className="document-certificate">
                <p>{t("Registration.34")}</p>{" "}
                <p>
                  {!createCertificate
                    ? `${t("Registration.54")} : ${certificateName}`
                    : t("Registration.56")}
                </p>
              </div>
              <div className="document-certificate">
                <p>{t("Registration.37")}</p>{" "}
                <p>
                  {authLetterFile && authLetterFile !== null
                    ? `${t("Registration.54")} : ${authorizationName}`
                    : t("Registration.55")}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="power-user-input-2-summary">
            {/* <p className="input-heading">{t("Registration.25")}</p> */}
            <Typography variant="h5" component="h3" style={titleStyles}>
              {t("Registration.25")}
            </Typography>

            <Row
              name={t("Registration.29")}
              userInput={values.company.companyName}
              firstInput={true}
            />
            <Row name={t("Registration.27")} userInput={values.city} />
            <Row name={t("Registration.30")} userInput={values.state} />
            <Row name={t("Registration.28")} userInput={values.country.name} />
          </div>
        )}

        <div className="user-confirm-submit">
          <div style={{ width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={certified}
                  onChange={() => setCertified(!certified)}
                  name="certified"
                  color="primary"
                  title={t("Translation.72")}
                />
              }
              label={t("Registration.43")}
            />

            <TooltipContainer txt={t("Registration.59")} />
          </div>

          {role !== "poweruser" ? (
            <div style={{ width: "100%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={certified2}
                    onChange={() => setCertified2(!certified2)}
                    name="certified2"
                    color="primary"
                    title={t("Translation.73")}
                  />
                }
                label={t("Registration.44")}
              />

              <TooltipContainer txt={t("Registration.60")} />
            </div>
          ) : null}
          <div style={{ width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={certified3}
                  onChange={() => setCertified3(!certified3)}
                  name="certified3"
                  color="primary"
                  title={t("Translation.74")}
                />
              }
              label={t("Registration.45")}
            />

            <TooltipContainer txt={t("Registration.61")} />
          </div>
        </div>

        <div className="user-captcha-container">
          <div style={{ width: "100%" }}>
            {fetchDataError && !loadingCaptcha && (
              <Alert severity="error">{fetchDataErrorMsg}</Alert>
            )}
          </div>
          {loadingCaptcha ? (
            <Spinner />
          ) : (
            <>
              {/* {captchaErr && (
                <>
                  <ErrorMsg>{t("Translation.24")}</ErrorMsg>
                  <br />
                </>
              )} */}
              <CAPTCHAContainer>
                <img
                  src={`data:image/jpeg;base64,${imgCode}`}
                  alt="captcha code"
                />
                <TextField
                  label={t("Translation.30")}
                  id="registration-submit-captcha"
                  value={userCaptchaInput}
                  onChange={(e) => {
                    setUserCaptchaInput(e.target.value);
                    setCaptchaErr(false);
                  }}
                  variant="outlined"
                  size="small"
                  error={captchaErr}
                  helperText={captchaErr ? t("Errors.28") : ""}
                  style={{ marginTop: "10px", width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {userCaptchaInput === captchaValue &&
                        !fetchDataError ? (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ) : (
                          <></>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  // error={userCaptchaInputErr}
                />
              </CAPTCHAContainer>
            </>
          )}
        </div>
        <div className="submit-page-footer">
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setCancelRegistration(!cancelRegistration)}
              title={t("Tooltips.11")}
            >
              {t("Registration.39")}
            </Button>
            <CancelRegistration
              cancelRegistration={cancelRegistration}
              cancelRegistrationHandler={stopRegistrationCancel}
              goBackToLogin={goBackToLogin}
            />

            <Button
              variant="outlined"
              color="primary"
              onClick={goBackHandler}
              className="previous-button"
              title={t("Tooltips.43")}
            >
              {t("Registration.41")}
            </Button>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(setSubmittingDataSpinner)}
            disabled={
              role === "poweruser"
                ? !certified || !certified3 || !userCaptchaInput
                : !certified || !certified2 || !certified3 || !userCaptchaInput
            }
          >
            {t("Registration.42")} {regSubmitLoader ? <SpinnerW /> : ""}
          </Button>
        </div>
      </div>
    );
  } else {
    if (submittingDataSpinner) {
      return <p>Loading</p>;
    } else {
      return (
        <StatusWrapper>
          <Paper elevation={3} style={{ padding: "30px" }}>
            <Alert severity={severity}>
              <AlertTitle>
                <strong>{statusTitle}</strong>
              </AlertTitle>
              {statusMessage}
            </Alert>
            <HorizontalLine></HorizontalLine>
            <LogInButtonWrapper>
              {" "}
              <Button
                variant="outlined"
                color="primary"
                onClick={signInHandler}
              >
                {t("Signin.1")}
              </Button>
            </LogInButtonWrapper>
          </Paper>
        </StatusWrapper>
      );
    }
  }
};

export default SubmitPage;
