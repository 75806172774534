import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const HorizontalLine = styled.div`
  border-bottom: 1px solid lightgrey;
  padding-top: 10px;
  margin-bottom: 20px;
`;
const LogInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const RestrictedAccesContainer = styled.div`
  width: 600px;
  margin: 100px auto;
  text-align: center;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 7px;
  padding: 30px;
`;

const authPO = (Component: any) => {
  const Access = (props: any) => {
    let history = useHistory();

    const signInHandler = () => {
      history.push("/");
    };

    const { t } = useTranslation();

    if (
      sessionStorage.getItem("authorized") === "true" &&
      sessionStorage.getItem("user") === "3"
    ) {
      return <Component {...props} />;
    } else {
      return (
        <RestrictedAccesContainer>
          {/* <p>Unathorized access</p> */}
          <Alert severity="error">
            <AlertTitle>
              <strong>Unathorized access</strong>
            </AlertTitle>
            Exception occurred while processing your request. Please try again.
            If you continue to get this problem please contact ESG Help Desk.
          </Alert>
          <HorizontalLine></HorizontalLine>
          <LogInButtonWrapper>
            {" "}
            <Button variant="outlined" color="primary" onClick={signInHandler}>
              {t("Signin.1")}
            </Button>
          </LogInButtonWrapper>
        </RestrictedAccesContainer>
      );
    }
  };

  return Access;
};

export default authPO;
