import React, { useState, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { submitStatus } from "../../utils/Utils";
import Spinner from "../Spinner/Spinner";
import { Formik, Form } from "formik";
import { forgotOptionsValid } from "../../utils/Verification";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { InputWithInfoIcon } from "../../utils/Input";

const GetNewPasswordInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid lightgrey;
  padding-top: 10px;
  margin-bottom: 20px;
`;
const LogInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CAPTCHAContainer = styled.div`
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ForgotUserId: React.FC = () => {
  const [showMsg, setShowMsg] = useState(false);

  const [dataUpdateStatus, setDataUpdateStatus] = useState("");
  const [status, setStatus] = useState(0);

  const [imgCode, setImgCode] = useState("");
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  const [captchaValue, setCaptchaValue] = useState("");

  const [submittingEmailLoader, setSubmittingEmailLoader] = useState(false);

  const [captchaErr, setCaptchaErr] = useState(false);

  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState("");
  const [fetchDataError, setFetchDataError] = useState(false);

  const { t } = useTranslation();

  let history = useHistory();

  const fetchCAPTCHA = useCallback(() => {
    Axios.get(`/api/captcha`)
      .then((res) => {
        setLoadingCaptcha(false);
        setImgCode(res.data.captchaImage);
        setCaptchaValue(res.data.value);

        setFetchDataError(false);
      })
      .catch((err) => {
        setLoadingCaptcha(false);

        setFetchDataErrorMsg(
          err.response && err.response.data && err.response.data.statusMessage
            ? err.response.data.statusMessage
            : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
        );

        setFetchDataError(true);
      });
  }, []);

  React.useEffect(() => {
    fetchCAPTCHA();
  }, [fetchCAPTCHA]);

  const handleGetNew = (values: any, actions: any) => {
    if (values.userCaptchaInput === captchaValue) {
      let UserData = new FormData();

      UserData.append("emailID", values.email);
      UserData.append("captcha", values.userCaptchaInput);
      UserData.append(
        "languageSelection",
        localStorage.getItem("i18nextLng") as any
      );

      setSubmittingEmailLoader(true);

      Axios.post("/api/forgotUserId", UserData)
        .then((res) => {
          setSubmittingEmailLoader(false);
          //check PENDING SUCCESS ERROR
          if (res.data.status === "SUCCESS") {
            //Add status statusMessage
            //TO THE MESSAGE DISPLAYED AFTER SUBMIT.
            setStatus(2);
          } else if (res.data.status === "WARNING") {
            setStatus(4);
          } else {
            setStatus(3);
          }

          setShowMsg(true);
          setDataUpdateStatus(res.data.statusMessage);
        })
        .catch((err) => {
          setSubmittingEmailLoader(false);

          setDataUpdateStatus(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setStatus(3);
          setShowMsg(true);
        });
    } else {
      setLoadingCaptcha(true);
      fetchCAPTCHA();

      actions.resetForm();
      setCaptchaErr(true);
    }
  };

  interface InitValuesTypes {
    email: string;
    userCaptchaInput: "";
  }

  const initValues: InitValuesTypes = {
    email: "",
    userCaptchaInput: "",
  };

  const validate = (values: any) => {
    interface ErrorsType {}

    let errors: ErrorsType = {};

    if (values.userCaptchaInput) {
      setCaptchaErr(false);
    }

    return errors;
  };

  if (showMsg) {
    return (
      <div>
        <Paper elevation={3} className="forgot-password-paper">
          {submitStatus(status, dataUpdateStatus)}

          <HorizontalLine></HorizontalLine>

          <LogInButtonWrapper>
            {" "}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push("/");
              }}
            >
              {t("Signin.1")}
            </Button>
          </LogInButtonWrapper>
        </Paper>
      </div>
    );
  } else {
    return (
      <div>
        <Paper elevation={3} className="forgot-password-paper">
          <h2>{t("Translation.11")}</h2>
          <h3>{t("Translation.15")}</h3>

          <Formik
            initialValues={initValues}
            enableReinitialize={true}
            onSubmit={handleGetNew}
            validationSchema={forgotOptionsValid}
            validate={validate}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => {
              return (
                <Form>
                  <GetNewPasswordInput>
                    {/* {captchaErr && values.userCaptchaInput !== captchaValue && (
                      <CaptchaErrorContainer>
                        <ErrorMsg>{t("Translation.24")}</ErrorMsg>
                      </CaptchaErrorContainer>
                    )} */}

                    <InputWithInfoIcon
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={t("Translation.26")}
                      titleTxt={t("Translation.27")}
                      tooltipTxt={t("Translation.28")}
                      maxLength={100}
                      error={(errors.email as any) && (touched.email as any)}
                    />

                    {errors.email && touched.email && (
                      <div className="error-container">
                        <p>{t("Errors.12")}</p>
                        <ul
                          style={{
                            listStylePosition: "inside",
                          }}
                        >
                          <li>{t("Errors.1")}</li>
                          <li>{t("Errors.2")}</li>

                          <li>{t("Errors.3")}</li>
                          <li>{t("Errors.4")}</li>
                          <li>{t("Errors.5")}</li>
                        </ul>
                        <p>{t("Errors.6")}</p>
                        <ul
                          style={{
                            listStylePosition: "inside",
                          }}
                        >
                          <li>{t("Errors.1")}</li>
                          <li>{t("Errors.2")}</li>
                          <li>{t("Errors.9")}</li>
                          <li>{t("Errors.10")}</li>
                        </ul>
                        <p> {t("Errors.11")}</p>
                      </div>
                    )}

                    <br />

                    <div style={{ width: "100%" }}>
                      {fetchDataError && !loadingCaptcha && (
                        <Alert severity="error">{fetchDataErrorMsg}</Alert>
                      )}
                    </div>

                    {loadingCaptcha ? (
                      <Spinner />
                    ) : (
                      <CAPTCHAContainer>
                        <img
                          src={`data:image/jpeg;base64,${imgCode}`}
                          alt="CAPTCHA-code"
                        />
                        <TextField
                          id="CAPTCHA-input"
                          name="userCaptchaInput"
                          label={t("Translation.30")}
                          value={values.userCaptchaInput}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          variant="outlined"
                          size="small"
                          error={
                            captchaErr &&
                            values.userCaptchaInput !== captchaValue
                          }
                          helperText={
                            captchaErr &&
                            values.userCaptchaInput !== captchaValue
                              ? t("Errors.28")
                              : ""
                          }
                          style={{ marginTop: "10px", width: "100%" }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {values.userCaptchaInput === captchaValue &&
                                !fetchDataError ? (
                                  <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                  <></>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </CAPTCHAContainer>
                    )}

                    <div style={{ marginBottom: "70px" }}></div>

                    <ButtonsContainer>
                      <Button
                        variant="outlined"
                        color="primary"
                        href="/"
                        title={t("Translation.29")}
                      >
                        {t("Signin.1")}
                      </Button>

                      <SubmitContainer>
                        {submittingEmailLoader && <Spinner />}
                        <Button
                          variant="contained"
                          color="primary"
                          title={t("Translation.33")}
                          type="submit"
                          disabled={
                            isSubmitting ||
                            values.email === "" ||
                            values.userCaptchaInput === "" ||
                            (errors.email as any)
                          }
                        >
                          {t("Translation.20")}
                        </Button>
                      </SubmitContainer>
                    </ButtonsContainer>
                  </GetNewPasswordInput>
                </Form>
              );
            }}
          </Formik>
        </Paper>
      </div>
    );
  }
};

export default ForgotUserId;
