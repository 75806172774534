import React, { useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import {
  InputWithInfoIcon,
  NotEditableInputWithInfoIcon,
} from "../../../../utils/Input";
import axios from "axios";
import { countries } from "../../../../utils/Countries";
import Alert from "@material-ui/lab/Alert";
import Spinner from "../../../Spinner/Spinner";
import { useTranslation } from "react-i18next";
import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { submitStatus } from "../../../../utils/Utils";
import { UpdateCompanySchema } from "../../../../utils/Verification";

import {
  InputRow,
  UpdateCountryField,
  UpdateProfileWrapper,
  SubmitContainer,
  UpdateCountryFieldWrapper,
  SubmitContainerWrapperCompany,
  ErrorMsg,
} from "../../Layout/Layout";

interface Props {
  getAPI: string;
  postAPI: string;
  setUpdateUser?: any;
}

interface Values {
  city: string;
  country: any;
  state: string;
  companyName: string;
}

export const UpdateCompanyProfile: React.FC<Props> = ({
  getAPI,
  postAPI,
  setUpdateUser,
}) => {
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(true);
  const [apiStatus, setApiStatus] = useState(0);
  const [dataUpdateStatus, setDataUpdateStatus] = useState("");
  const [companyId, setCompanyId] = useState(null);
  const [resData, setResData] = useState<any>(null);
  const [fetchDataError, setFetchDataError] = useState(false);
  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState(false);

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchData = useCallback(() => {
    axios
      .get(getAPI, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          setResData(res.data);
          setFetchDataError(false);
          setFetchingData(false);
          setCompanyId(res.data.companyId);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setFetchingData(false);
          setFetchDataErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );

          setFetchDataError(true);
        }
      });
  }, [getAPI]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUpdateCompanySubmit = (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    setApiStatus(1);
    let UserData = new FormData();

    UserData.append("state", values.state);
    UserData.append("country", values.country.name);
    UserData.append("city", values.city);
    // UserData.append("company", values.companyName);

    UserData.append(
      "languageSelection",
      localStorage.getItem("i18nextLng") as any
    );
    actions.setSubmitting(true);

    axios
      .post(postAPI + "&companyid=" + companyId, UserData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        //check PENDING SUCCESS ERROR
        if (isMounted.current) {
          if (res.data.status === "SUCCESS") {
            setApiStatus(2);
          } else if (res.data.status === "WARNING") {
            setApiStatus(4);
          } else {
            setApiStatus(3);
          }
          setDataUpdateStatus(res.data.statusMessage);
          actions.setSubmitting(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setApiStatus(3);
          actions.setSubmitting(false);
          setDataUpdateStatus(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
        }
      });
  };

  const initialValues: any = {
    city: resData && resData.city ? resData.city : "",
    state: resData && resData.state ? resData.state : "",
    country:
      resData && resData.country
        ? { name: resData.country }
        : {
            name: "",
          },
    companyName: resData && resData.company ? resData.company : "",
  };

  const [showTooltip, setShowTooltip] = React.useState(false);

  const renderTooltip = (props: any) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      onMouseLeave={() => setShowTooltip(false)}
      onMouseEnter={() => setShowTooltip(true)}
    >
      {t("UpdateCompanyProfile.11")}
    </Tooltip>
  );

  if (fetchingData) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    if (fetchDataError) {
      return <Alert severity="error">{fetchDataErrorMsg}</Alert>;
    } else {
      return (
        <>
          <div className="update-account-wrapper">
            <Paper elevation={0} className="update-account-paper">
              <UpdateProfileWrapper>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={handleUpdateCompanySubmit}
                  validationSchema={() =>
                    UpdateCompanySchema(t("Translation.59"))
                  }
                  validate={(values) => {
                    interface ErrorsType {
                      country?: string;
                    }

                    let errors: ErrorsType = {};

                    if (
                      values.country &&
                      (values.country.name === "" ||
                        values.country.name === null)
                    ) {
                      errors.country = "Required field(s) is/are missing";
                    }

                    if (
                      values.country === null ||
                      values.country === undefined
                    ) {
                      errors.country = "Required field(s) is/are missing";
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    setFieldValue,
                  }) => {
                    console.log(errors);
                    return (
                      <Form>
                        <InputRow className="input-row-styles">
                          <label htmlFor="companyName">
                            {t("UpdateCompanyProfile.14")}
                          </label>
                          <div>
                            <NotEditableInputWithInfoIcon
                              value={values.companyName}
                              id="companyName"
                            />
                          </div>
                        </InputRow>
                        <br />
                        <InputRow className="input-row-styles">
                          {/* <p>{t("UpdateCompanyProfile.3")}</p> */}
                          <label htmlFor="city">
                            {t("UpdateCompanyProfile.3")}
                          </label>
                          <div>
                            <InputWithInfoIcon
                              name="city"
                              id="city"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              tooltipTxt={t("UpdateCompanyProfile.5")}
                              titleTxt={t("UpdateCompanyProfile.4")}
                              error={errors.city && touched.city}
                              maxLength={50}
                            />

                            <ErrorMessage name="city">
                              {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                            </ErrorMessage>
                          </div>
                        </InputRow>
                        <br />
                        <InputRow className="input-row-styles">
                          {/* <p>{t("UpdateCompanyProfile.6")}</p> */}
                          <label htmlFor="state">
                            {t("UpdateCompanyProfile.6")}
                          </label>
                          <div>
                            <InputWithInfoIcon
                              name="state"
                              id="state"
                              value={values.state}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              tooltipTxt={t("UpdateCompanyProfile.8")}
                              titleTxt={t("UpdateCompanyProfile.7")}
                              error={errors.state && touched.state}
                              maxLength={50}
                            />

                            <ErrorMessage name="state">
                              {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                            </ErrorMessage>
                          </div>
                        </InputRow>

                        <br />
                        <UpdateCountryFieldWrapper>
                          {/* <p>{t("UpdateCompanyProfile.9")}</p> */}
                          <label htmlFor="country" style={{ width: "30%" }}>
                            {t("UpdateCompanyProfile.9")}
                          </label>
                          <div style={{ width: "70%" }}>
                            <Autocomplete
                              value={values.country as any}
                              id="country"
                              autoComplete={true}
                              options={countries}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, value) => {
                                return option.name === value.name;
                              }}
                              onChange={(event, newValue: any) => {
                                setFieldValue("country", newValue);
                                // if (newValue) {
                                //   setFieldValue("country", newValue.name);
                                // } else {
                                //   setFieldValue("country", "");
                                // }
                              }}
                              renderInput={(params) => (
                                <UpdateCountryField>
                                  <TextField
                                    {...params}
                                    // label={"testtttt"}
                                    variant="outlined"
                                    size="small"
                                    title={t("UpdateCompanyProfile.10")}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "new-password", // disable autocomplete and autofill
                                    }}
                                    error={
                                      (errors.country as any) && touched.country
                                    }
                                    // className="input-field"
                                    // error={contryError}
                                    // helperText={contryErrorMessage}
                                    // onBlur={() => {
                                    //   if (country !== null) {
                                    //     setCountryError(false);
                                    //     setCountryErrorMessage("");
                                    //   }
                                    // }}
                                  />
                                  <div
                                    className="tooltip-icon-info"
                                    // style={{ marginBottom: "0" }}
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip}
                                      show={showTooltip}
                                    >
                                      <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        onMouseEnter={() =>
                                          setShowTooltip(true)
                                        }
                                        onMouseLeave={() =>
                                          setShowTooltip(false)
                                        }
                                      />
                                    </OverlayTrigger>
                                  </div>
                                </UpdateCountryField>
                              )}
                            />
                            <ErrorMessage name="country">
                              {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                            </ErrorMessage>
                          </div>
                        </UpdateCountryFieldWrapper>
                        <br />
                        <br />
                        <div>{submitStatus(apiStatus, dataUpdateStatus)}</div>
                        <br />
                        <SubmitContainerWrapperCompany>
                          <SubmitContainer>
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                              title={t("UpdateCompanyProfile.13")}
                            >
                              {t("UpdateCompanyProfile.12")}
                            </Button>
                          </SubmitContainer>
                        </SubmitContainerWrapperCompany>
                      </Form>
                    );
                  }}
                </Formik>
              </UpdateProfileWrapper>
            </Paper>
          </div>
        </>
      );
    }
  }
};

export default UpdateCompanyProfile;
