import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Spinner from "../../../Spinner/Spinner";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";

const TopText = styled.div`
  margin-left: 1.5rem;
  margin-top: 1.5rem;
  border-bottom: 1px solid lightgrey;
  margin-right: 1.5rem;
`;
const BottomText = styled.div`
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "8px",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

interface Props {
  getAPI: string;
}

const ViewStatus: React.FC<Props> = ({ getAPI }) => {
  const { t } = useTranslation();

  const [fetchingError, setFetchingError] = useState(false);
  const [fetchingErrorMsg, setFetchingErrorMsg] = useState("");

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  function getSteps() {
    return [
      t("ViewAccountStatus.3"),
      t("ViewAccountStatus.5"),
      t("ViewAccountStatus.7"),
      t("ViewAccountStatus.9"),
    ];
  }

  function getStepContent(step: any) {
    switch (step) {
      case 0:
        return t("ViewAccountStatus.4");
      case 1:
        return t("ViewAccountStatus.6");
      case 2:
        return t("ViewAccountStatus.8");
      case 3:
        return t("ViewAccountStatus.10");

      case 4:
        return t("ViewAccountStatus.4");
      case 5:
        return t("ViewAccountStatus.6");
      case 6:
        return t("ViewAccountStatus.10");
      default:
        return "Unknown step";
    }
  }
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [fetchingData, setFetchingData] = useState(true);

  const fetchData = useCallback(() => {
    axios
      .get(getAPI, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          setFetchingData(false);

          setActiveStep(Number(res.data.status));
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setFetchingData(false);

          // setShowError(true);

          setFetchingErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setFetchingError(true);
        }
      });
  }, [getAPI]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const determineIfActive = (ind: any) => {
    if ((activeStep === 1 || activeStep === 5) && ind <= 0) {
      return true;
    } else if ((activeStep === 2 || activeStep === 6) && ind <= 1) {
      return true;
    } else if ((activeStep === 3 || activeStep === 7) && ind <= 2) {
      return true;
    } else if ((activeStep === 4 || activeStep === 8) && ind <= 3) {
      return true;
    } else {
      return false;
    }
  };

  const determineIfExp = (ind: any) => {
    if ((activeStep === 1 || activeStep === 5) && ind === 1) {
      return true;
    } else if ((activeStep === 2 || activeStep === 6) && ind === 2) {
      return true;
    } else if (activeStep === 3 && ind === 3) {
      return true;
    } else if ((activeStep === 4 || activeStep === 7) && ind === 4) {
      return true;
    } else {
      return false;
    }
  };

  if (fetchingData) {
    return (
      <>
        <Spinner />
      </>
    );
  } else if (fetchingError) {
    return <Alert severity="error">{fetchingErrorMsg}</Alert>;
  } else {
    return (
      <>
        <TopText>
          <Typography variant="h5" component="h2">
            {t("ViewAccountStatus.2")}
          </Typography>
          {/* <h4>{t("ViewAccountStatus.2")}</h4> */}
        </TopText>
        <div className={`${classes.root} submissions-status`}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => {
              return (
                <Step
                  key={label}
                  completed={determineIfActive(index)}
                  expanded={determineIfExp(index)}
                >
                  <StepLabel style={{ fontWeight: 600 }}>{label}</StepLabel>
                  <StepContent>
                    <Typography>{getStepContent(index)}</Typography>
                    <div className={classes.actionsContainer}></div>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </div>
        <BottomText>
          <p>{t("ViewAccountStatus.11")}</p>
        </BottomText>
      </>
    );
  }
};

export default ViewStatus;
