import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
const WelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const WelcomeText = styled.p`
  font-size: 1.2rem;
`;

const Welcome: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* <OptionHeader>
        <p>Welcome to FDA ESG Portal</p>
      </OptionHeader> */}
      <WelcomeWrapper>
        <div style={{ textAlign: "center" }}>
          {/* <h4>{t("PUHome.1")}</h4> */}
          <Typography variant="h4" component="h2">
            {t("PUHome.1")}
          </Typography>
        </div>
        <br />
        <br />
        <WelcomeText>{t("PUHome.2")}</WelcomeText>
        <br /> <br />
      </WelcomeWrapper>
    </>
  );
};

export default Welcome;
