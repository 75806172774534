import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  cancelRegistration: any;
  cancelRegistrationHandler: any;
  goBackToLogin: any;
}

const CancelRegistration: React.FC<Props> = ({
  cancelRegistration,
  cancelRegistrationHandler,
  goBackToLogin,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={cancelRegistration}
      TransitionComponent={Transition}
      keepMounted
      onClose={cancelRegistrationHandler}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {t("CancelRegistration.1")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t("CancelRegistration.2")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={cancelRegistrationHandler}
          variant="outlined"
          color="primary"
          title={t("CancelRegistration.4")}
        >
          {t("CancelRegistration.3")}
        </Button>
        <Button
          onClick={goBackToLogin}
          variant="contained"
          color="secondary"
          title={t("CancelRegistration.6")}
        >
          {t("CancelRegistration.5")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelRegistration;
