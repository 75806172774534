import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { detectIE } from "../../../../utils/checkIE";

import { submitStatus } from "../../../../utils/Utils";

const CertificateWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 30px;
`;

const CertificateUploadMessage = styled.p`
  font-weight: 300;

  color: gray;
  font-size: 1.5rem;
`;

const UploadErrorMessage = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #c60000;
`;

interface Props {
  setUpdateUser: any;
  postAPI: any;
}

const Certificate: React.FC<Props> = ({ setUpdateUser, postAPI }) => {
  const [certificateFile, setCertificateFile] = useState(null);
  const [certificateFileName, setCertificateFileName] = useState(
    localStorage.getItem("i18nextLng") === "fr"
      ? "Joindre un fichier…"
      : "Attach a file..."
  );
  const [certificateFileError, setCertificateFileError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedError, setCheckedError] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [statusCode, setStatusCode] = useState(0);

  const [certificateExtError, setCertificateExtError] = useState(false);

  const [disableSubmit, setDisableSubmit] = useState(false);

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { t } = useTranslation();

  const submitCert = () => {
    if (certificateFile === null) {
      setCertificateFileError(true);
    } else if (!checked) {
      setCheckedError(true);
    } else {
      setStatusCode(1);
      let UserData = new FormData();

      UserData.append("certificateFile", certificateFile as any);
      UserData.append(
        "languageSelection",
        localStorage.getItem("i18nextLng") as any
      );

      setDisableSubmit(true);
      axios
        .post(postAPI, UserData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (isMounted.current) {
            if (res.data.status === "SUCCESS") {
              setStatusCode(2);
            } else if (res.data.status === "WARNING") {
              setStatusCode(4);
            } else {
              setStatusCode(3);
            }

            // setStatusCode(2);
            setStatusMessage(res.data.statusMessage);
            setDisableSubmit(false);

            setCertificateFile(null);
            setCertificateFileName(
              localStorage.getItem("i18nextLng") === "fr"
                ? "Joindre un fichier…"
                : "Attach a file..."
            );
            setCheckedError(false);
            setChecked(false);
          }
        })
        .catch((err) => {
          if (isMounted.current) {
            setStatusCode(3);
            setDisableSubmit(false);
            setStatusMessage(
              err.response &&
                err.response.data &&
                err.response.data.statusMessage
                ? err.response.data.statusMessage
                : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
            );
          }
        });
    }
  };

  const certificateUploadHandler = (e: any) => {
    // setCertificateFile(e.target.files[0]);
    // setCertificateFileName(e.target.files[0].name);

    // setCertificateFileError(false);

    let name = e.target.files[0].name;

    let ext = name?.slice(name.length - 4, name.length);

    if (ext !== ".cer" && ext !== ".p7b" && ext !== ".p7c") {
      setCertificateExtError(true);
      setCertificateFileError(false);

      setCertificateFile(null);
      setCertificateFileName(
        localStorage.getItem("i18nextLng") === "fr"
          ? "Joindre un fichier…"
          : "Attach a file..."
      );
    } else {
      setCertificateFile(e.target.files[0]);
      setCertificateFileName(e.target.files[0].name);

      setCertificateExtError(false);
      setCertificateFileError(false);
    }

    if (!detectIE()) {
      e.target.value = "";
    }
  };

  const checkBoxHandler = () => {
    setChecked(!checked);
  };

  return (
    <CertificateWrapper>
      <h2 style={{ fontSize: "1.75rem" }}>{t("UpdateCompanyCertificate.1")}</h2>
      <br />
      <CertificateUploadMessage>
        {t("UpdateCompanyCertificate.2")}
      </CertificateUploadMessage>
      <br /> <br />
      <Form style={{ width: "100%" }}>
        <Form.File
          id="certificate-upload"
          title={t("Translation.71")}
          custom
          accept=".cer, .p7b, .p7c"
          label={certificateFileName}
          onChange={certificateUploadHandler}
          isInvalid={certificateFileError || certificateExtError}
          data-browse={
            localStorage.getItem("i18nextLng") === "fr" ? "Explorer" : "Browse"
          }
        />
      </Form>
      {certificateFileError ? (
        <UploadErrorMessage>{t("Translation.59")}</UploadErrorMessage>
      ) : null}
      {certificateExtError && (
        <UploadErrorMessage>{t("Errors.27")}</UploadErrorMessage>
      )}
      <br />
      {checkedError && !checked && (
        <UploadErrorMessage>{t("Translation.59")}</UploadErrorMessage>
      )}
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={checked}
            onChange={checkBoxHandler}
          />
        }
        label={t("UpdateCompanyCertificate.5")}
        title={t("UpdateCompanyCertificate.6")}
      />
      <br />
      <br />
      <br />
      <div>{submitStatus(statusCode, statusMessage)}</div> <br />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          // onClick={handleSubmitUpload}
          color="secondary"
          variant="outlined"
          onClick={setUpdateUser}
          title={t("UpdateCompanyCertificate.8")}
        >
          {t("UpdateCompanyCertificate.7")}
        </Button>

        <Button
          onClick={submitCert}
          color="primary"
          variant="contained"
          title={t("UpdateCompanyCertificate.10")}
          disabled={
            !checked ||
            disableSubmit ||
            certificateFile === null ||
            certificateFile === undefined
          }
        >
          {t("UpdateCompanyCertificate.9")}
        </Button>
      </div>
    </CertificateWrapper>
  );
};

export default Certificate;
