import styled from "styled-components";

export const SubmitContainerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubmitContainerWrapperCompany = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const UpdateProfileWrapper = styled.div`
  /* width: 90%; */
  margin: 0 auto;
  padding: 30px;
  @media (min-width: 1060px) {
    width: 80%;
  }
`;

export const InputRow = styled.div`
  display: flex;
`;

export const UpdateCountryField = styled.div`
  display: flex;
  align-items: center;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ErrorMsg = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #c60000;
`;

export const SubmitStatusContainer = styled.div`
  margin-bottom: 30px;
`;

export const MainContainerPWD = styled.div`
  padding: 30px;
`;

export const InputSectionPWD = styled.div`
  margin-bottom: 3rem;
`;

type PropsInputRow = {
  first?: boolean;
};

export const InputRowPWD = styled.div<PropsInputRow>`
  display: flex;
  margin-bottom: ${(props) => (props.first ? "2.5rem" : "1.5rem")};
`;

export const SubmitSectionPWD = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UpdateCountryFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const AttachContainer = styled.div`
  padding: 15px 30px;

  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LetterSection = styled.div`
  padding: 30px;
`;

export const UploadErrorMessage = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #c60000;
`;

export const LetterSectionTitle = styled.p`
  border-bottom: 1px solid lightgrey;
  padding-bottom: 7px;
  font-weight: 700;
  font-size: 1.2rem;
`;

export const ExtraMessageContainer = styled.div`
  padding-left: 30px;
`;
export const ExtraMessage = styled.p`
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

export const GoBackToSearchWrapper = styled.div`
  padding: 30px;
`;

export const letterSectionTitleStyle = {
  borderBottom: "1px solid lightgrey",
  paddingBottom: "7px",
  fontWeight: 700,
  fontSize: "1.2rem",
};
