import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { submitStatus } from "../../../../utils/Utils";
import Typography from "@material-ui/core/Typography";

const RequestPowerUserContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 100px;
  padding-right: 100px;
`;

const Request: React.FC = () => {
  const { t } = useTranslation();
  const [requested, setRequest] = useState(false);

  const [requestPUStatus, setRequestPUStatus] = useState("");

  const [apiStatus, setApiStatus] = useState(0);

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitData = () => {
    setApiStatus(1);
    axios
      .get(
        `/api/reqPowerUser?userid=${sessionStorage.getItem(
          "userID"
        )}&lang=${localStorage.getItem("i18nextLng")}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (isMounted.current) {
          const data = res.data;

          setRequest(true);

          if (res.data.status === "SUCCESS") {
            setApiStatus(2);
          } else if (res.data.status === "WARNING") {
            setApiStatus(4);
          } else {
            setApiStatus(3);
          }

          setRequestPUStatus(data.statusMessage);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setApiStatus(3);
          setRequestPUStatus(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
        }
      });
  };

  return (
    <>
      <RequestPowerUserContainer>
        {requested ? (
          <></>
        ) : (
          <>
            <Typography
              variant="h5"
              component="h2"
              style={{ marginBottom: "3rem" }}
            >
              {t("RequestPower.2")}
            </Typography>
            {/* <h5>{t("RequestPower.2")}</h5> */}
            <Button
              variant="contained"
              color="primary"
              onClick={submitData}
              title={t("RequestPower.4")}
            >
              {t("RequestPower.3")}
            </Button>
          </>
        )}
        <div>{submitStatus(apiStatus, requestPUStatus)}</div>
      </RequestPowerUserContainer>
    </>
  );
  // }
};

export default Request;
