import * as Yup from "yup";

export const WTSchema = (
  missingRequired: any,
  alphab: any,
  suffixMsg: any,
  mustMatch: any,
  secondaryAlphab: any,
  secondarySuffix: any
) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(missingRequired)
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab),

    middleInitial: Yup.string()
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab)
      .nullable(),

    lastName: Yup.string()
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab)

      .required(missingRequired),

    suffix: Yup.string()
      .matches(/^[a-zA-Z0-9.àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, suffixMsg)
      .nullable(),

    //TODO add more verification
    emailId: Yup.string()
      .email(
        " Enter a valid email  The Email ID must be of the form Part1 || “@” || Part2, <br /> where  Part1 must only have any of the following ASCII characters:  Uppercase letters A to Z Lowercase letters a to z No more than 50 characters Digits 0 to 9 Printable characters from the set {`!#$%&’*+-/=?^_`{|}~`} Dot “.”, provided it is not the first or last character, and provided that it does not appear consecutively Part2 is the domain name of the email address and must meet the requirements of a hostname, a list of dot-separated DNS labels, each label being limited to a length of 63 characters and consisting of: Uppercase letters A to Z Lowercase letters a to z Digits 0 to 9, provided that top-level domain names are not all-numeric Hyphen “-“, provided that it is not the first or last character. For example, ‘john.doe@companyname.com’."
      )
      .matches(/^[a-zA-Z0-9.!#$%&*+-/=?^_`{|}~@]+$/, "")
      .required(missingRequired),

    phone: Yup.string()
      .matches(
        /^(\+\d{1,5}\s?)?((\d{3}(\s|-?)?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(x|X)(\s?\d+))?$/,
        "Phone number must only contain digits, use X to separate extension."
    ).nullable(),

    secondaryEmailID: Yup.string()
      .email(
        " Enter a valid email  The Email ID must be of the form Part1 || “@” || Part2, <br /> where  Part1 must only have any of the following ASCII characters:  Uppercase letters A to Z Lowercase letters a to z No more than 50 characters Digits 0 to 9 Printable characters from the set {`!#$%&’*+-/=?^_`{|}~`} Dot “.”, provided it is not the first or last character, and provided that it does not appear consecutively Part2 is the domain name of the email address and must meet the requirements of a hostname, a list of dot-separated DNS labels, each label being limited to a length of 63 characters and consisting of: Uppercase letters A to Z Lowercase letters a to z Digits 0 to 9, provided that top-level domain names are not all-numeric Hyphen “-“, provided that it is not the first or last character. For example, ‘john.doe@companyname.com’."
      )
      .matches(/^[a-zA-Z0-9.!#$%&’*+-/=?^_`{|}~@]+$/, ""),

    secondaryFirstName: Yup.string().matches(
      /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
      secondaryAlphab
    ),

    secondaryLastName: Yup.string().matches(
      /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
      secondaryAlphab
    ),

    secondarySuffix: Yup.string()
      .matches(
        /^[a-zA-Z0-9.àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
        secondarySuffix
      )
      .nullable(),

    secondaryMInitial: Yup.string()
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, secondaryAlphab)
      .nullable(),
    
    secondaryPhone: Yup.string()
      .matches(
        /^(\+\d{1,5}\s?)?((\d{3}(\s|-?)?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(x|X)(\s?\d+))?$/,
        "Phone number must only contain digits, use X to separate extension."
    ).nullable(),

    password: Yup.string()
      .required(missingRequired)
      .min(15, "Minimum password length is 15 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-(=)[\]|\\;:~!@#$%^&*])(?=.{8,})[A-Za-z0-9-(=)[\]|\\;:~!@#$%^&*]+$/,
        "Must Contain at least one uppercase, one lowercase, one number and one special case character"
      ),

    reenterPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], mustMatch)
      .required(missingRequired),
  });
};

export const PUSchema = (
  missingRequired: any,
  alphab: any,
  suffixMsg: any,
  mustMatch: any
) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(missingRequired)
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab),

    middleInitial: Yup.string()
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab)
      .nullable(),

    lastName: Yup.string()
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab)

      .required(missingRequired),

    suffix: Yup.string()
      .matches(/^[a-zA-Z0-9.àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, suffixMsg)
      .nullable(),

    //TODO add more verification
    emailId: Yup.string()
      .email(
        " Enter a valid email  The Email ID must be of the form Part1 || “@” || Part2, where   Part1 must only have any of the following ASCII characters:  Uppercase letters A to Z Lowercase letters a to z No more than 50 characters Digits 0 to 9 Printable characters from the set {`!#$%&’*+-/=?^_`{|}~`} Dot “.”, provided it is not the first or last character, and provided that it does not appear consecutively Part2 is the domain name of the email address and must meet the requirements of a hostname, a list of dot-separated DNS labels, each label being limited to a length of 63 characters and consisting of: Uppercase letters A to Z Lowercase letters a to z Digits 0 to 9, provided that top-level domain names are not all-numeric Hyphen “-“, provided that it is not the first or last character. For example, ‘john.doe@companyname.com’."
      )
      .matches(/^[a-zA-Z0-9.!#$%&’*+-/=?^_`{|}~@]+$/, ""),
        
    password: Yup.string()
      .required(missingRequired)
      .min(15, "Minimum password length is 15 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-(=)[\]|\\;:~!@#$%^&*])(?=.{8,})[A-Za-z0-9-(=)[\]|\\;:~!@#$%^&*]+$/,
        "Must Contain at least one uppercase, one lowercase, one number and one special case character"
      ),

    reenterPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], mustMatch)
      .required(missingRequired),
  });
};

export const PasswordSchema = (missingRequired: any, mustMatch: any) => {
  return Yup.object().shape({
    newPwd: Yup.string()
      .required(missingRequired)
      .min(15, "Minimum password length is 15 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-(=)[\]|\\;:~!@#$%^&*])(?=.{8,})[A-Za-z0-9-(=)[\]|\\;:~!@#$%^&*]+$/,
        "Must Contain at least one uppercase, one lowercase, one number and one special case character"
      ),

    reenterNewPwd: Yup.string()
      .oneOf([Yup.ref("newPwd"), null], mustMatch)
      .required(missingRequired),
  });
};

export const forgotOptionsValid = () => {
  return Yup.object().shape({
    email: Yup.string().email("Enter a valid email format."),
  });
};
export const ProfileSchema = (
  missingRequired: any,
  alphab: any,
  suffixMsg: any
) => {
  return Yup.object().shape({
    fName: Yup.string()
      .required(missingRequired)
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab),

    mInitial: Yup.string().matches(
      /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
      alphab
    ),

    lName: Yup.string()
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab)

      .required(missingRequired),

    suffix: Yup.string().matches(
      /^[a-zA-Z0-9.àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
      suffixMsg
    ),
    pPhone: Yup.string()
      .matches(
        /^(\+\d{1,5}\s?)?((\d{3}(\s|-?)?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(x|X)(\s?\d+))?$/,
        "Phone number must only contain digits, use X to separate extension."
    ).nullable(),
  });
};

export const ProfileSchemaWT = (
  missingRequired: any,
  alphab: any,
  suffixMsg: any,
  secondaryAlphab: any,
  secondarySuffix: any
  // secondarySuf: any,
  // secondaryMI:any
) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required(missingRequired)
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab),

    mInitial: Yup.string().matches(
      /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
      alphab
    ),

    lastName: Yup.string()
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, alphab)

      .required(missingRequired),

    suffix: Yup.string().matches(
      /^[a-zA-Z0-9.àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
      suffixMsg
    ),

    primaryPhone: Yup.string()
      .matches(
        /^(\+\d{1,5}\s?)?((\d{3}(\s|-?)?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(x|X)(\s?\d+))?$/,
        "Phone number must only contain digits, use X to separate extension."
    ).nullable(),

    secondaryFirstName: Yup.string().matches(
      /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
      secondaryAlphab
    ),

    secondaryLastName: Yup.string().matches(
      /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
      secondaryAlphab
    ),

    secondaryEmailID: Yup.string()
      .email("Enter a valid email format.")
      .matches(/^[a-zA-Z0-9.!#$%&’*+-/=?^_`{|}~@]+$/, ""),

    secondaryPhone: Yup.string()
      .matches(
        /^(\+\d{1,5}\s?)?((\d{3}(\s|-?)?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(x|X)(\s?\d+))?$/,
        "Phone number must only contain digits, use X to separate extension."
    ).nullable(),

    secondarySuffix: Yup.string()
      .matches(
        /^[a-zA-Z0-9.àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
        secondarySuffix
      )
      .nullable(),

    secondaryMInitial: Yup.string()
      .matches(/^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/, secondaryAlphab)
      .nullable(),
  });
};

export const PasswordResetSchema = (missing: any, mustMatch: any) => {
  return Yup.object().shape({
    currentPwd: Yup.string().required(missing),
    newPwd: Yup.string()
      .required(missing)
      .min(15, "Minimum password length is 15 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-(=)[\]|\\;:~!@#$%^&*])(?=.{8,})[A-Za-z0-9-(=)[\]|\\;:~!@#$%^&*]+$/,
        "Must Contain at least one uppercase, one lowercase, one number and one special case character"
      ),

    reenterNewPwd: Yup.string()
      .oneOf([Yup.ref("newPwd"), null], mustMatch)
      .required(missing),
  });
};

export const UpdateCompanySchema = (missing: any) => {
  return Yup.object().shape({
    companyName: Yup.string().required(missing),
    // .matches(
    //   /^[A-Za-z0-9.',&-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$/,
    //   "The new Company Name must only contain Alphanumeric characters or the following special Characters - , . & ’."
    // )
    city: Yup.string().required(missing),

    state: Yup.string().required(missing),

    // country: Yup.string().required(missing),
  });
};
