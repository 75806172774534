import React, { useState, useEffect, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  InputWithInfoIcon,
  NotEditableInputWithInfoIcon,
} from "../../../../utils/Input";
import axios from "axios";
import Spinner from "../../../Spinner/Spinner";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import { submitStatus } from "../../../../utils/Utils";
import {
  UpdateProfileWrapper,
  InputRow,
  ErrorMsg,
  SubmitContainerWrapper,
  SubmitStatusContainer,
} from "../../Layout/Layout";
import FormControl from "@material-ui/core/FormControl";
import { ProfileSchemaWT } from "../../../../utils/Verification";

interface Props {
  setUpdateUser?: any;
  isCompanyAccount?: boolean;
  getAPI: string;
  postAPI: string;
  appendRole?: boolean;
  roleId?: string;
  showAccountNameInput?: boolean;
}

interface Values {
  accountName: string;
  emailID: string;
  firstName: string;
  lastName: string;
  mInitial: string;
  primaryPhone: string;
  routingId: string;
  secondaryEmailID: string;
  secondaryFirstName: string;
  secondaryLastName: string;
  secondaryMInitial: string;
  secondaryPhone: string;
  secondarySuffix: string;
  suffix: string;
  userID: string;
  company: string;
}

const Update: React.FC<Props> = ({
  setUpdateUser,
  isCompanyAccount,
  getAPI,
  postAPI,
  appendRole,
  roleId,
  showAccountNameInput,
}) => {
  const { t } = useTranslation();
  const [userID, setUserID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [mInitial, setMInitial] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [emailID, setEmailID] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [accountName, setAccountName] = useState("");
  const [routingId, setRoutingId] = useState("");
  const [secondaryFirstName, setSecondaryFirstName] = useState("");
  const [secondaryMInitial, setSecondaryMInitial] = useState("");
  const [secondaryLastName, setSecondaryLastName] = useState("");
  const [secondarySuffix, setSecondarySuffix] = useState("");
  const [secondaryEmailID, setSecondaryEmailID] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [fetchingData, setFetchingData] = useState(true);
  const [apiStatus, setApiStatus] = useState(0);
  const [dataUpdateStatus, setDataUpdateStatus] = useState("");
  const [fetchDataError, setFetchDataError] = useState(false);
  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState(false);
  const [company, setCompany] = useState("");
  

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchData = useCallback(() => {
    axios
      .get(getAPI, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          setFetchDataError(false);

          const {
            emailID,
            firstName,
            lastName,
            middleInitial,
            phone,
            secondaryFname,
            secondaryMinitial,
            secondaryLname,
            secondarySuffix,
            secondaryEmailID,
            secondaryPhone,
            suffix,
            loginUserId,
            routingId,
            accountName,
            company,
          } = res.data;

          setAccountName(accountName);
          setRoutingId(routingId);
          setSuffix(suffix);
          setLastName(lastName);
          setMInitial(middleInitial);
          setUserID(loginUserId);
          setEmailID(emailID);
          setPrimaryPhone(phone);
          setFirstName(firstName);
          setSecondaryFirstName(secondaryFname);
          setSecondaryMInitial(secondaryMinitial);
          setSecondaryPhone(secondaryPhone);
          setSecondaryEmailID(secondaryEmailID);
          setSecondaryLastName(secondaryLname);
          setSecondarySuffix(secondarySuffix);
          setFetchingData(false);
          setCompany(company);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setFetchingData(false);
          setFetchDataErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setFetchDataError(true);
        }
      });
  }, [getAPI]);

  const handleFormSubmit = (values: Values, actions: FormikHelpers<Values>) => {
    setApiStatus(1);
    actions.setSubmitting(true);
    let UserData = new FormData();

	//UserData.append("companyName", values.companyName);
    UserData.append("firstName", values.firstName);
    UserData.append("lastName", values.lastName);
    UserData.append("suffix", values.suffix ? values.suffix : "");
    UserData.append("middleInitial", values.mInitial ? values.mInitial : "");
    UserData.append("emailID", values.emailID);
    UserData.append("phone", values.primaryPhone ? values.primaryPhone : "");

    if (appendRole) {
      UserData.append("role", roleId ? roleId : "");
    }

    UserData.append(
      "secondaryPhone",
      values.secondaryPhone ? values.secondaryPhone : ""
    );
    UserData.append(
      "secondaryFname",
      values.secondaryFirstName ? values.secondaryFirstName : ""
    );
    UserData.append(
      "secondaryEmailID",
      values.secondaryEmailID ? values.secondaryEmailID : ""
    );
    UserData.append(
      "secondaryMinitial",
      values.secondaryMInitial ? values.secondaryMInitial : ""
    );
    UserData.append(
      "secondaryLname",
      values.secondaryLastName ? values.secondaryLastName : ""
    );
    UserData.append(
      "secondarySuffix",
      values.secondarySuffix ? values.secondarySuffix : ""
    );

    UserData.append(
      "languageSelection",
      localStorage.getItem("i18nextLng") as any
    );

    axios
      .post(postAPI, UserData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        if (isMounted.current) {
          if (res.data.status === "SUCCESS") {
            setApiStatus(2);
          } else if (res.data.status === "WARNING") {
            setApiStatus(4);
          } else {
            setApiStatus(3);
          }
          setDataUpdateStatus(res.data.statusMessage);
          actions.setSubmitting(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setDataUpdateStatus(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setApiStatus(3);
          actions.setSubmitting(false);
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [updateSecondaryInfo, setUpdateSecondaryInfo] = useState(false);

  const validateWT = (values: Values) => {
    interface ErrorsType {
      secondaryEmailID?: string;
      secondaryFirstName?: string;
      secondaryLastName?: string;
      // secondarySuffix?: string;
    }

    let errors: ErrorsType = {};

    if (values.secondaryEmailID !== "") {
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = t("Translation.59");

      if (values.secondaryLastName === "")
        errors.secondaryLastName = t("Translation.59");
    }

    if (values.secondaryLastName !== "") {
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = t("Translation.59");
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = t("Translation.59");
    }

    if (values.secondaryFirstName !== "") {
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = t("Translation.59");
      if (values.secondaryLastName === "")
        errors.secondaryLastName = t("Translation.59");
    }

    if (values.secondarySuffix !== "") {
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = t("Translation.59");
      if (values.secondaryLastName === "")
        errors.secondaryLastName = t("Translation.59");
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = t("Translation.59");
    }

    if (values.secondaryMInitial !== "") {
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = t("Translation.59");
      if (values.secondaryLastName === "")
        errors.secondaryLastName = t("Translation.59");
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = t("Translation.59");
    }

    if (values.secondaryPhone !== "") {
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = t("Translation.59");
      if (values.secondaryLastName === "")
        errors.secondaryLastName = t("Translation.59");
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = t("Translation.59");
    }

    return errors;
  };

  if (fetchingData) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    if (fetchDataError) {
      return <Alert severity="error">{fetchDataErrorMsg}</Alert>;
    } else {
      return (
        <>
          <UpdateProfileWrapper>
            <div className="update-account-wrapper">
              <Paper elevation={0} className="update-account-paper">
                <div className="paper-body">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
					  company: company ? company : "",
                      firstName: firstName ? firstName : "",
                      mInitial: mInitial ? mInitial : "",
                      lastName: lastName ? lastName : "",
                      suffix: suffix ? suffix : "",
                      primaryPhone: primaryPhone ? primaryPhone : "",
                      userID: userID ? userID : "",
                      emailID: emailID ? emailID : "",
                      accountName: accountName ? accountName : "",
                      routingId: routingId ? routingId : "",
                      secondaryPhone: secondaryPhone ? secondaryPhone : "",
                      secondaryFirstName: secondaryFirstName
                        ? secondaryFirstName
                        : "",
                      secondaryEmailID: secondaryEmailID
                        ? secondaryEmailID
                        : "",
                      secondaryMInitial: secondaryMInitial
                        ? secondaryMInitial
                        : "",
                      secondarySuffix: secondarySuffix ? secondarySuffix : "",
                      secondaryLastName: secondaryLastName
                        ? secondaryLastName
                        : "",
                    }}
                    onSubmit={handleFormSubmit}
                    validationSchema={() =>
                      ProfileSchemaWT(
                        t("Translation.59"),
                        t("Errors.22"),
                        t("Errors.23"),
                        t("Errors.32"),
                        t("Errors.33")
                      )
                    }
                    validate={validateWT}
                  >
                    {({
                      values,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      errors,
                      touched,
                    }) => {
                      return (
                        <Form>
                          {showAccountNameInput ? (
                            <>
                              <FormControl
                                style={{
                                  width: "100%",
                                  marginBottom: "1.3rem",
                                }}
                              >
                                <InputRow className="input-row-styles">
                                  {/* <p>{t("UpdateProfileWT.42")}</p> */}
                                  <label htmlFor="accountName">
                                    {t("UpdateProfileWT.42")}
                                  </label>
                                  <NotEditableInputWithInfoIcon
                                    id="accountName"
                                    value={values.accountName}
                                  />
                                </InputRow>
                              </FormControl>
                              <br />
                            </>
                          ) : null}

                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              {/* <p>{t("UpdateProfileWT.1")}</p> */}
                              <label htmlFor="routingId">
                                {t("UpdateProfileWT.1")}
                              </label>
                              <NotEditableInputWithInfoIcon
                                value={values.routingId}
                                id="routingId"
                              />
                            </InputRow>
                          </FormControl>

                          <br />
                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              <label htmlFor="userId">
                                {t("UpdateProfileWT.2")}
                              </label>
                              {/* <p>{t("UpdateProfileWT.2")}</p> */}
                              <NotEditableInputWithInfoIcon
                                value={values.userID}
                                id="userId"
                              />
                            </InputRow>
                          </FormControl>
                          <br />
                          
                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              <label htmlFor="company">
                                {t("UpdateProfileWT.47")}
                              </label>
                              {/* <p>{t("UpdateProfileWT.47")}</p> */}
                              <NotEditableInputWithInfoIcon
                                value={values.company}
                                id="company"
                              />
                            </InputRow>
                          </FormControl>
                          <br />
                          
                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              <label htmlFor="firstName">
                                {t("UpdateProfileWT.3")}
                              </label>
                              {/* <p>{t("UpdateProfileWT.3")}</p> */}
                              <div>
                                <InputWithInfoIcon
                                  id="firstName"
                                  value={values.firstName}
                                  name="firstName"
                                  tooltipTxt={t("UpdateProfileWT.5")}
                                  titleTxt={t("UpdateProfileWT.4")}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.firstName && touched.firstName}
                                  maxLength={40}
                                />

                                <ErrorMessage name="firstName">
                                  {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                                </ErrorMessage>
                              </div>
                            </InputRow>
                          </FormControl>

                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              <label htmlFor="mInitial">
                                {t("UpdateProfileWT.6")}
                              </label>
                              {/* <p>{t("UpdateProfileWT.6")}</p> */}
                              <div>
                                <InputWithInfoIcon
                                  id="mInitial"
                                  value={values.mInitial}
                                  tooltipTxt={t("UpdateProfileWT.8")}
                                  titleTxt={t("UpdateProfileWT.7")}
                                  name="mInitial"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={1}
                                  error={errors.mInitial && touched.mInitial}
                                />{" "}
                                <ErrorMessage name="mInitial">
                                  {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                                </ErrorMessage>{" "}
                              </div>
                            </InputRow>
                          </FormControl>
                          <br />
                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              <label htmlFor="lastName">
                                {t("UpdateProfileWT.9")}
                              </label>

                              <div>
                                <InputWithInfoIcon
                                  id="lastName"
                                  value={values.lastName}
                                  tooltipTxt={t("UpdateProfileWT.11")}
                                  titleTxt={t("UpdateProfileWT.10")}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="lastName"
                                  maxLength={45}
                                  error={errors.lastName && touched.lastName}
                                />{" "}
                                <ErrorMessage name="lastName">
                                  {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                                </ErrorMessage>{" "}
                              </div>
                            </InputRow>
                          </FormControl>
                          <br />

                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              <label htmlFor="suffix">
                                {t("UpdateProfileWT.12")}
                              </label>
                              {/* <p>{t("UpdateProfileWT.12")}</p> */}
                              <div>
                                <InputWithInfoIcon
                                  id="suffix"
                                  name="suffix"
                                  tooltipTxt={t("UpdateProfileWT.14")}
                                  titleTxt={t("UpdateProfileWT.13")}
                                  value={values.suffix}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.suffix && touched.suffix}
                                  maxLength={5}
                                />{" "}
                                <ErrorMessage name="suffix">
                                  {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                                </ErrorMessage>{" "}
                              </div>
                            </InputRow>
                          </FormControl>
                          <br />
                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              <label htmlFor="emailId">
                                {t("UpdateProfileWT.15")}
                              </label>
                              {/* <p>{t("UpdateProfileWT.15")}</p> */}
                              <NotEditableInputWithInfoIcon
                                value={values.emailID}
                                id="emailId"
                              />
                            </InputRow>
                          </FormControl>
                          <br />
                          <FormControl
                            style={{ width: "100%", marginBottom: "1.3rem" }}
                          >
                            <InputRow className="input-row-styles">
                              <label htmlFor="primaryPhone">
                                {t("UpdateProfileWT.18")}
                              </label>
                              {/* <p>{t("UpdateProfileWT.18")}</p> */}
                              <div>
                                <InputWithInfoIcon
                                  id="primaryPhone"
                                  name="primaryPhone"
                                  value={values.primaryPhone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={25}
                                  tooltipTxt={t("UpdateProfileWT.46")}
                                  titleTxt={t("UpdateProfileWT.19")}
                                  error={errors.primaryPhone && touched.primaryPhone}
                                />{" "}
                                {errors.primaryPhone && touched.primaryPhone ? 
                                  (<div className="error-container">
                                    <p>{t("Errors.34")}</p>
                                    <p>{t("Errors.35")}</p>
                                    <ul style={{ listStylePosition: "inside" }}>
                                      <li>{t("Errors.36")}</li>
                                      <li>{t("Errors.37")}</li>
                                      <li>{t("Errors.38")}</li>
                                      <li>{t("Errors.39")}</li>
                                    </ul>
                                  </div>
                                  ):(<p className="error-mssg-input-fields error-mssg-input-fields-regular">
                                  {t("Registration.20")}
                                  </p>)}
                              </div>
                            </InputRow>
                          </FormControl>
                          <br />

                          <Accordion
                            onChange={() =>
                              setUpdateSecondaryInfo(!updateSecondaryInfo)
                            }
                            expanded={updateSecondaryInfo}
                          >
                            <AccordionSummary>
                              <IconButton
                                className="expand-optional-button"
                                title={t("UpdateProfileWT.22")}
                              >
                                {updateSecondaryInfo ? (
                                  <RemoveCircleOutlineIcon
                                    style={{ color: "#3f51b5" }}
                                  />
                                ) : (
                                  <AddCircleIcon style={{ color: "#3f51b5" }} />
                                )}
                              </IconButton>
                              <p style={{ fontWeight: 600, marginBottom: 0 }}>
                                {t("UpdateProfileWT.21")}
                              </p>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl style={{ width: "100%" }}>
                                <InputRow className="input-row-styles">
                                  <label htmlFor="secondaryFirstName">
                                    {t("UpdateProfileWT.23")}
                                  </label>
                                  {/* <p>{t("UpdateProfileWT.23")}</p> */}
                                  <div>
                                    <InputWithInfoIcon
                                      id="secondaryFirstName"
                                      tooltipTxt={t("UpdateProfileWT.25")}
                                      titleTxt={t("UpdateProfileWT.24")}
                                      value={values.secondaryFirstName}
                                      name="secondaryFirstName"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      maxLength={40}
                                      error={
                                        errors.secondaryFirstName &&
                                        touched.secondaryFirstName
                                      }
                                    />{" "}
                                    <ErrorMessage name="secondaryFirstName">
                                      {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                                    </ErrorMessage>
                                  </div>
                                </InputRow>
                              </FormControl>
                              <br />
                              <FormControl style={{ width: "100%" }}>
                                <InputRow className="input-row-styles">
                                  <label htmlFor="secondaryMInitial">
                                    {t("UpdateProfileWT.26")}
                                  </label>
                                  {/* {t("UpdateProfileWT.26")} */}
                                  <div>
                                    <InputWithInfoIcon
                                      tooltipTxt={t("UpdateProfileWT.28")}
                                      titleTxt={t("UpdateProfileWT.27")}
                                      name="secondaryMInitial"
                                      id="secondaryMInitial"
                                      value={values.secondaryMInitial}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      maxLength={1}
                                      error={
                                        errors.secondaryMInitial &&
                                        touched.secondaryMInitial
                                      }
                                    />{" "}
                                    <ErrorMessage name="secondaryMInitial">
                                      {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                                    </ErrorMessage>
                                  </div>
                                </InputRow>
                              </FormControl>
                              <br />

                              <FormControl style={{ width: "100%" }}>
                                <InputRow className="input-row-styles">
                                  <label htmlFor="secondaryLastName">
                                    {t("UpdateProfileWT.29")}
                                  </label>
                                  {/* <p>{t("UpdateProfileWT.29")}</p> */}
                                  <div>
                                    <InputWithInfoIcon
                                      tooltipTxt={t("UpdateProfileWT.31")}
                                      titleTxt={t("UpdateProfileWT.30")}
                                      name="secondaryLastName"
                                      id="secondaryLastName"
                                      value={values.secondaryLastName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      maxLength={45}
                                      error={
                                        errors.secondaryLastName &&
                                        touched.secondaryLastName
                                      }
                                    />{" "}
                                    <ErrorMessage name="secondaryLastName">
                                      {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                                    </ErrorMessage>
                                  </div>
                                </InputRow>
                              </FormControl>
                              <br />
                              <FormControl style={{ width: "100%" }}>
                                <InputRow className="input-row-styles">
                                  <label htmlFor="secondarySuffix">
                                    {t("UpdateProfileWT.32")}
                                  </label>
                                  {/* <p>{t("UpdateProfileWT.32")}</p> */}
                                  <div>
                                    <InputWithInfoIcon
                                      tooltipTxt={t("UpdateProfileWT.34")}
                                      titleTxt={t("UpdateProfileWT.33")}
                                      value={values.secondarySuffix}
                                      name="secondarySuffix"
                                      id="secondarySuffix"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      maxLength={5}
                                      error={
                                        errors.secondarySuffix &&
                                        touched.secondarySuffix
                                      }
                                    />{" "}
                                    <ErrorMessage name="secondarySuffix">
                                      {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                                    </ErrorMessage>
                                  </div>
                                </InputRow>
                              </FormControl>
                              <br />
                              <FormControl style={{ width: "100%" }}>
                                <InputRow className="input-row-styles">
                                  <label htmlFor="secondaryEmailID">
                                    {t("UpdateProfileWT.35")}
                                  </label>
                                  {/* <p>{t("UpdateProfileWT.35")}</p> */}
                                  <div>
                                    <InputWithInfoIcon
                                      tooltipTxt={t("UpdateProfileWT.37")}
                                      titleTxt={t("UpdateProfileWT.36")}
                                      name="secondaryEmailID"
                                      id="secondaryEmailID"
                                      value={values.secondaryEmailID}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      maxLength={100}
                                      error={
                                        errors.secondaryEmailID &&
                                        touched.secondaryEmailID
                                      }
                                    />{" "}
                                    {errors.secondaryEmailID &&
                                      touched.secondaryEmailID && (
                                        <div className="error-container">
                                          <p>
                                            <li>{t("Errors.13")}</li>
                                          </p>
                                          <ul
                                            style={{
                                              listStylePosition: "inside",
                                            }}
                                          >
                                            <li>{t("Errors.1")}</li>
                                            <li>{t("Errors.2")}</li>

                                            <li>{t("Errors.3")}</li>
                                            <li>{t("Errors.4")}</li>
                                            <li>{t("Errors.5")}</li>
                                          </ul>
                                          <p>
                                            <li>{t("Errors.6")}</li>
                                          </p>
                                          <ul
                                            style={{
                                              listStylePosition: "inside",
                                            }}
                                          >
                                            <li>{t("Errors.1")}</li>
                                            <li>{t("Errors.2")}</li>
                                            <li>{t("Errors.9")}</li>
                                            <li>{t("Errors.10")}</li>
                                          </ul>
                                          <p>{t("Errors.11")}</p>
                                        </div>
                                      )}
                                  </div>
                                </InputRow>
                              </FormControl>
                              <br />

                              <FormControl style={{ width: "100%" }}>
                                <InputRow className="input-row-styles">
                                  <label htmlFor="secondaryPhone">
                                    {t("UpdateProfileWT.38")}
                                  </label>
                                  {/* <p>{t("UpdateProfileWT.38")}</p> */}
                                  <div>
                                  <InputWithInfoIcon
                                    // name="Secondary Phone Number"
                                    tooltipTxt={t("UpdateProfileWT.46")}
                                    titleTxt={t("UpdateProfileWT.39")}
                                    name="secondaryPhone"
                                    id="secondaryPhone"
                                    value={values.secondaryPhone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    maxLength={25}
                                    error={errors.secondaryPhone && touched.secondaryPhone}
                                  />{" "} 
                                  {errors.secondaryPhone && touched.secondaryPhone ? 
                                    (<div className="error-container">
                                      <p>{t("Errors.40")}</p>
                                      <p>{t("Errors.41")}</p>
                                      <ul style={{ listStylePosition: "inside" }}>
                                        <li>{t("Errors.36")}</li>
                                        <li>{t("Errors.37")}</li>
                                        <li>{t("Errors.38")}</li>
                                        <li>{t("Errors.39")}</li>
                                      </ul>
                                    </div>
                                    ):(<p className="error-mssg-input-fields error-mssg-input-fields-regular">
                                    {t("Registration.20")}
                                  </p>)}
                                </div>
                                </InputRow>
                              </FormControl>
                              <br />
                            </AccordionDetails>
                          </Accordion>
                          <br />

                          <SubmitStatusContainer>
                            {submitStatus(apiStatus, dataUpdateStatus)}
                          </SubmitStatusContainer>

                          <SubmitContainerWrapper>
                            {isCompanyAccount && setUpdateUser ? (
                              <Button
                                variant="outlined"
                                style={{ marginRight: "2rem" }}
                                color="secondary"
                                onClick={setUpdateUser}
                                title={t("UpdateProfileWT.45")}
                              >
                                {t("UpdateProfileWT.44")}
                              </Button>
                            ) : null}
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                              title={t("UpdateProfileWT.43")}
                            >
                              {t("UpdateProfileWT.41")}
                            </Button>
                          </SubmitContainerWrapper>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </Paper>
            </div>
          </UpdateProfileWrapper>
        </>
      );
    }
  }
};

export default Update;
