import React from "react";
import Typography from "@material-ui/core/Typography";

interface Props {
  firstLine: any;
  secondLine?: any;
}

const FormHeader: React.FC<Props> = ({ firstLine, secondLine }) => {
  return (
    <div className="paper-header">
      {/* <p>{firstLine}</p> */}
      <Typography variant="h6" component="h1">
        {firstLine}
      </Typography>

      {secondLine ? (
        <Typography variant="h6" component="h2" style={{ fontWeight: 600 }}>
          {secondLine}
        </Typography>
      ) : null}

      {/* <p style={{ fontWeight: 200 }}>{secondLine ? secondLine : null}</p> */}
    </div>
  );
};

export default FormHeader;
