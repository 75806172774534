import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import { detectIE } from "../../../../utils/checkIE";
import { TooltipContainer, TooltipWithContent } from "../../../../utils/Utils";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import Field from "../../../Registration/UserDataInput/UserInputField/Field";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Spinner from "../../../Spinner/Spinner";
import { Formik, ErrorMessage, Form as FormikForm } from "formik";
import { PasswordSchema } from "../../../../utils/Verification";
import { Base64 } from "js-base64";
import { submitStatus } from "../../../../utils/Utils";
import FormLabel from "@material-ui/core/FormLabel";

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RequestWTAccountContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 30px;
`;

const CertifySubmitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CertifyCheckbox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  /* width: 65%; */
`;

const ErrorMsg = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #c60000;
`;

const TitleAndInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;
const DocTitle = styled.p`
  font-weight: bold;
  color: black;
  margin-right: 10px;
  margin-bottom: 0;
`;
const OptionalContainer = styled.div`
  margin-top: 4px;
  margin-left: 14px;
  margin-right: 14px;
`;
const OptionalNote = styled.p`
  color: rgba(0, 0, 0, 0.54);
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin: 0;
  font-size: 0.75rem;
`;

const RequestWT: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const [submitStatusAPI, setSubmitStatusAPI] = useState(0);
  const [dataUpdateStatus, setDataUpdateStatus] = useState("");
  const [certLoader, setCertLoader] = useState(false);
  const [publicCertificate, setPublicCertificate] = useState("");
  const [publicCertFileName, setPublicCertFileName] = useState("");

  const [submitCallDisable, setSubmitCallDisable] = useState(false);

  const [submittingCertificateErr, setSubmittingCertificateErr] =
    useState(false);
  const [submittingCertificateErrMsg, setSubmittingCertificateErrMsg] =
    useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCertificateRadioChange = (e: any, setFieldValue: any) => {
    // e.preventDefault();
    let createdByPortal = e.target.value === "attach" ? true : false;

    if (e.target.value === "" || e.target.value === "attach") {
      setFieldValue("certificateStatus", e.target.value);

      setFieldValue("shouldSystemCreateCertificate", !createdByPortal);
    }

    if (createdByPortal === false) {
      setFieldValue("certificate", null);
      setFieldValue(
        "certificateName",
        localStorage.getItem("i18nextLng") === "fr"
          ? "Joindre un fichier…"
          : "Attach a file..."
      );
    }

    if (!createdByPortal) {
      handleClickOpen();
    }
  };

  const nonRepudiationHandler = (e: any, setFieldValue: any) => {
    setFieldValue("nrLetter", e.target.files[0]);
    setFieldValue("nrLetterName", e.target.files[0].name);

    if (!detectIE()) {
      e.target.value = "";
    }
  };

  const uploadCertificateHandler = (e: any, setFieldValue: any) => {
    let name = e.target.files[0].name;

    let ext = name?.slice(name.length - 4, name.length);

    if (ext !== ".cer" && ext !== ".p7b" && ext !== ".p7c") {
      setFieldValue("certificateExtError", true);

      setFieldValue("certificate", null);
      setFieldValue(
        "certificateName",
        localStorage.getItem("i18nextLng") === "fr"
          ? "Joindre un fichier…"
          : "Attach a file..."
      );
    } else {
      setFieldValue("certificate", e.target.files[0]);
      setFieldValue("certificateName", e.target.files[0].name);

      setFieldValue("certificateExtError", false);
    }

    if (!detectIE()) {
      e.target.value = "";
    }
  };

  const authorizationLetterHandler = (e: any, setFieldValue: any) => {
    setFieldValue("authLetter", e.target.files[0]);
    setFieldValue("authLetterName", e.target.files[0].name);

    if (!detectIE()) {
      e.target.value = "";
    }
  };

  const handleNRLRadioChange = (e: any, setFieldValue: any) => {
    // e.preventDefault();
    setFieldValue("nrlStatus", e.target.value);

    let attachNRL = e.target.value === "attach" ? true : false;
    setFieldValue("existingNRL", !attachNRL);

    if (attachNRL === false) {
      setFieldValue("nrLetter", null);
      setFieldValue(
        "nrLetterName",
        localStorage.getItem("i18nextLng") === "fr"
          ? "Joindre un fichier…"
          : "Attach a file..."
      );
    }
  };

  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [middleInitial, setMiddleInitial] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  //TODO make sure these stuff are attached.
  const [issuer, setIssuer] = useState("");
  const [emailID, setEmailID] = useState("");
  const [name, setName] = useState("");

  const [fetchingData, setFetchingData] = useState(true);
  const [fetchDataError, setFetchDataError] = useState(false);
  const [fetchDataErrorMsg, setFetchDataErrorMsg] = useState(false);

  let isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    axios
      .get(
        `/api/getAccountCertData?userid=${sessionStorage.getItem(
          "userID"
        )}&lang=${localStorage.getItem("i18nextLng")}`,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (isMounted.current) {
          setFetchDataError(false);
          setFetchingData(false);
          const {
            emailID,
            firstName,
            lastName,
            middleInitial,
            phone,
            country,
            suffix,
            city,
            state,
            company,
            issuer,
            name,
          } = res.data;

          setCountry(country);
          setCity(city);
          setState(state);
          setFirstName(firstName);
          setLastName(lastName);
          setSuffix(suffix);
          setMiddleInitial(middleInitial);
          setCompany(company);
          setPhone(phone);
          setIssuer(issuer);
          setEmailID(emailID);

          setName(name);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          //TODO

          setFetchingData(false);
          setFetchDataErrorMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setFetchDataError(true);
        }
      });
  }, []);

  const handleCertificatePopupSubmit = (
    vals: any,
    setFieldValue: any,
    resetForm: any
  ) => {
    function urltoFile(url: any, filename: any, mimeType: any) {
      return fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, { type: mimeType });
        });
    }

    let CertificateData = new FormData();

    CertificateData.append("firstName", firstName);
    CertificateData.append("lastName", lastName);
    CertificateData.append("suffix", suffix);
    CertificateData.append("middleInitial", middleInitial);
    CertificateData.append("emailID", emailID);
    CertificateData.append("city", city);
    CertificateData.append("company", company);
    CertificateData.append("state", state);
    CertificateData.append("country", country);
    CertificateData.append("phone", phone);
    CertificateData.append("certificatePassword", vals.newPwd);
    CertificateData.append(
      "languageSelection",
      localStorage.getItem("i18nextLng") as any
    );
    setCertLoader(true);

    setSubmittingCertificateErr(false);
    //Uncomment this on server
    axios
      .post("/api/getcertificate", CertificateData)
      .then(function (res) {
        if (isMounted.current) {
          const data = res.data;

          setSubmittingCertificateErr(false);

          setPublicCertificate(data.publicCertificate);
          setPublicCertFileName(data.publicCertFileName);

          setFieldValue("certificateStatus", "not-attach");
          setFieldValue("shouldSystemCreateCertificate", true);

          if (detectIE()) {
            let wn: any = window.navigator;
            wn.msSaveOrOpenBlob(
              new Blob([Base64.toUint8Array(data.privateKeyStore)], {
                type: "text/plain",
              }),
              data.privateKeyFilename
            );
            setCertLoader(false);
            if (!certLoader) {
              setOpen(false);
            }
          } else {
            urltoFile(
              `data:text/plain;base64,${data.privateKeyStore}`,
              `${data.privateKeyFilename}`,
              "text/plain"
            ).then(function (file) {
              var link = document.createElement("a");
              // var file = element.artifact;

              link.download = file.name;
              link.href = URL.createObjectURL(file);
              link.click();
              if (!certLoader) {
                setOpen(false);
              }

              setCertLoader(false);
            });
          }
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          //TODO need to add handler
          resetForm();
          setCertLoader(false);

          setSubmittingCertificateErr(true);
          setSubmittingCertificateErrMsg(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
        }
      });
  };

  const submitAPI = (values: any, resetForm: any) => {
    let UserData = new FormData();

    if (values.certificate) {
      UserData.append("certificateFile", values.certificate as any);
    }

    if (values.nrLetter) {
      UserData.append("nrlFile", values.nrLetter as any);
    }

    if (values.authLetter) {
      UserData.append("authLetterFile", values.authLetter as any);
    }

    UserData.append(
      "shouldSystemCreateCertificate",
      values.shouldSystemCreateCertificate as any
    );
    UserData.append("existingNRL", values.existingNRL as any);
    //TODO fix
    if (values.shouldSystemCreateCertificate) {
      UserData.append("certificatePassword", values.newPwd);
      UserData.append("publicCertificate", publicCertificate);
      UserData.append("publicCertFileName", publicCertFileName);
    }
    // UserData.append("emailID", emailID);
    // UserData.append("phone", primaryPhone);

    // if (appendRole) {
    //   UserData.append("role", roleId);
    // }

    UserData.append(
      "languageSelection",
      localStorage.getItem("i18nextLng") as any
    );

    setSubmitStatusAPI(1);

    setSubmitCallDisable(true);

    axios
      .post(
        `/api/reqWTForPURole?userid=${sessionStorage.getItem(
          "userID"
        )}&lang=${localStorage.getItem("i18nextLng")}`,
        UserData,
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (isMounted.current) {
          // if (res.data.status === "SUCCESS") {
          //   setSubmitStatusAPI(2);

          // } else {
          //   setSubmitStatusAPI(3);
          // }

          if (res.data.status === "SUCCESS") {
            setSubmitStatusAPI(2);
          } else if (res.data.status === "WARNING") {
            setSubmitStatusAPI(4);
          } else {
            setSubmitStatusAPI(3);
          }

          setDataUpdateStatus(res.data.statusMessage);
          resetForm();
          setSubmitCallDisable(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          // console.log(err.message);
          // setDataUpdateStatus(err.message);
          setDataUpdateStatus(
            err.response && err.response.data && err.response.data.statusMessage
              ? err.response.data.statusMessage
              : "Exception occurred while processing your request. Please try again. If you continue to get this problem please contact ESG Help Desk."
          );
          setSubmitStatusAPI(3);
          setSubmitCallDisable(false);
          resetForm();
        }
      });
  };

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    setFieldValue("checked", event.target.checked);
  };

  if (fetchingData) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    if (fetchDataError) {
      return <Alert severity="error">{fetchDataErrorMsg}</Alert>;
    } else {
      //TODO make sure thanslation is correct and use correct object for i18, not Registration object.
      return (
        <>
          <RequestWTAccountContainer>
            <div className="paper-body">
              <Formik
                initialValues={{
                  authLetter: null,
                  authLetterName:
                    localStorage.getItem("i18nextLng") === "fr"
                      ? "Joindre un fichier…"
                      : "Attach a file...",

                  certificate: null,
                  certificateName:
                    localStorage.getItem("i18nextLng") === "fr"
                      ? "Joindre un fichier…"
                      : "Attach a file...",
                  certificateStatus: "",

                  certificateExtError: false,

                  nrLetter: null,
                  nrLetterName:
                    localStorage.getItem("i18nextLng") === "fr"
                      ? "Joindre un fichier…"
                      : "Attach a file...",
                  nrlStatus: "",

                  checked: false,

                  existingNRL: false,
                  shouldSystemCreateCertificate: false,

                  newPwd: "",
                  reenterNewPwd: "",

                  // isCertificateCreatedByESG: false,
                }}
                enableReinitialize={true}
                onSubmit={submitAPI}
                validationSchema={() =>
                  PasswordSchema(t("Translation.59"), t("Errors.24"))
                }
              >
                {({
                  values,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <FormikForm>
                      <div className="nrl-upload">
                        <FormControl
                          component="fieldset"
                          style={{ width: "100%" }}
                        >
                          {/* <TitleAndInfoContainer> */}
                          <FormLabel
                            component="legend"
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            {/* <DocTitle>{t("RequestWT.2")}</DocTitle> */}
                            {t("RequestWT.2")}{" "}
                            <TooltipWithContent>
                              <p>
                                {t("Tooltips.61")}
                                <a
                                  href="https://www.fda.gov/industry/about-esg/appendix-g-letters-non-repudiation-agreement"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("Tooltips.62")}
                                </a>
                                {t("Tooltips.63")}
                              </p>
                            </TooltipWithContent>
                          </FormLabel>
                          {/* </TitleAndInfoContainer> */}

                          <RadioGroup
                            value={values.nrlStatus}
                            onChange={(e) =>
                              handleNRLRadioChange(e, setFieldValue)
                            }
                          >
                            <FormControlLabel
                              value="attach"
                              title={t("RequestWT.5")}
                              control={<Radio color="primary" />}
                              label={t("Registration.32")}
                              className="radio-choices"
                            />
                            {values.nrlStatus.localeCompare("attach") === 0 ? (
                              <>
                                <Form.File
                                  style={{ marginBottom: "1rem" }}
                                  title={t("Translation.71")}
                                  id="nrl-upload"
                                  label={values.nrLetterName}
                                  data-browse={
                                    localStorage.getItem("i18nextLng") === "fr"
                                      ? "Explorer"
                                      : "Browse"
                                  }
                                  custom
                                  onChange={(e: any) =>
                                    nonRepudiationHandler(e, setFieldValue)
                                  }
                                  // isInvalid={NRLUploadError}
                                />
                              </>
                            ) : null}
                            <FormControlLabel
                              value="not-attach"
                              title={t("RequestWT.8")}
                              control={<Radio color="primary" />}
                              className="radio-choices"
                              label={t("Registration.33")}
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <br />
                      <div className="certificate-upload">
                        <FormControl
                          component="fieldset"
                          // error={certificateError}
                          style={{ width: "100%" }}
                        >
                          {/* <TitleAndInfoContainer> */}
                          <FormLabel
                            component="legend"
                            style={{ fontWeight: "bold", color: "black" }}
                          >
                            {t("RequestWT.12")}{" "}
                            <TooltipWithContent>
                              <p>
                                {t("Tooltips.64")}
                                <a
                                  href="https://www.fda.gov/industry/about-esg/esg-appendix-c-digital-certificates"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {t("Tooltips.65")}
                                </a>{" "}
                                {t("Tooltips.66")}
                              </p>
                            </TooltipWithContent>
                          </FormLabel>
                          {/* </TitleAndInfoContainer> */}

                          <RadioGroup
                            value={values.certificateStatus}
                            onChange={(e) =>
                              handleCertificateRadioChange(e, setFieldValue)
                            }
                          >
                            <FormControlLabel
                              value="attach"
                              title={t("RequestWT.15")}
                              control={<Radio color="primary" />}
                              label={t("Registration.35")}
                              className="radio-choices"
                            />
                            {values.certificateStatus.localeCompare(
                              "attach"
                            ) === 0 ? (
                              <>
                                <p
                                  style={
                                    values.certificateExtError
                                      ? { color: "#dc3545", fontSize: "0.9rem" }
                                      : {
                                          color: "rgba(0, 0, 0, 0.54)",
                                          fontSize: "0.9rem",
                                        }
                                  }
                                >
                                  {t("RequestWT.16")}
                                </p>

                                <Form.File
                                  id="certificate-upload"
                                  title={t("Translation.71")}
                                  label={values.certificateName}
                                  custom
                                  accept=".cer, .p7b, .p7c"
                                  onChange={(e: any) =>
                                    uploadCertificateHandler(e, setFieldValue)
                                  }
                                  isInvalid={values.certificateExtError}
                                  data-browse={
                                    localStorage.getItem("i18nextLng") === "fr"
                                      ? "Explorer"
                                      : "Browse"
                                  }
                                />
                              </>
                            ) : null}
                            <FormControlLabel
                              value="not-attach"
                              title={t("RequestWT.19")}
                              control={<Radio color="primary" />}
                              label={t("Registration.36")}
                              className="radio-choices"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="xl"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {t("CreateCertificatePopup.9")}
                        </DialogTitle>

                        <DialogContent>
                          <DialogContentText
                            className="create-certificate-desc"
                            id="alert-dialog-description"
                          >
                            {t("CreateCertificatePopup.1")}
                          </DialogContentText>

                          {submittingCertificateErr && (
                            <Alert severity="error">
                              {submittingCertificateErrMsg}
                            </Alert>
                          )}
                          <br />

                          <div className="certificate-password-field">
                            <label htmlFor="newPwd-requestwt">
                              {t("CreateCertificatePopup.2")}:
                            </label>

                            <div className="certificate-password-input-wrapper">
                              <Field
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="newPwd"
                                id="newPwd-requestwt"
                                value={values.newPwd}
                                error={errors.newPwd && touched.newPwd}
                                // errorStatus={certificatePasswordError}
                                // label={t("Registration.4")}
                                titleTxt={t("CreateCertificatePopup.3")}
                                tooltipTxt={t("CreateCertificatePopup.4")}
                                inputType="password"
                              />

                              {errors.newPwd && touched.newPwd && (
                                <div className="error-container">
                                  <p>{t("Errors.14")}</p>
                                  <ul style={{ listStylePosition: "inside" }}>
                                    <li>{t("Errors.15")}</li>
                                    <li>{t("Errors.16")}</li>
                                    <li>{t("Errors.17")}</li>
                                    <li>{t("Errors.18")}</li>
                                    <li> {t("Errors.19")}</li>
                                    <li> {t("Errors.20")}</li>
                                    <li>{t("Errors.21")}</li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="certificate-password-field">
                            <label htmlFor="reenterNewPwd-requestwt">
                              {t("CreateCertificatePopup.5")}:
                            </label>
                            <div className="certificate-password-input-wrapper">
                              <Field
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="reenterNewPwd"
                                id="reenterNewPwd-requestwt"
                                value={values.reenterNewPwd}
                                error={
                                  errors.reenterNewPwd && touched.reenterNewPwd
                                }
                                // label={t("Registration.4")}
                                titleTxt={t("CreateCertificatePopup.6")}
                                tooltipTxt={t("CreateCertificatePopup.7")}
                                inputType="password"
                              />

                              <ErrorMessage name="reenterNewPwd">
                                {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                              </ErrorMessage>
                            </div>
                          </div>
                        </DialogContent>
                        <div className="certificate-bottom-buttons">
                          <Button
                            onClick={handleClose}
                            color="secondary"
                            variant="outlined"
                            title={t("CreateCertificatePopup.11")}
                          >
                            {t("CreateCertificatePopup.10")}
                          </Button>

                          <SubmitContainer>
                            {certLoader && <Spinner />}
                            <Button
                              onClick={() =>
                                handleCertificatePopupSubmit(
                                  values,
                                  setFieldValue,
                                  resetForm
                                )
                              }
                              disabled={
                                !values.newPwd ||
                                !values.reenterNewPwd ||
                                errors.newPwd !== undefined ||
                                errors.reenterNewPwd !== undefined
                                // errors.newPwd ||
                                // errors.reenterNewPwd
                              }
                              // type="submit"
                              // onClick={handleCertificatePopupSubmit}
                              color="primary"
                              variant="contained"
                              title={t("CreateCertificatePopup.13")}
                            >
                              {t("CreateCertificatePopup.12")}
                            </Button>
                          </SubmitContainer>
                        </div>
                      </Dialog>
                      <br />
                      <div className="authorization-upload">
                        {/* <TitleAndInfoContainer> */}
                        <FormLabel
                          component="legend"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          {t("RequestWT.20")}

                          <TooltipWithContent>
                            <p>
                              {t("Tooltips.67")}
                              <a
                                href="https://www.fda.gov/industry/about-esg/appendix-k-sample-authorization-letter"
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                {t("Tooltips.68")}
                              </a>{" "}
                              {t("Tooltips.69")}
                            </p>
                          </TooltipWithContent>
                        </FormLabel>
                        {/* </TitleAndInfoContainer> */}

                        <Form.File
                          id="authorization-letter-upload"
                          label={values.authLetterName}
                          title={t("Translation.71")}
                          custom
                          onChange={(e: any) =>
                            authorizationLetterHandler(e, setFieldValue)
                          }
                          data-browse={
                            localStorage.getItem("i18nextLng") === "fr"
                              ? "Explorer"
                              : "Browse"
                          }
                        ></Form.File>

                        <OptionalContainer>
                          <OptionalNote>{t("RequestWT.23")}</OptionalNote>
                          {/* <p className="authorization-optional-note">
                        {t("RequestWT.23")}
                      </p> */}
                        </OptionalContainer>
                      </div>
                      <br /> <br /> <br />
                      <CertifyCheckbox>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name="checked"
                              checked={values.checked}
                              onChange={(e) =>
                                handleChangeCheckbox(e, setFieldValue)
                              }
                            />
                          }
                          label={t("RequestWT.27")}
                          title={t("RequestWT.28")}
                        />

                        <div className="tooltip-icon-info">
                          <TooltipContainer txt={t("RequestWT.29")} />
                        </div>
                      </CertifyCheckbox>
                      <br />
                      <br />
                      <CertifySubmitContainer>
                        <div>
                          {submitStatus(submitStatusAPI, dataUpdateStatus)}
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          title={t("RequestWT.34")}
                          // type="submit"
                          disabled={
                            !values.checked ||
                            values.nrlStatus === "" ||
                            (values.nrlStatus === "not-attach"
                              ? false
                              : values.nrLetter === null) ||
                            values.certificate === "" ||
                            (values.certificateStatus === "attach"
                              ? values.certificate === null
                              : !values.shouldSystemCreateCertificate) ||
                            submitCallDisable
                          }
                          onClick={() => submitAPI(values, resetForm)}
                        >
                          {t("RequestWT.33")}
                        </Button>
                      </CertifySubmitContainer>
                    </FormikForm>
                  );
                }}
              </Formik>
            </div>
          </RequestWTAccountContainer>
        </>
      );
    }
  }
};

export default RequestWT;
