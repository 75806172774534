import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormFooter from "../FormFooter/FormFooter";
import FormHeader from "../FormHeader/FormHeader";
import { TooltipWithContent } from "../../../utils/Utils";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

interface Props {
  setPage: any;
  setFieldValue: any;
  setRole: any;
  resetForm: any;
  role: any;
}

const SelectUser: React.FC<Props> = ({
  setPage,
  setFieldValue,
  setRole,
  resetForm,
  role,
}) => {
  useEffect(() => {
    sessionStorage.setItem("authorized", "false");
    sessionStorage.setItem("user", "0");
  }, []);

  const { t } = useTranslation();

  const [disbale, setDisable] = useState(true);

  const continueHandler = () => {
    setPage(2);
  };
  const handleRadioChange = (e: any) => {
    resetForm();
    setFieldValue("role", e.currentTarget.value);
    setDisable(false);
    setRole(e.currentTarget.value);
  };

  useEffect(() => {
    if (role) {
      setDisable(false);
    }
  }, [role]);

  return (
    <React.Fragment>
      <div className="registration-wrapper" style={{ marginTop: "200px" }}>
        <Paper
          elevation={3}
          className="registration-screen-select-role"
          style={{ width: "550px" }}
        >
          <FormHeader firstLine={t("Registration.15")} />

          <div className="paper-body">
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {" "}
                <p
                  style={{
                    fontWeight: 700,
                    marginBottom: "1rem",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {t("Registration.14")}
                </p>
              </FormLabel>
              <RadioGroup
                aria-label="select a role"
                name="role"
                value={role}
                onChange={handleRadioChange}
              >
                <div className="web-trader-option">
                  <FormControlLabel
                    value="webtrader"
                    control={<Radio color="primary" />}
                    label={t("Registration.16")}
                    title={t("Registration.62")}
                  />

                  <div className="tooltip-icon-info-select-role">
                    <TooltipWithContent>
                      <p>
                        {t("Tooltips.16")}
                        <a
                          href="https://www.fda.gov/industry/electronic-submissions-gateway/create-esg-account"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          {t("Tooltips.17")}
                        </a>
                      </p>
                    </TooltipWithContent>
                  </div>
                </div>
                <div className="power-user-option">
                  <FormControlLabel
                    value="poweruser"
                    control={<Radio color="primary" />}
                    label={t("Registration.17")}
                    title={t("Registration.63")}
                  />{" "}
                  <div className="tooltip-icon-info-select-role">
                    <TooltipWithContent>
                      <p>{t("Tooltips.13")}</p>
                    </TooltipWithContent>
                  </div>
                </div>{" "}
              </RadioGroup>
            </FormControl>
            <FormFooter
              continueHandler={continueHandler}
              previousHandler={null}
              previousOption={false}
              disbale={disbale}
            />
          </div>
        </Paper>
      </div>
    </React.Fragment>
  );
};

export default SelectUser;
