import React from "react";
import Form from "react-bootstrap/Form";

interface Props {
  name: any;
  userInput: any;
  firstInput?: any;
}

const Row: React.FC<Props> = ({ name, userInput, firstInput }) => {
  let id = `${name}-submit`.replace(/\s+/g, "");

  return (
    <div
      className={`user-input-row-submit-page ${
        firstInput ? "first-input-margin" : ""
      }`}
    >
      <label htmlFor={id}>{name}:</label>
      {/* <p>{name}</p> */}

      <Form.Group>
        <Form.Control type="text" disabled={true} value={userInput} id={id} />
      </Form.Group>
    </div>
  );
};

export default Row;
