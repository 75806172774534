import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/es/map";
import "core-js/es/set";
import "core-js/es/array";
import "core-js/es/symbol";
import "core-js/es/object";
import "core-js/es/function";
import "core-js/es/parse-int";
import "core-js/es/parse-float";
import "core-js/es/number";
import "core-js/es/math";
import "core-js/es/string";
import "core-js/es/date";
import "core-js/es/regexp";
import "core-js/es/weak-map";
import "core-js/stable/string/repeat";
import "core-js/features/string/repeat";
import "core-js/es/string/repeat";
import "core-js";
import "core-js/stable";
import "regenerator-runtime/runtime";

import React, { Suspense } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
// import i18n (needs to be bundled ;))
import "./i18n";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#c60000", //Another orange-ish color
    },
    error: {
      main: "#c60000",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading</div>}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
