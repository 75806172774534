import React, { FocusEvent } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const InputArea = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface EditableInputProps {
  name: string;
  value: string;
  tooltipTxt?: string;
  titleTxt?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: any;
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  label?: string;
  type?: string;
  autoComplete?: string;
  maxLength?: number;
  id?: string;
}

export const InputWithInfoIcon: React.FC<EditableInputProps> = ({
  name,
  value,
  tooltipTxt,
  titleTxt,
  onChange,
  error,
  onBlur,
  label,
  type = "text",
  autoComplete,
  maxLength = null,
  id,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const renderTooltip = (props: any) => (
    <Tooltip
      id="button-tooltip"
      {...props}
      onMouseLeave={() => setShowTooltip(false)}
      onMouseEnter={() => setShowTooltip(true)}
    >
      {tooltipTxt}
    </Tooltip>
  );

  return (
    <InputWrapper>
      {/* <InputLabel htmlFor="input-text">Email address</InputLabel> */}
      <TextField
        id={id}
        name={name}
        autoComplete={autoComplete}
        onChange={onChange}
        value={value}
        variant="outlined"
        size="small"
        title={titleTxt}
        error={error}
        onBlur={onBlur}
        type={type}
        label={label}
        style={{ width: "100%" }}
        inputProps={{ maxLength: maxLength }}
      />

      <div className="tooltip-icon-info">
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
          show={showTooltip}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            // data-tip={tooltipTxt}
          />
        </OverlayTrigger>
      </div>
    </InputWrapper>
  );
};

interface NotEditableProps {
  value: string;
  id: string;
}

export const NotEditableInputWithInfoIcon: React.FC<NotEditableProps> = ({
  value,
  id,
}) => {
  return (
    <InputArea>
      <Form.Group style={{ width: "100%", marginBottom: 0 }}>
        <Form.Control type="text" disabled={true} value={value} id={id} />
      </Form.Group>

      <div className="tooltip-icon-info">
        <div style={{ width: "1em" }}></div>
      </div>
    </InputArea>
  );
};
