import React from "react";

const Spinner: React.FC<any> = ({ width = "51px", height = "51px" }) => {
  return (
    <div>
      <img
        src="img/Rolling-1s-51px.gif"
        alt="Loading..."
        style={{ display: "block", width: width, height: height }}
      />
    </div>
  );
};

export default Spinner;
