import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import SearchForAccounts from "./SearchForAccounts";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import UpdateF from "../UpdateProfile/UpdateF";
import UpdateP from "../UpdateProfile/UpdateP";
import Certificate from "./Certificate";
import Authorization from "../AddAuth/AddAuth";
import Nonrepudiation from "../AddNRL/AddNRL";
import EnableDisable from "./EnableDisable";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

interface Props {
  getAPIProfile: string;
  postAPIProfile: string;
  postAPINRL: string;
  getAPINRL: string;
  getAPIAUTH: string;
  postAPIAUTH: string;
  postAPICompanySearch: string;
  postAPICERTIFICATE: string;
  postAPIEnableDisable: string;
  getAPIEnableDisable: string;
  setSelectedNodeText: any;
}

const Update: React.FC<Props> = ({
  getAPIProfile,
  postAPIProfile,
  postAPINRL,
  getAPINRL,
  getAPIAUTH,
  postAPIAUTH,
  postAPICompanySearch,
  postAPICERTIFICATE,
  postAPIEnableDisable,
  getAPIEnableDisable,
  setSelectedNodeText,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [updateUser, setUpdateUser] = useState<boolean>(false);

  React.useEffect(() => {
    if (!updateUser) setSelectedNodeText("Update Company Accounts");
  }, [updateUser]);

  const [value, setValue] = React.useState(0);

  const [isWT, setIsWT] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState("");

  const [roleId, setRoleId] = useState(0);

  const [rows, setRows] = useState<any>(null);

  const handleChange = (event: any, newValue: any) => {
    // console.log("clicked");
    setValue(newValue);
  };

  const goBackToSearch = () => {
    setValue(0);
    setUpdateUser(false);
  };

  return (
    <div>
      <Paper elevation={0}>
        {/* <OptionHeader>
          <p>Update Company Accounts : Assyst - JD</p>
        </OptionHeader> */}

        <div className="paper-body pu-update-company-accounts">
          {!updateUser ? (
            <SearchForAccounts
              setUpdateUser={setUpdateUser}
              setIsWT={setIsWT}
              setSelectedUserId={setSelectedUserId}
              setRoleId={setRoleId}
              postAPICompanySearch={postAPICompanySearch}
              rows={rows}
              setRows={setRows}
              setSelectedNodeText={setSelectedNodeText}
            />
          ) : (
            <>
              <div className={classes.root}>
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                  >
                    <Tab
                      // style={{ color: "blue" }}
                      label={t("Translation.70")}
                      {...a11yProps(0)}
                    />
                    {isWT && (
                      <Tab
                        label={t("BreadCrumbsAndNav.21")}
                        {...a11yProps(1)}
                      />
                    )}
                    {isWT && (
                      <Tab
                        label={t("BreadCrumbsAndNav.22")}
                        {...a11yProps(2)}
                      />
                    )}
                    {isWT && (
                      <Tab
                        label={t("BreadCrumbsAndNav.23")}
                        {...a11yProps(3)}
                      />
                    )}
                    <Tab
                      label={t("BreadCrumbsAndNav.24")}
                      {...a11yProps(isWT ? 4 : 1)}
                    />
                  </Tabs>
                </AppBar>

                <TabPanel value={value} index={0}>
                  {isWT ? (
                    <UpdateF
                      getAPI={`${getAPIProfile}&userid=${selectedUserId}`}
                      postAPI={`${postAPIProfile}&userid=${selectedUserId}`}
                      setUpdateUser={goBackToSearch}
                      isCompanyAccount={true}
                      appendRole={true}
                      roleId={String(roleId)}
                      showAccountNameInput={true}
                    />
                  ) : (
                    <UpdateP
                      setUpdateUser={goBackToSearch}
                      isCompanyAccount={true}
                      getAPI={`${getAPIProfile}&userid=${selectedUserId}`}
                      postAPI={`${postAPIProfile}&userid=${selectedUserId}`}
                      appendRole={true}
                      roleId={String(roleId)}
                      showAccountNameInput={true}
                    />
                  )}
                </TabPanel>
                {isWT && (
                  <TabPanel value={value} index={1}>
                    <Nonrepudiation
                      checkboxText={t("UploadNewNRL.16")}
                      setUpdateUser={goBackToSearch}
                      isCompanyAccount={true}
                      // getAPI={getAPINRL}
                      // postAPI={postAPINRL}
                      getAPI={`${getAPINRL}&userid=${selectedUserId}`}
                      postAPI={`${postAPINRL}&userid=${selectedUserId}`}
                      checkboxTooltip={t("UploadNewNRL.15")}
                      baseDownloadURL={
                        isWT ? "api/downloadLetterFile" : "api/downloadPUFile"
                      }
                    />
                  </TabPanel>
                )}
                {isWT && (
                  <TabPanel value={value} index={2}>
                    <Authorization
                      checkboxTooltip={t("UploadNewAuth.15")}
                      checkboxText={t("UploadNewAuth.16")}
                      setUpdateUser={goBackToSearch}
                      isCompanyAccount={true}
                      // getAPI={getAPIAUTH}
                      // postAPI={postAPIAUTH}
                      getAPI={`${getAPIAUTH}&userid=${selectedUserId}`}
                      postAPI={`${postAPIAUTH}&userid=${selectedUserId}`}
                      baseDownloadURL={
                        isWT ? "api/downloadLetterFile" : "api/downloadPUFile"
                      }
                    />
                  </TabPanel>
                )}
                {isWT && (
                  <TabPanel value={value} index={3}>
                    <Certificate
                      setUpdateUser={goBackToSearch}
                      // postAPI={postAPICERTIFICATE}

                      postAPI={`${postAPICERTIFICATE}&userid=${selectedUserId}`}
                    />
                  </TabPanel>
                )}
                <TabPanel value={value} index={isWT ? 4 : 1}>
                  <EnableDisable
                    setUpdateUser={goBackToSearch}
                    // getAPI={getAPIEnableDisable}
                    // postAPI={postAPIEnableDisable}
                    getAPI={`${getAPIEnableDisable}&userid=${selectedUserId}`}
                    postAPI={`${postAPIEnableDisable}&userid=${selectedUserId}`}
                    roleId={roleId}
                    setRows={setRows}
                    rows={rows}
                    selectedUserId={selectedUserId}
                  />
                </TabPanel>
              </div>
            </>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default Update;
