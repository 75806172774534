import React, { useState } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import Registration from "./components/Registration/Registration";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ForgotuserId from "./components/ForgotUserId/ForgotUserId";
import ResetPWD from "./components/PasswordReset/PasswordReset";
import "./styles/styles.scss";
import { useTranslation } from "react-i18next";
import MenuPU from "./components/Menu/PowerUser/MenuPU";
import MenuFP from "./components/Menu/FullPowerUser/MenuPUWT";
import MenuWT from "./components/Menu/WebTrader/MenuWT";
import authWT from "./service/authWT";
import authPO from "./service/authPO";
import authFP from "./service/authFP";
import useSessionStorageParse from "./service/useSessionStorageParse";
import PageNotFound from "./components/PageNotFound/PageNotFound";

import "./styles/custom.scss";

const AuthorizedWTUser = authWT(MenuWT);
const AuthorizedPOUser = authPO(MenuPU);
const AuthorizedFPUser = authFP(MenuFP);

const App = () => {
  const { t, i18n } = useTranslation();
  const [french, setFrench] = useState(true);
  const [authorizedStatus, setAuthorizedStatus] = useSessionStorageParse(
    false,
    "authorized"
  );

  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [userClickedLogOut, setUserClickedLogOut] = useState(false);
  const [loggedOutMessage, setLoggedOutMessage] = useState("");

  const [openPWDExpire, setOpenPWDExpire] = React.useState(false);
  const [pwdExpireMsg, setPwdExpireMsg] = useState("");

  const handleClickOpenPWDExpire = () => {
    setOpenPWDExpire(true);
  };

  const handleClosePWDExpire = () => {
    setOpenPWDExpire(false);
  };

  // const [userAccountStatus, setUserAccountStatus] = useLocalStorage(
  //   "Pending",
  //   "status"
  // );

  // const handleLanguage = (fr) => {
  //   const lang = fr ? "en" : "fr";
  //   i18n.changeLanguage(lang);
  //   setFrench(fr);
  // };

  const setEnglish = () => {
    i18n.changeLanguage("en");
    setFrench(true);
  };
  const setFrenchLang = () => {
    i18n.changeLanguage("fr");
    setFrench(false);
  };

  // const location = useLocation();

  return (
    <Router>
      <div className="App">
        <Header
          userAuthenticated={userAuthenticated}
          setUserAuthenticated={setUserAuthenticated}
          setLoggedOutMessage={setLoggedOutMessage}
          setUserClickedLogOut={setUserClickedLogOut}
          setAuthorizedStatus={setAuthorizedStatus}
          authorizedStatus={authorizedStatus}
          // userName={userName}
          // userRole={userRole}
        />

        <main>
          <Switch>
            <Route exact path="/">
              <Login
                setAuthorizedStatus={setAuthorizedStatus}
                t={t}
                french={french}
                // handleLanguage={handleLanguage}
                i18n={i18n}
                setFrenchLang={setFrenchLang}
                setEnglish={setEnglish}
                setUserAuthenticated={setUserAuthenticated}
                setUserClickedLogOut={setUserClickedLogOut}
                // setUserName={setUserName}
                // setUserRole={setUserRole}
                handleClickOpenPWDExpire={handleClickOpenPWDExpire}
                setPwdExpireMsg={setPwdExpireMsg}
              />
            </Route>
            {/* ${french ? "?lang=fr" : ""} */}

            <Route exact path={`/register`}>
              {authorizedStatus ? (
                <Redirect to="/" />
              ) : (
                <Registration t={t} i18n={i18n} />
              )}
            </Route>

            <Route exact path="/wt">
              <AuthorizedWTUser
                userClickedLogOut={userClickedLogOut}
                loggedOutMessage={loggedOutMessage}
                setLoggedOutMessage={setLoggedOutMessage}
                setUserClickedLogOut={setUserClickedLogOut}
                setAuthorizedStatus={setAuthorizedStatus}
                setUserAuthenticated={setUserAuthenticated}
                openPWDExpire={openPWDExpire}
                handleClosePWDExpire={handleClosePWDExpire}
                handleClickOpenPWDExpire={handleClickOpenPWDExpire}
                pwdExpireMsg={pwdExpireMsg}
              />
            </Route>
            <Route exact path="/po">
              <AuthorizedPOUser
                userClickedLogOut={userClickedLogOut}
                loggedOutMessage={loggedOutMessage}
                setLoggedOutMessage={setLoggedOutMessage}
                setUserClickedLogOut={setUserClickedLogOut}
                setAuthorizedStatus={setAuthorizedStatus}
                setUserAuthenticated={setUserAuthenticated}
                openPWDExpire={openPWDExpire}
                handleClosePWDExpire={handleClosePWDExpire}
                handleClickOpenPWDExpire={handleClickOpenPWDExpire}
                pwdExpireMsg={pwdExpireMsg}
              />
            </Route>
            <Route exact path="/fp">
              <AuthorizedFPUser
                userClickedLogOut={userClickedLogOut}
                loggedOutMessage={loggedOutMessage}
                setLoggedOutMessage={setLoggedOutMessage}
                setUserClickedLogOut={setUserClickedLogOut}
                setAuthorizedStatus={setAuthorizedStatus}
                setUserAuthenticated={setUserAuthenticated}
                openPWDExpire={openPWDExpire}
                handleClosePWDExpire={handleClosePWDExpire}
                handleClickOpenPWDExpire={handleClickOpenPWDExpire}
                pwdExpireMsg={pwdExpireMsg}
              />
            </Route>

            {/* <Route exact path="/validate-user">
              <ValidateUser />
            </Route> */}

            <Route exact path="/forgot-password">
              {authorizedStatus ? <Redirect to="/" /> : <ForgotPassword />}
            </Route>
            <Route exact path="/forgot-userid">
              {authorizedStatus ? <Redirect to="/" /> : <ForgotuserId />}
            </Route>
            <Route exact path="/reset-pwd">
              {authorizedStatus ? <Redirect to="/" /> : <ResetPWD />}
            </Route>

            <Route>
              <PageNotFound />
            </Route>
          </Switch>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
