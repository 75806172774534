import Link from "@material-ui/core/Link";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { v4 as uuidv4 } from "uuid";

interface Props {
  selectedNode: string;
  setSelectedNode: (param: string) => void;
  setSelectedNodeText: any;
}

export const RenderBreadcrumb: React.FC<Props> = ({
  selectedNode,
  setSelectedNode,
  setSelectedNodeText,
}) => {
  const { t } = useTranslation();
  const breadcrumbsPUWT = [
    [{ crumbName: t("BreadCrumbsAndNav.1"), node: 15 }],
    [{ crumbName: t("BreadCrumbsAndNav.2"), node: 1 }],
    [{ crumbName: t("BreadCrumbsAndNav.5"), node: 2 }],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.6"), node: 3 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.7"), node: 4 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.8"), node: 5 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.8"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.26"), node: 6 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.8"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.27"), node: 7 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.8"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.11"), node: 8 },
    ],
    [{ crumbName: t("BreadCrumbsAndNav.12"), node: 9 }],
    [
      { crumbName: t("BreadCrumbsAndNav.12"), node: 9 },
      { crumbName: t("BreadCrumbsAndNav.13"), node: 10 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.12"), node: 9 },
      { crumbName: t("BreadCrumbsAndNav.26"), node: 11 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.12"), node: 9 },
      { crumbName: t("BreadCrumbsAndNav.27"), node: 12 },
    ],
    [{ crumbName: t("BreadCrumbsAndNav.16"), node: 13 }],
    [{ crumbName: t("BreadCrumbsAndNav.20"), node: 14 }],
  ];

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          color="inherit"
          component="button"
          style={{ color: "#1766AA" }}
          onClick={() => {
            setSelectedNode("15");
            setSelectedNodeText(t("BreadCrumbsAndNav.1"));
          }}
          // style={selected ? { color: "black" } : {}}
        >
          {t("BreadCrumbsAndNav.1")}
        </Link>
        {selectedNode !== "15" &&
          breadcrumbsPUWT[Number(selectedNode)].map((crumb, index, arr) => {
            const selected = index === arr.length - 1;

            return (
              <Link
                key={uuidv4()}
                color="inherit"
                component="button"
                style={
                  selected
                    ? {
                        color: "black",
                        fontWeight: 600,
                        cursor: "auto",
                        textDecoration: "none",
                      }
                    : { color: "#1766AA" }
                }
                onClick={() => {
                  setSelectedNode(String(crumb.node));
                  setSelectedNodeText(crumb.crumbName);
                }}
              >
                {crumb.crumbName}
              </Link>
            );
          })}
      </Breadcrumbs>
    </>
  );
};

export const RenderBreadcrumbPU: React.FC<Props> = ({
  selectedNode,
  setSelectedNode,
  setSelectedNodeText,
}) => {
  const { t } = useTranslation();

  const breadcrumbsPU = [
    [{ crumbName: t("BreadCrumbsAndNav.1"), node: 11 }],
    [{ crumbName: t("BreadCrumbsAndNav.2"), node: 1 }],
    [{ crumbName: t("BreadCrumbsAndNav.5"), node: 2 }],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.6"), node: 3 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.7"), node: 4 },
    ],
    [{ crumbName: t("BreadCrumbsAndNav.12"), node: 5 }],
    [
      { crumbName: t("BreadCrumbsAndNav.12"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.13"), node: 6 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.12"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.9"), node: 7 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.12"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.10"), node: 8 },
    ],
    [{ crumbName: t("BreadCrumbsAndNav.16"), node: 9 }],
    [{ crumbName: t("BreadCrumbsAndNav.18"), node: 10 }],
  ];

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          color="inherit"
          component="button"
          style={{ color: "#1766AA" }}
          onClick={() => {
            setSelectedNode("11");
            setSelectedNodeText(t("BreadCrumbsAndNav.1"));
          }}
          // style={selected ? { color: "black" } : {}}
        >
          {t("BreadCrumbsAndNav.1")}
        </Link>
        {selectedNode !== "11" &&
          breadcrumbsPU[Number(selectedNode)].map((crumb, index, arr) => {
            const selected = index === arr.length - 1;
            return (
              <Link
                key={uuidv4()}
                color="inherit"
                component="button"
                style={
                  selected
                    ? {
                        color: "black",
                        fontWeight: 600,
                        cursor: "auto",
                        textDecoration: "none",
                      }
                    : { color: "#1766AA" }
                }
                onClick={() => {
                  setSelectedNode(String(crumb.node));
                  setSelectedNodeText(crumb.crumbName);
                }}
              >
                {crumb.crumbName}
              </Link>
            );
          })}
      </Breadcrumbs>
    </>
  );
};

export const RenderBreadcrumbWT: React.FC<Props> = ({
  selectedNode,
  setSelectedNode,
  setSelectedNodeText,
}) => {
  const { t } = useTranslation();
  const breadcrumbsWT = [
    [{ crumbName: t("BreadCrumbsAndNav.1"), node: 11 }],
    [{ crumbName: t("BreadCrumbsAndNav.2"), node: 1 }],
    [{ crumbName: t("BreadCrumbsAndNav.5"), node: 2 }],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.6"), node: 3 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.7"), node: 4 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.8"), node: 5 },
    ],

    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.8"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.26"), node: 6 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.8"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.27"), node: 7 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.8"), node: 5 },
      { crumbName: t("BreadCrumbsAndNav.11"), node: 8 },
    ],
    [
      { crumbName: t("BreadCrumbsAndNav.5"), node: 2 },
      { crumbName: t("BreadCrumbsAndNav.17"), node: 9 },
    ],
    [{ crumbName: t("BreadCrumbsAndNav.19"), node: 10 }],
  ];

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        <Link
          color="inherit"
          component="button"
          style={{ color: "#1766AA" }}
          onClick={() => {
            setSelectedNode("11");
            setSelectedNodeText(t("BreadCrumbsAndNav.1"));
          }}
          // style={selected ? { color: "black" } : {}}
        >
          {t("BreadCrumbsAndNav.1")}
        </Link>
        {selectedNode !== "11" &&
          breadcrumbsWT[Number(selectedNode)].map((crumb, index, arr) => {
            const selected = index === arr.length - 1;
            return (
              <Link
                key={uuidv4()}
                color="inherit"
                component="button"
                style={
                  selected
                    ? {
                        color: "black",
                        fontWeight: 600,
                        cursor: "auto",
                        textDecoration: "none",
                      }
                    : { color: "#1766AA" }
                }
                onClick={() => {
                  setSelectedNode(String(crumb.node));
                  setSelectedNodeText(crumb.crumbName);
                }}
              >
                {crumb.crumbName}
              </Link>
            );
          })}
      </Breadcrumbs>
    </>
  );
};
