import React from "react";

import styled from "styled-components";

const CustomPaper = styled.div`
  margin: 0 auto;
  margin-top: 150px;
  width: 570px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 8px;
  background-color: #fff;
`;

const PageNotFound: React.FC = () => {
  return (
    <div>
      <CustomPaper>
        <h4>Page you are looking for doesn't exist!</h4>
      </CustomPaper>
    </div>
  );
};

export default PageNotFound;
