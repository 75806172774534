import React, { useState, useEffect } from "react";
import SelectUser from "./UserDataInput/SelectUser";
import UserInput from "./UserDataInput/UserInput";
import UserInput2 from "./UserDataInput/UserInput2";
import WebTraderInput from "./UserDataInput/WebTraderInput";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Paper from "@material-ui/core/Paper";
import FormHeader from "./FormHeader/FormHeader";
import SubmitPage from "./SubmitPage/SubmitPage";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { PUSchema, WTSchema } from "../../utils/Verification";
import { Formik, Form } from "formik";

interface Props {
  t: any;
  i18n: any;
}

const Registration: React.FC<Props> = ({ t, i18n }) => {
  useEffect(() => {
    sessionStorage.setItem("authorized", "false");
    sessionStorage.setItem("user", "0");
  }, []);

  let history = useHistory();

  let params = queryString.parse(history.location.search);

  useEffect(() => {
    if (params.lang === "fr") {
      i18n.changeLanguage(params.lang);
    }
  }, []);
  const [role, setRole] = useState("");

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  // const handleCheckboxRole = (e) => {
  //   setRole({ ...role, [e.target.name]: e.target.checked });
  // };

  const getSteps = () => {
    return [t("Registration.18"), t("Registration.19"), t("Registration.21")];
  };

  const getStepsPowerUser = () => {
    return [t("Registration.18"), t("Registration.19")];
  };

  const [page, setPage] = useState(1);

  const [authLetterFile, setAuthLetterFile] = useState(null);
  const [certificateFile, setCertificateFile] = useState(null);
  const [nrlFile, setNrlFile] = useState(null);
  const [createCertificate, setCreateCertificate] = useState(false);
  const [existingNRL, setExistingNRL] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const steps = role === "webtrader" ? getSteps() : getStepsPowerUser();

  const [nrlStatus, setNrlStatus] = useState("");
  const [certificate, setCertificate] = useState("");

  const [certificateName, setCertificateName] = useState(
    localStorage.getItem("i18nextLng") === "fr"
      ? "Joindre un fichier…"
      : "Attach a file..."
  );
  const [nrlName, setNrlName] = useState(
    localStorage.getItem("i18nextLng") === "fr"
      ? "Joindre un fichier…"
      : "Attach a file..."
  );
  const [authorizationName, setAuthorizationName] = useState(
    localStorage.getItem("i18nextLng") === "fr"
      ? "Joindre un fichier…"
      : "Attach a file..."
  );

  const [publicCertificate, setPublicCertificate] = useState("");
  const [publicCertFileName, setPublicCertFileName] = useState("");

  const [autoFillDisable, setAutoFillDisable] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmitPagePrevious = (step: any) => {
    setActiveStep(step);
  };

  const renderHeader = (page: any, role: any) => {
    if (page === 2) {
      if (role === "webtrader") {
        return (
          <FormHeader
            firstLine={t("Registration.23")}
            secondLine={t("Registration.24")}
          />
        );
      }
      if (role === "poweruser") {
        return (
          <FormHeader
            firstLine={t("Registration.26")}
            secondLine={t("Registration.24")}
          />
        );
      }
    }

    if (page === 3) {
      if (role === "webtrader") {
        return (
          <FormHeader
            firstLine={t("Registration.23")}
            secondLine={t("Registration.25")}
          />
        );
      }
      if (role === "poweruser") {
        return (
          <FormHeader
            firstLine={t("Registration.26")}
            secondLine={t("Registration.25")}
          />
        );
      }
    }
    return;
  };

  const validateWT = (values: any) => {
    interface ErrorsType {
      secondaryEmailID?: string;
      secondaryFirstName?: string;
      secondaryLastName?: string;
    }

    let errors: ErrorsType = {};

    if (values.secondaryEmailID !== "") {
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = "Field is required.";

      if (values.secondaryLastName === "")
        errors.secondaryLastName = "Field is required.";
    }

    if (values.secondaryLastName !== "") {
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = "Field is required.";
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = "Field is required.";
    }

    if (values.secondaryFirstName !== "") {
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = "Field is required.";
      if (values.secondaryLastName === "")
        errors.secondaryLastName = "Field is required.";
    }

    //           secondaryPhone: "",

    if (values.secondarySuffix !== "") {
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = t("Translation.59");
      if (values.secondaryLastName === "")
        errors.secondaryLastName = t("Translation.59");
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = t("Translation.59");
    }

    if (values.secondaryMInitial !== "") {
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = t("Translation.59");
      if (values.secondaryLastName === "")
        errors.secondaryLastName = t("Translation.59");
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = t("Translation.59");
    }

    if (values.secondaryPhone !== "") {
      if (values.secondaryEmailID === "")
        errors.secondaryEmailID = t("Translation.59");
      if (values.secondaryLastName === "")
        errors.secondaryLastName = t("Translation.59");
      if (values.secondaryFirstName === "")
        errors.secondaryFirstName = t("Translation.59");
    }

    // if (values.file === null) {
    //   errors.file = "File is required";
    // }

    // if (values.checked === false) {
    //   errors.checked = "You must select the checkbox.";
    // }

    return errors;
  };

  const validatePU = (values: any) => {
    interface ErrorsType {}

    let errors: ErrorsType = {};

    return errors;
  };

  return (
    <Formik
      initialValues={
        role === "poweruser"
          ? {
              // fName: firstName,
              // mInitial: mInitial,
              // lName: lastName,
              // suffix: suffix,
              // pPhone: primaryPhone,
              role: null,

              firstName: "",
              lastName: "",
              middleInitial: "",
              suffix: "",
              emailId: "",
              password: "",
              phone: "",

              city: "",
              state: "",
              country: {
                name: "",
              },
              company: {
                companyName: "",
                companyId: "",
              },

              // secondaryFirstName: "",
              // secondaryLastName: "",
              // secondaryMInitial: "",
              // secondarySuffix: "",
              // secondaryEmailID: "",
              // secondaryPhone: "",
              reenterPassword: "",

              //TODO do i really need this????
              continueToStep2: false,
            }
          : {
              role: null,

              firstName: "",
              lastName: "",
              middleInitial: "",
              suffix: "",
              emailId: "",
              password: "",
              phone: "",

              city: "",
              state: "",
              country: {
                name: "",
              },
              company: {
                companyName: "",
                companyId: "",
              },

              secondaryFirstName: "",
              secondaryLastName: "",
              secondaryMInitial: "",
              secondarySuffix: "",
              secondaryEmailID: "",
              secondaryPhone: "",
              reenterPassword: "",
              //If company exist 
              ifExist:false,

              //TODO do i really need this????
              continueToStep2: false,

              // disableCompanyFields: false,
            }
      }
      enableReinitialize={true}
      onSubmit={(values) => {}}
      validationSchema={
        role === "poweruser"
          ? () =>
              PUSchema(
                t("Translation.59"),
                t("Errors.22"),
                t("Errors.23"),
                t("Errors.24")
              )
          : () =>
              WTSchema(
                t("Translation.59"),
                t("Errors.22"),
                t("Errors.23"),
                t("Errors.24"),
                t("Errors.32"),
                t("Errors.33")
              )
      }
      validate={role === "poweruser" ? validatePU : validateWT}
    >
      {({
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        resetForm,
      }) => {

        return (
          <Form>
            {page === 1 ? (
              <SelectUser
                setPage={setPage}
                role={role}
                setFieldValue={setFieldValue}
                setRole={setRole}
                resetForm={resetForm}
              />
            ) : (
              <div className="registration-wrapper">
                {page === 5 ? (
                  <SubmitPage
                    role={role}
                    authLetterFile={authLetterFile}
                    certificateFile={certificateFile}
                    nrlFile={nrlFile}
                    existingNRL={existingNRL}
                    createCertificate={createCertificate}
                    setPage={setPage}
                    handleSubmitPagePrevious={handleSubmitPagePrevious}
                    certificateName={certificateName}
                    nrlName={nrlName}
                    authorizationName={authorizationName}
                    publicCertificate={publicCertificate}
                    publicCertFileName={publicCertFileName}
                    values={values}
                  />
                ) : (
                  <Paper elevation={3} className="registration-screen">
                    {page === 2
                      ? renderHeader(2, role)
                      : page === 3
                      ? renderHeader(3, role)
                      : null}
                    {page === 4 ? (
                      <FormHeader
                        firstLine={t("Registration.23")}
                        secondLine={t("Registration.21")}
                      />
                    ) : null}
                    {page !== 5 ? (
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    ) : null}
                    {page === 2 ? (
                      <UserInput
                        setPage={setPage}
                        handleNext={handleNext}
                        role={role}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                      />
                    ) : null}
                    {page === 3 ? (
                      <UserInput2
                        setPage={setPage}
                        handleNext={handleNext}
                        role={role}
                        handleBack={handleBack}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        autoFillDisable={autoFillDisable}
                        setAutoFillDisable={setAutoFillDisable}
                      />
                    ) : null}

                    {page === 4 && role === "webtrader" ? (
                      <WebTraderInput
                        values={values}
                        handleBack={handleBack}
                        setPage={setPage}
                        setNrlFile={setNrlFile}
                        setAuthLetterFile={setAuthLetterFile}
                        setCertificateFile={setCertificateFile}
                        setExistingNRL={setExistingNRL}
                        setCreateCertificate={setCreateCertificate}
                        nrlStatus={nrlStatus}
                        setNrlStatus={setNrlStatus}
                        certificate={certificate}
                        setCertificate={setCertificate}
                        certificateName={certificateName}
                        setCertificateName={setCertificateName}
                        nrlName={nrlName}
                        setNrlName={setNrlName}
                        authorizationName={authorizationName}
                        setAuthorizationName={setAuthorizationName}
                        setPublicCertificate={setPublicCertificate}
                        setPublicCertFileName={setPublicCertFileName}
                      />
                    ) : null}
                  </Paper>
                )}
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default Registration;
