import React, { useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { RenderBreadcrumbPU } from "../../../utils/Breadcrumbs";

import RequestWT from "./RequestWT/RequestWT";
import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { StyledTreeItem } from "../SideNav/SideNav";
import PasswordChange from "../MenuOptions/UpdatePassword/UpdatePassword";
import UpdateAccount from "../MenuOptions/UpdateCompanyAccounts/UpdateAccount";
import IdleTimerContainer from "../../../service/IdleTimerContainer";

import AddNRL from "../MenuOptions/AddNRL/AddNRL";
import AddAuth from "../MenuOptions/AddAuth/AddAuth";
import UpdateCompanyProfile from "../MenuOptions/UpdateCompany/UpdateCompany";
import { useTranslation } from "react-i18next";
import StatusOfSubmissions from "../MenuOptions/SearchStatusOfSub/SearchStatusOfSub";
import UpdateProfile from "../MenuOptions/UpdateProfile/UpdateP";
import Welcome from "./Welcome/Welcome";
import { ExpiredPWDWindow } from "../../../utils/Utils";
import Typography from "@material-ui/core/Typography";

const RestrictedAccesContainer = styled.div`
  width: 600px;
  margin: 100px auto;
  text-align: center;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 7px;
  padding: 30px;
`;

const HorizontalLine = styled.div`
  border-bottom: 1px solid lightgrey;
  padding-top: 10px;
  margin-bottom: 20px;
`;
const LogInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MainContainer = styled.div`
  width: 90%;
  margin: 0px auto 20px;

  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 8px;
`;

const MenuWrapper = styled.div`
  /* width: 400px; */

  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2); */

  /* background-color: white; */
  /* border-radius: 8px; */
  /* border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px; */

  /* align-self: flex-start; */
  /* background-color: #f6f6f6; */
  /* background-color: rgba(0, 0, 0, 0.12); */
  background-color: #e9ecef;
  border-bottom-left-radius: 8px;
  width: 20%;
`;

const BreadCrumbsContainer = styled.div`
  /* width: 90%; */
  margin: 20px auto 0px;
  /* background-color: white; */
  /* border-radius: 8px; */
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 20px;
`;

const PageTitleContainer = styled.div`
  text-align: center;
  padding: 15px;
  background-color: #001871;
  color: white;
`;

const SelectedOptionContainer = styled.div`
  display: flex;
`;

const SelectedOption = styled.div`
  /* flex-grow: 1; */
  background-color: white;

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 0px;
  width: 80%;
`;

const useStyles = makeStyles({
  root: {
    // height: 264,
    flexGrow: 1,
    // maxWidth: 400,
  },
});

const StaticContentContainer = styled.div`
  padding: 30px;
`;

interface TextStaticProps {
  txt: string;
}

const StaticContent: React.FC<TextStaticProps> = ({ txt }) => {
  return (
    <StaticContentContainer>
      <p>{txt}</p>
    </StaticContentContainer>
  );
};

interface Props {
  loggedOutMessage: any;
  userClickedLogOut: any;
  setLoggedOutMessage: any;
  setUserClickedLogOut: any;
  setAuthorizedStatus: any;
  setUserAuthenticated: any;
  openPWDExpire: any;
  handleClosePWDExpire: any;
  pwdExpireMsg: any;
}

const MenuPU: React.FC<Props> = ({
  loggedOutMessage,
  userClickedLogOut,
  setLoggedOutMessage,
  setUserClickedLogOut,
  setAuthorizedStatus,
  setUserAuthenticated,
  openPWDExpire,
  handleClosePWDExpire,
  pwdExpireMsg,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const { t } = useTranslation();

  const [selectedNode, setSelectedNode] = useState("11");

  const [selectedNodeText, setSelectedNodeText] = useState(
    t("BreadCrumbsAndNav.1")
  );
  const changeSelectedNode = (e: any, n: any) => {
    setSelectedNode(n);
    setSelectedNodeText(e.target.innerText);
    // }
  };

  if (userClickedLogOut) {
    return (
      <RestrictedAccesContainer>
        {/* <p>Unathorized access</p> */}
        <Alert severity="success">
          <AlertTitle>
            <strong>{loggedOutMessage}</strong>
          </AlertTitle>
        </Alert>
        <HorizontalLine></HorizontalLine>
        <LogInButtonWrapper>
          {" "}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              history.push("/");
            }}
          >
            {t("Signin.1")}
          </Button>
        </LogInButtonWrapper>
      </RestrictedAccesContainer>
    );
  } else {
    if (sessionStorage.getItem("status") === "Pending") {
      return (
        <RestrictedAccesContainer>
          {/* <p>Unathorized access</p> */}
          <h5>{sessionStorage.getItem("statusMessage")}</h5>
          <br />
          <p>
            Your account status is:{" "}
            <strong>{sessionStorage.getItem("status")}</strong>
          </p>
        </RestrictedAccesContainer>
      );
    } else {
      return (
        <MainContainer>
          <IdleTimerContainer
            setLoggedOutMessage={setLoggedOutMessage}
            setUserClickedLogOut={setUserClickedLogOut}
            setAuthorizedStatus={setAuthorizedStatus}
            setUserAuthenticated={setUserAuthenticated}
          />

          <BreadCrumbsContainer>
            <RenderBreadcrumbPU
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              setSelectedNodeText={setSelectedNodeText}
            />
          </BreadCrumbsContainer>

          <PageTitleContainer>
            <Typography variant="h4" component="h1" style={{ marginBottom: 0 }}>
              {selectedNodeText}
            </Typography>
            {/* <h3 style={{ marginBottom: 0 }}>{selectedNodeText}</h3> */}
          </PageTitleContainer>

          <SelectedOptionContainer>
            <MenuWrapper>
              <TreeView
                className={classes.root}
                selected={[selectedNode]}
                // expanded={expandedNodes}
                onNodeSelect={changeSelectedNode}
                // onNodeToggle={toggleSelectTesting}
                // defaultExpanded={["3"]}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
              >
                <StyledTreeItem
                  nodeId="11"
                  labelText={t("BreadCrumbsAndNav.1")}
                />
                <StyledTreeItem
                  nodeId="1"
                  labelText={t("BreadCrumbsAndNav.2")}
                />
                <StyledTreeItem
                  nodeId="2"
                  labelText={t("BreadCrumbsAndNav.5")}
                  className="folder-menu"
                >
                  <StyledTreeItem
                    nodeId="3"
                    labelText={t("BreadCrumbsAndNav.6")}
                  />
                  <StyledTreeItem
                    nodeId="4"
                    labelText={t("BreadCrumbsAndNav.7")}
                  />
                </StyledTreeItem>
                <StyledTreeItem
                  nodeId="5"
                  labelText={t("BreadCrumbsAndNav.12")}
                  className="folder-menu"
                >
                  <StyledTreeItem
                    nodeId="6"
                    labelText={t("BreadCrumbsAndNav.13")}
                  />
                  <StyledTreeItem
                    nodeId="7"
                    labelText={t("BreadCrumbsAndNav.9")}
                  />
                  <StyledTreeItem
                    nodeId="8"
                    labelText={t("BreadCrumbsAndNav.10")}
                  />
                </StyledTreeItem>

                <StyledTreeItem
                  nodeId="9"
                  labelText={t("BreadCrumbsAndNav.16")}
                ></StyledTreeItem>

                <StyledTreeItem
                  nodeId="10"
                  labelText={t("BreadCrumbsAndNav.25")}
                />
              </TreeView>
            </MenuWrapper>
            <SelectedOption>
              {selectedNode === "11" ? <Welcome /> : null}
              {selectedNode === "1" ? (
                <StatusOfSubmissions inputAccountName={true} />
              ) : null}
              {selectedNode === "2" ? (
                <StaticContent txt={t("Errors.31")} />
              ) : null}

              {selectedNode === "3" ? (
                <UpdateProfile
                  getAPI={`/api/getPUProfile?userid=${sessionStorage.getItem(
                    "userID"
                  )}&lang=${localStorage.getItem("i18nextLng")}`}
                  postAPI={`/api/updatePUProfile?userid=${sessionStorage.getItem(
                    "userID"
                  )}`}
                  showRoutingId={true}
                  appendRole={true}
                  roleId={
                    sessionStorage.getItem("user")
                      ? String(sessionStorage.getItem("user"))
                      : ""
                  }
                />
              ) : null}

              {selectedNode === "4" ? (
                <PasswordChange
                  postAPI={`/api/updatePUPwd?userid=${sessionStorage.getItem(
                    "userID"
                  )}`}
                />
              ) : null}

              {selectedNode === "5" ? (
                <StaticContent txt={t("Errors.30")} />
              ) : null}

              {selectedNode === "6" ? (
                <UpdateCompanyProfile
                  getAPI={`/api/getPUCompanyProfile?userid=${sessionStorage.getItem(
                    "userID"
                  )}&lang=${localStorage.getItem("i18nextLng")}`}
                  postAPI={`/api/updatePUCompanyProfile?userid=${sessionStorage.getItem(
                    "userID"
                  )}`}
                />
              ) : null}

              {selectedNode === "7" ? (
                <AddNRL
                  baseDownloadURL="api/downloadPUFile"
                  postAPI={`/api/uploadPUCompanyNewNRL?userid=${sessionStorage.getItem(
                    "userID"
                  )}&lang=${localStorage.getItem("i18nextLng")}`}
                  getAPI={`/api/getPUCompanyNRL?userid=${sessionStorage.getItem(
                    "userID"
                  )}&lang=${localStorage.getItem("i18nextLng")}`}
                  checkboxText={t("UploadNewNRL.12")}
                  displayMessage={true}
                  extraMessage={t("UploadNewNRL.13")}
                  checkboxTooltip={t("UploadNewNRL.14")}
                />
              ) : null}
              {selectedNode === "8" ? (
                <AddAuth
                  baseDownloadURL="api/downloadPUFile"
                  getAPI={`/api/getPUCompanyAuth?userid=${sessionStorage.getItem(
                    "userID"
                  )}&lang=${localStorage.getItem("i18nextLng")}`}
                  postAPI={`/api/uploadPUCompanyNewAuth?userid=${sessionStorage.getItem(
                    "userID"
                  )}&lang=${localStorage.getItem("i18nextLng")}`}
                  checkboxText={t("UploadNewAuth.12")}
                  checkboxTooltip={t("UploadNewAuth.14")}
                  displayMessage={true}
                  extraMessage={t("UploadNewAuth.13")}
                />
              ) : null}
              {selectedNode === "9" ? (
                <UpdateAccount
                  setSelectedNodeText={setSelectedNodeText}
                  getAPIProfile={`/api/getPUCompanyAccountProf?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  postAPIProfile={`/api/updatePUCompAccountProf?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  postAPINRL={`/api/uploadPUCompAccountNRL?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  getAPINRL={`/api/getPUCompAccountNRL?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  getAPIAUTH={`/api/getPUCompAccountAuth?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  postAPIAUTH={`/api/uploadPUCompAccountAuth?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  getAPIEnableDisable={`/api/getPUCompAccountStatus?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  postAPIEnableDisable={`/api/updatePUCompAccountStatus?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  postAPICERTIFICATE={`/api/uploadNewCert?lang=${localStorage.getItem(
                    "i18nextLng"
                  )}`}
                  postAPICompanySearch={`/api/getPUCompanySearch?userid=${sessionStorage.getItem(
                    "userID"
                  )}&lang=${localStorage.getItem("i18nextLng")}`}
                />
              ) : null}
              {selectedNode === "10" ? <RequestWT /> : null}
              {/* {welcomeScreenActive ? <Welcome /> : null} */}
            </SelectedOption>
          </SelectedOptionContainer>
          <ExpiredPWDWindow
            openPWDExpire={openPWDExpire}
            handleClosePWDExpire={handleClosePWDExpire}
            pwdExpireMsg={pwdExpireMsg}
          />
        </MainContainer>
      );
    }
  }
};

export default MenuPU;
