import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import FormFooter from "../FormFooter/FormFooter";
import Field from "./UserInputField/Field";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "formik";
import styled from "styled-components";
import axios from "axios";

const ErrorMsg = styled.p`
  color: #c60000;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 4px;
  margin-left: 14px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

interface Props {
  setPage: any;
  handleNext: any;
  role: any;
  values: any;
  handleChange: any;
  handleBlur: any;
  errors: any;
  touched: any;
}

const UserInput: React.FC<Props> = ({
  setPage,
  handleNext,
  role,
  handleChange,
  handleBlur,
  errors,
  touched,
  values,
}) => {
  const { t } = useTranslation();

  const continueHandler = () => {
    setPage(3);
    handleNext();
  };

  const [additionalInfoCollapse, setAdditionalInfoCollapse] = useState(false);

  const [isEmailExist, setIsEmailExist] = React.useState(false);
  const [emailExistMsg, setEmailExistMsg] = React.useState("");

  const [emailValidationFinished, setEmailValidationFinished] =
    React.useState(false);

  const [validateExistingEmail, setValidateExistingEmail] =
    React.useState(false);

  React.useEffect(() => {
    if (values.emailId && errors.emailId === undefined) {
      setEmailValidationFinished(true);
    }
  }, []);
 
 React.useEffect(() => {
    if (values.emailId === "") {
      setIsEmailExist(false);
    }

    //TODO MAKE SURE UNTIL EMAIL IS VALIDATED I CAN'T GO TO THE NEXT PAGE!
    if (values.emailId && !errors.emailId && validateExistingEmail) {
      setEmailValidationFinished(false);
      axios
        .get(
          `/api/getEmailExists?emailId=` +
            values.emailId +
            `&lang=${localStorage.getItem("i18nextLng")}`
        )
        
        .then((res) => {
        console.log(res);
          if (res.data.status === "WARNING") {
            setIsEmailExist(true);
            setEmailExistMsg(res.data.statusMessage);
          } else {
            setIsEmailExist(false);
          }
          setEmailValidationFinished(true);
        })

        .catch((err) => {
          setEmailValidationFinished(true);
          setIsEmailExist(true);
          setEmailExistMsg(err.response.data.statusMessage);

          // if(error.response){
          //       emailError(error.response.data.statusMessage);
          //    } else {
          //     emailError(error.message);
          //    }
          //    return true;
        });
    }
  }, [values.emailId, validateExistingEmail, errors.emailId]);

  return (
    <div className="paper-body user-input-1">
      <div className="firstname-and-middle-initial">
        <div className="first-name-wrapper">
          <Field
            value={values.firstName}
            name="firstName"
            id="firstName-reg"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={40}
            error={errors.firstName && touched.firstName}
            label={t("Registration.1")}
            titleTxt={t("Tooltips.18")}
            tooltipTxt={t("Tooltips.19")}
            inputType="text"
          />

          <ErrorMessage name="firstName">
            {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
          </ErrorMessage>
        </div>

        <div className="mi-wrapper">
          <Field
            value={values.middleInitial}
            name="middleInitial"
            id="middleInitial-reg"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={1}
            error={errors.middleInitial && touched.middleInitial}
            label={t("Registration.7")}
            titleTxt={t("Tooltips.20")}
            tooltipTxt={t("Tooltips.21")}
            inputType="text"
            helperText={
              errors.middleInitial && touched.middleInitial
                ? errors.middleInitial
                : t("Registration.20")
            }
          />
        </div>
      </div>

      <div style={{ height: "10px" }}></div>

      <div className="suffix-last-name-wrapper">
        <div className="last-name-wrapper">
          <Field
            value={values.lastName}
            name="lastName"
            id="lastName-reg"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={45}
            error={errors.lastName && touched.lastName}
            label={t("Registration.2")}
            titleTxt={t("Tooltips.22")}
            tooltipTxt={t("Tooltips.23")}
            inputType="text"
            // tooltipPlace={"bottom"}
          />

          <ErrorMessage name="lastName">
            {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
          </ErrorMessage>
        </div>
        <div className="suffix-wrapper">
          <Field
            value={values.suffix}
            name="suffix"
            id="suffix-reg"
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={5}
            error={errors.suffix && touched.suffix}
            label={t("Registration.46")}
            titleTxt={t("Registration.47")}
            tooltipTxt={t("Registration.48")}
            inputType="text"
            helperText={
              errors.suffix && touched.suffix
                ? errors.suffix
                : t("Registration.20")
            }
          />
        </div>
      </div>
      <div style={{ height: "15px" }}></div>

      <Field
        inputType="email"
        autoComplete="new-password"
        value={values.emailId}
        name="emailId"
        id="emailId-reg"
        onChange={(e: any) => {
          handleChange(e);
          setIsEmailExist(false);
        }}
        onBlur={(e: any) => {
          handleBlur(e);
          setValidateExistingEmail(true);
        }}
        onSelect={(e: any) => setValidateExistingEmail(false)}
        maxLength={100}
        error={(errors.emailId && touched.emailId) || isEmailExist}
        label={t("Registration.3")}
        titleTxt={t("Tooltips.24")}
        tooltipTxt={t("Tooltips.25")}
      />

      <div>
        {errors.emailId && touched.emailId ? (
          <div className="error-container">
            <p>{t("Errors.12")}</p>
            <ul style={{ listStylePosition: "inside" }}>
              <li>{t("Errors.1")}</li>
              <li>{t("Errors.2")}</li>

              <li>{t("Errors.3")}</li>
              <li>{t("Errors.4")}</li>
              <li>{t("Errors.5")}</li>
            </ul>
            <p>{t("Errors.6")}</p>
            <ul style={{ listStylePosition: "inside" }}>
              <li>{t("Errors.1")}</li>
              <li>{t("Errors.2")}</li>
              <li>{t("Errors.9")}</li>
              <li>{t("Errors.10")}</li>
            </ul>
            <p> {t("Errors.11")}</p>
          </div>
        ) : isEmailExist ? (
          <div className="error-container">
            <p>{emailExistMsg}</p>
          </div>
        ) : (
          <div className="email-id-no-error">
            <p>{t("Registration.52")}</p>
          </div>
        )}
      </div>

      <div style={{ height: "12px" }}></div>

      <Field
        value={values.password}
        name="password"
        id="password-reg"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.password && touched.password}
        autoComplete="new-password"
        label={t("Registration.4")}
        titleTxt={t("Tooltips.26")}
        tooltipTxt={t("Tooltips.27")}
        inputType="password"
      />

      {errors.password && touched.password && (
        <div className="error-container">
          <p>{t("Errors.14")}</p>
          <ul style={{ listStylePosition: "inside" }}>
            <li> {t("Errors.15")}</li>
            <li> {t("Errors.16")}</li>
            <li>{t("Errors.17")}</li>
            <li>{t("Errors.18")}</li>
            <li>{t("Errors.19")}</li>
            <li> {t("Errors.20")}</li>
            <li> {t("Errors.21")}</li>
          </ul>
        </div>
      )}

      <div style={{ height: "10px" }}></div>

      <Field
        value={values.reenterPassword}
        name="reenterPassword"
        id="reenterPassword-reg"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.reenterPassword && touched.reenterPassword}
        autoComplete="new-password"
        label={t("Registration.5")}
        titleTxt={t("Tooltips.28")}
        tooltipTxt={t("Tooltips.29")}
        inputType="password"
      />

      <ErrorMessage name="reenterPassword">
        {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
      </ErrorMessage>

      <div style={{ height: "35px" }}></div>

      <Field
        value={values.phone}
        name="phone"
        id="phone-reg"
        onChange={handleChange}
        onBlur={handleBlur}
        // errorStatus={passwordConfirmError}
        label={t("Registration.6")}
        titleTxt={t("Tooltips.30")}
        tooltipTxt={t("Tooltips.70")}
        inputType="text"
        //helperText={t("Registration.20")}
        maxLength={25}
        error={errors.phone && touched.phone}
      />
      {errors.phone && touched.phone ?
        (<div className="error-container">
            <p>{t("Errors.34")}</p>
            <p>{t("Errors.35")}</p>
            <ul style={{ listStylePosition: "inside" }}>
              <li>{t("Errors.36")}</li>
              <li>{t("Errors.37")}</li>
              <li>{t("Errors.38")}</li>
              <li>{t("Errors.39")}</li>
            </ul>
          </div>
        ):(<p className="error-mssg-input-fields error-mssg-input-fields-regular">
            {t("Registration.20")}
          </p>)}

      <div style={{ height: "35px" }}></div>

      {role === "webtrader" ? (
        <Accordion
          onChange={() => setAdditionalInfoCollapse(!additionalInfoCollapse)}
          expanded={additionalInfoCollapse}
          style={{ marginBottom: "2.5rem" }}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            // expanded={expanded === "panel1"}
            // onChange={handleChange("panel1")}
          >
            <IconButton
              className="expand-optional-button"
              title={t("UpdateProfileWT.22")}
              // onClick={() => setAdditionalInfoCollapse(!additionalInfoCollapse)}
            >
              {additionalInfoCollapse ? (
                <RemoveCircleOutlineIcon style={{ color: "#3f51b5" }} />
              ) : (
                <>
                  <AddCircleIcon style={{ color: "#3f51b5" }} />{" "}
                </>
              )}
            </IconButton>
            <p style={{ fontWeight: 600, marginBottom: 0 }}>
              {t("Registration.22")}
            </p>
          </AccordionSummary>
          <AccordionDetails>
            <div className="secondary-firstname-and-middle-initial">
              <div style={{ width: "80%" }}>
                <Field
                  value={values.secondaryFirstName}
                  name="secondaryFirstName"
                  id="secondaryFirstName-reg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.secondaryFirstName}
                  maxLength={40}
                  // errorStatus={firstNameError}
                  label={t("Registration.8")}
                  // titleTxt="Enter your First Name"
                  tooltipTxt={t("Tooltips.34")}
                  titleTxt={t("Tooltips.33")}
                  inputType="text"
                />

                <ErrorMessage name="secondaryFirstName">
                  {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                </ErrorMessage>
              </div>
              {/* <p className="error-mssg-input-fields">Optional</p>
            <div style={{ height: "10px" }}></div> */}
              <div className="secondary-mi-wrapper">
                <Field
                  value={values.secondaryMInitial}
                  name="secondaryMInitial"
                  id="secondaryMInitial-reg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.secondaryMInitial && touched.secondaryMInitial}
                  maxLength={1}
                  // errorStatus={passwordConfirmError}
                  label={t("Registration.10")}
                  // titleTxt="Enter your phone number (optional)"
                  tooltipTxt={t("Tooltips.36")}
                  titleTxt={t("Tooltips.35")}
                  inputType="text"
                />{" "}
                <ErrorMessage name="secondaryMInitial">
                  {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                </ErrorMessage>
              </div>
            </div>

            <div style={{ height: "15px" }}></div>

            <div className="sec-suffix-last-name-wrapper">
              <div className="sec-last-name-wrapper">
                <Field
                  value={values.secondaryLastName}
                  name="secondaryLastName"
                  id="secondaryLastName-reg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.secondaryLastName}
                  maxLength={45}
                  // errorStatus={passwordConfirmError}
                  label={t("Registration.11")}
                  // titleTxt="Enter your phone number (optional)"
                  tooltipTxt={t("Tooltips.38")}
                  titleTxt={t("Tooltips.37")}
                  inputType="text"
                />
                <ErrorMessage name="secondaryLastName">
                  {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                </ErrorMessage>
              </div>
              <div className="sec-suffix-wrapper">
                <Field
                  value={values.secondarySuffix}
                  name="secondarySuffix"
                  id="secondarySuffix-reg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.secondarySuffix && touched.secondarySuffix}
                  maxLength={5}
                  label={t("Registration.49")}
                  titleTxt={t("Registration.50")}
                  tooltipTxt={t("Registration.51")}
                  inputType="text"
                  helperText={
                    errors.secondarySuffix ? "" : t("Registration.20")
                  }
                />
                <ErrorMessage name="secondarySuffix">
                  {(msg) => <ErrorMsg>{msg}</ErrorMsg>}
                </ErrorMessage>
              </div>
            </div>

            <div style={{ height: "30px" }}></div>

            <Field
              value={values.secondaryEmailID}
              name="secondaryEmailID"
              id="secondaryEmailID-reg"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.secondaryEmailID}
              maxLength={100}
              // errorStatus={passwordConfirmError}
              label={t("Registration.12")}
              // titleTxt="Enter your phone number (optional)"
              tooltipTxt={t("Tooltips.40")}
              inputType="text"
              titleTxt={t("Tooltips.39")}
              helperText={errors.secondaryEmailID ? "" : t("Registration.20")}
            />

            {errors.secondaryEmailID && touched.secondaryEmailID && (
              <div className="error-container">
                <p>{t("Errors.12")}</p>
                <ul style={{ listStylePosition: "inside" }}>
                  <li>{t("Errors.1")}</li>
                  <li>{t("Errors.2")}</li>

                  <li>{t("Errors.3")}</li>
                  <li>{t("Errors.4")}</li>
                  <li>{t("Errors.5")}</li>
                </ul>
                <p>{t("Errors.6")}</p>
                <ul style={{ listStylePosition: "inside" }}>
                  <li>{t("Errors.1")}</li>
                  <li>{t("Errors.2")}</li>
                  <li>{t("Errors.9")}</li>
                  <li>{t("Errors.10")}</li>
                </ul>
                <p> {t("Errors.11")}</p>
              </div>
            )}

            <div style={{ height: "30px" }}></div>

            <Field
              value={values.secondaryPhone}
              name="secondaryPhone"
              id="secondaryPhone-reg"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.secondaryPhone && touched.secondaryPhone}
              // errorStatus={passwordConfirmError}
              label={t("Registration.13")}
              // titleTxt="Enter your phone number (optional)"
              tooltipTxt={t("Tooltips.71")}
              inputType="text"
              titleTxt={t("Tooltips.41")}
              maxLength={25}
            />
            {errors.secondaryPhone && touched.secondaryPhone ? 
            (<div className="error-container">
              <p>{t("Errors.40")}</p>
              <p>{t("Errors.41")}</p>
              <ul style={{ listStylePosition: "inside" }}>
                <li>{t("Errors.36")}</li>
                <li>{t("Errors.37")}</li>
                <li>{t("Errors.38")}</li>
                <li>{t("Errors.39")}</li>
              </ul>
            </div>
            ):(<p className="error-mssg-input-fields error-mssg-input-fields-regular">
            {t("Registration.20")}
          </p>)
          }
            <div style={{ height: "15px" }}></div>
          </AccordionDetails>
        </Accordion>
      ) : null}

      <FormFooter
        previousHandler={() => setPage(1)}
        previousOption={true}
        continueHandler={continueHandler}
        //TODO MAKE it dynamic based on role.
        disbale={
          values.firstName === "" ||
          values.lastName === "" ||
          values.emailId === "" ||
          values.password === "" ||
          errors.suffix !== undefined ||
          errors.password !== undefined ||
          errors.reenterPassword !== undefined ||
          errors.emailId !== undefined ||
          errors.firstName !== undefined ||
          errors.lastName !== undefined ||
          errors.middleInitial !== undefined ||
          errors.secondaryEmailID !== undefined ||
          errors.secondaryFirstName !== undefined ||
          errors.secondaryLastName !== undefined ||
          isEmailExist ||
          !emailValidationFinished
        }
      />
    </div>
  );
};

export default UserInput;
