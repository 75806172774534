import React, { useState, useEffect } from "react";
import styled from "styled-components";

import ViewAccountStatus from "../MenuOptions/ViewAccountStatus/ViewStatus";
import Update from "../MenuOptions/UpdateProfile/UpdateF";
import AddAuth from "../MenuOptions/AddAuth/AddAuth";
import AddNRL from "../MenuOptions/AddNRL/AddNRL";
import StatusOfSubmissions from "../MenuOptions/SearchStatusOfSub/SearchStatusOfSub";
import PasswordUpdate from "../MenuOptions/UpdatePassword/UpdatePassword";
import RequestPower from "./RequestPowerRole/Request";
import Welcome from "./Welcome/Welcome";
import Certificate from "../MenuOptions/Certificate/Certificate";

import { Alert, AlertTitle } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import IdleTimerContainer from "../../../service/IdleTimerContainer";
import { StyledTreeItem } from "../SideNav/SideNav";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import TreeView from "@material-ui/lab/TreeView";
import { makeStyles } from "@material-ui/core/styles";
import { RenderBreadcrumbWT } from "../../../utils/Breadcrumbs";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { ExpiredPWDWindow } from "../../../utils/Utils";

const HorizontalLine = styled.div`
  border-bottom: 1px solid lightgrey;
  padding-top: 10px;
  margin-bottom: 20px;
`;
const LogInButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const BreadCrumbsContainer = styled.div`
  margin: 20px auto 0px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 20px;
`;

const PageTitleContainer = styled.div`
  text-align: center;
  padding: 15px;
  background-color: #001871;
  color: white;
`;

const RestrictedAccesContainer = styled.div`
  width: 600px;
  margin: 100px auto;
  text-align: center;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 7px;
  padding: 30px;
`;

const MainContainer = styled.div`
  width: 90%;
  margin: 0px auto 20px;

  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 8px;
`;

const MenuWrapper = styled.div`
  /* width: 400px; */

  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2); */

  /* background-color: white; */
  /* border-radius: 8px; */
  /* border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px; */

  /* align-self: flex-start; */
  /* background-color: #f6f6f6; */
  /* background-color: rgba(0, 0, 0, 0.12); */
  background-color: #e9ecef;
  border-bottom-left-radius: 8px;
  width: 20%;
`;

const SelectedOptionContainer = styled.div`
  display: flex;

  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.12); ;
`;

const SelectedOption = styled.div`
  /* flex-grow: 1; */
  /* background-color: white; */

  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 0px;
  width: 80%;
`;

const useStyles = makeStyles({
  root: {
    // height: 264,
    flexGrow: 1,
    // maxWidth: 400,
  },
});

const StaticContentContainer = styled.div`
  padding: 30px;
`;

interface TextStaticProps {
  txt: string;
}

const StaticContent: React.FC<TextStaticProps> = ({ txt }) => {
  return (
    <StaticContentContainer>
      <p>{txt}</p>
    </StaticContentContainer>
  );
};

// const UpdateAccountInfo: React.FC = () => {
//   return (
//     <StaticContentContainer>
//       <p>todo</p>
//     </StaticContentContainer>
//   );
// };

// const UpdateMyInformation: React.FC = () => {
//   return (
//     <StaticContentContainer>
//       <p>
//         {" "}
//         Please use the menu on the left to update your Profile, update your
//         Password, upload new Non-Repudiation Letter, upload new Authorization
//         Letter and upload new Certificate.
//       </p>
//     </StaticContentContainer>
//   );
// };

interface Props {
  loggedOutMessage: any;
  userClickedLogOut: any;
  setLoggedOutMessage: any;
  setUserClickedLogOut: any;
  setAuthorizedStatus: any;
  setUserAuthenticated: any;
  openPWDExpire: any;
  handleClosePWDExpire: any;
  pwdExpireMsg: any;
}

const Menu: React.FC<Props> = ({
  loggedOutMessage,
  userClickedLogOut,
  setLoggedOutMessage,
  setUserClickedLogOut,
  setAuthorizedStatus,
  setUserAuthenticated,
  openPWDExpire,
  handleClosePWDExpire,
  pwdExpireMsg,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [selectedNode, setSelectedNode] = useState("11");

  const [selectedNodeText, setSelectedNodeText] = useState(
    t("BreadCrumbsAndNav.1")
  );

  const changeSelectedNode = (e: any, n: any) => {
    if (n === "12" || n === "13") {
    } else {
      setSelectedNode(n);
      setSelectedNodeText(e.target.innerText);
    }
  };

  //IMPORTANT TO UNDERSTAND
  //IE 11 IS NOT SUPPORTING THIS
  //-----------------------------------------------

  //Add same effect everywhere
  //TODO
  // useEffect(() => {
  // window.addEventListener("storage", (event: any) => {
  //   console.log(event.storageArea.isUserAuthenticated);
  //   if (event.storageArea.isUserAuthenticated === undefined) {
  //     history.push("/");
  //   }
  // if (event.storageArea == localStorage) {
  //   console.log(event);
  // }
  // });
  // return () => {
  //TODO this function is not invoking on clean, check it out
  // window.removeEventListener("storage", (e) => {
  //   console.log("cleaned");
  // });
  //   };
  // }, []);

  const history = useHistory();

  if (userClickedLogOut) {
    return (
      <RestrictedAccesContainer>
        {/* <p>Unathorized access</p> */}
        <Alert severity="success">
          <AlertTitle>
            <strong>{loggedOutMessage}</strong>
          </AlertTitle>
        </Alert>
        <HorizontalLine></HorizontalLine>
        <LogInButtonWrapper>
          {" "}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              history.push("/");
            }}
          >
            {t("Signin.1")}
          </Button>
        </LogInButtonWrapper>
      </RestrictedAccesContainer>
    );
  } else {
    if (sessionStorage.getItem("status") === "Pending") {
      return (
        <RestrictedAccesContainer>
          {/* <p>Unathorized access</p> */}
          <h5>{sessionStorage.getItem("statusMessage")}</h5>
          <br />
          <p>
            Your account status is:{" "}
            <strong>{sessionStorage.getItem("status")}</strong>
          </p>
        </RestrictedAccesContainer>
      );
    } else {
      return (
        <>
          <MainContainer>
            <BreadCrumbsContainer>
              <RenderBreadcrumbWT
                selectedNode={selectedNode}
                setSelectedNode={setSelectedNode}
                setSelectedNodeText={setSelectedNodeText}
              />
            </BreadCrumbsContainer>

            <PageTitleContainer>
              <Typography
                variant="h4"
                component="h1"
                style={{ marginBottom: 0 }}
              >
                {selectedNodeText}
              </Typography>
              {/* <h3 style={{ marginBottom: 0 }}>{selectedNodeText}</h3> */}
            </PageTitleContainer>

            <IdleTimerContainer
              setLoggedOutMessage={setLoggedOutMessage}
              setUserClickedLogOut={setUserClickedLogOut}
              setAuthorizedStatus={setAuthorizedStatus}
              setUserAuthenticated={setUserAuthenticated}
            />

            <SelectedOptionContainer>
              <MenuWrapper>
                <TreeView
                  className={classes.root}
                  selected={[selectedNode]}
                  // expanded={expandedNodes}
                  onNodeSelect={changeSelectedNode}
                  // onNodeToggle={toggleSelectTesting}
                  // defaultExpanded={["3"]}
                  defaultCollapseIcon={<ArrowDropDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                >
                  <StyledTreeItem
                    nodeId="11"
                    labelText={t("BreadCrumbsAndNav.1")}
                  />
                  <StyledTreeItem
                    nodeId="1"
                    labelText={t("BreadCrumbsAndNav.2")}
                  />

                  <a
                    href="https://esgtest.fda.gov/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StyledTreeItem
                      nodeId="12"
                      labelText={t("BreadCrumbsAndNav.3")}
                      className="folder-menu always-closed-tab"
                    />
                  </a>
                  <a
                    href="https://esg.fda.gov/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StyledTreeItem
                      nodeId="13"
                      labelText={t("BreadCrumbsAndNav.4")}
                      className="folder-menu always-closed-tab"
                    />{" "}
                  </a>

                  <StyledTreeItem
                    nodeId="2"
                    labelText={t("BreadCrumbsAndNav.5")}
                    className="folder-menu"
                  >
                    <StyledTreeItem
                      nodeId="3"
                      labelText={t("BreadCrumbsAndNav.6")}
                    />
                    <StyledTreeItem
                      nodeId="4"
                      labelText={t("BreadCrumbsAndNav.7")}
                    />

                    <StyledTreeItem
                      nodeId="5"
                      labelText={t("BreadCrumbsAndNav.8")}
                      className="folder-menu"
                    >
                      <StyledTreeItem
                        nodeId="6"
                        labelText={t("BreadCrumbsAndNav.9")}
                        // className="folder-menu"
                        className="third-dropdown-level-padding"
                      />
                      <StyledTreeItem
                        nodeId="7"
                        labelText={t("BreadCrumbsAndNav.10")}
                        // className="folder-menu"
                        className="third-dropdown-level-padding"
                      />
                      <StyledTreeItem
                        nodeId="8"
                        labelText={t("BreadCrumbsAndNav.11")}
                        // className="folder-menu"
                        className="third-dropdown-level-padding"
                      />
                    </StyledTreeItem>

                    <StyledTreeItem
                      nodeId="9"
                      labelText={t("BreadCrumbsAndNav.17")}
                    />
                  </StyledTreeItem>

                  <StyledTreeItem
                    nodeId="10"
                    labelText={t("BreadCrumbsAndNav.19")}
                  />
                </TreeView>
              </MenuWrapper>
              <SelectedOption>
                {selectedNode === "11" ? <Welcome /> : null}
                {selectedNode === "1" ? <StatusOfSubmissions /> : null}

                {selectedNode === "2" ? (
                  <StaticContent txt={t("Errors.29")} />
                ) : null}

                {selectedNode === "3" ? (
                  <Update
                    getAPI={`/api/getProfile?userid=${sessionStorage.getItem(
                      "userID"
                    )}&lang=${localStorage.getItem("i18nextLng")}`}
                    postAPI={`/api/updateProfile?userid=${sessionStorage.getItem(
                      "userID"
                    )}`}
                    appendRole={true}
                    roleId={
                      sessionStorage.getItem("user")
                        ? String(sessionStorage.getItem("user"))
                        : ""
                    }
                  />
                ) : null}

                {selectedNode === "4" ? (
                  <PasswordUpdate
                    postAPI={`/api/updatePwd?userid=${sessionStorage.getItem(
                      "userID"
                    )}`}
                  />
                ) : null}

                {selectedNode === "5" ? <StaticContent txt={""} /> : null}

                {selectedNode === "6" ? (
                  <AddNRL
                    baseDownloadURL="api/downloadFile"
                    checkboxText={t("UploadNewNRL.5")}
                    checkboxTooltip={t("UploadNewNRL.6")}
                    postAPI={`/api/uploadNewNRL?userid=${sessionStorage.getItem(
                      "userID"
                    )}&lang=${localStorage.getItem("i18nextLng")}`}
                    getAPI={`/api/getUserNRL?userid=${sessionStorage.getItem(
                      "userID"
                    )}&lang=${localStorage.getItem("i18nextLng")}`}
                  />
                ) : null}
                {selectedNode === "7" ? (
                  <AddAuth
                    baseDownloadURL="api/downloadFile"
                    checkboxText={t("UploadNewAuth.5")}
                    checkboxTooltip={t("UploadNewAuth.6")}
                    getAPI={`/api/getAuthLetter?userid=${sessionStorage.getItem(
                      "userID"
                    )}&lang=${localStorage.getItem("i18nextLng")}`}
                    postAPI={`/api/uploadAuthletter?userid=${sessionStorage.getItem(
                      "userID"
                    )}&lang=${localStorage.getItem("i18nextLng")}`}
                  />
                ) : null}

                {selectedNode === "8" ? <Certificate /> : null}
                {selectedNode === "9" ? <RequestPower /> : null}
                {selectedNode === "10" ? (
                  <ViewAccountStatus
                    getAPI={`/api/ViewAcStatus?userid=${sessionStorage.getItem(
                      "userID"
                    )}&lang=${localStorage.getItem("i18nextLng")}`}
                  />
                ) : null}
              </SelectedOption>
            </SelectedOptionContainer>
          </MainContainer>

          <ExpiredPWDWindow
            openPWDExpire={openPWDExpire}
            handleClosePWDExpire={handleClosePWDExpire}
            pwdExpireMsg={pwdExpireMsg}
          />
        </>
      );
    }
  }
};

export default Menu;
